import React from "react";
import { Button, Popover, Input, Modal, Spin } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import "react-phone-number-input/style.css";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Wrapper = ({ title, subtitle, children }) => (
  <div className={styles.wrapperHeader}>
    <h4 className={styles.wrapperTitle}>{title}</h4>
    <h7 className={styles.wrapperSubtitle}>{subtitle}</h7>
    {children}
  </div>
);

const Representation = ({
  email,
  setEmail,
  updateChangeEmail,
  saveEmail,
  changeEmail,
  changePassword,
  setShowPasswordModal,
  emailVerified,
  setShowDeleteModal,
  deleteAccount,
  showDeleteModal,
  showPasswordModal,
  setConfirmNewPassword,
  setNewPassword,
  setOldPassword,
  loading,
  history,
  disable2fa,
  mfaEnabled,
  plan,
  changePwdLoading,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();

  const ModalStyle = {
    padding: "45px",
    borderRadius: "15px",
    background: theme === "dark" ? "#1C193B" : "#ffffff",
    overflow: "hidden",
  };

  return (
    <Wrapper title={t("settings.15")} subtitle={t("settings.16")}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h7 className={styles.title}> {t("settings.12")} </h7>
          <div className={styles.verifyContainer}>
            {emailVerified ? (
              <>
                <CheckCircleOutlined /> {t("settings.13")}
              </>
            ) : (
              <>
                <ExclamationCircleOutlined /> {t("settings.14")}
              </>
            )}
          </div>
          <Input
            className={styles.customInput}
            value={email}
            placeholder={t("settings.17")}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!changeEmail}
          />
          {plan?.PackageId !== 68 ? (
            <div className={styles.row}>
              <Button
                disabled={!changeEmail}
                className={styles.btn}
                onClick={saveEmail}
              >
                {t("button.16")}
              </Button>
              <Button className={styles.btn} onClick={updateChangeEmail}>
                {changeEmail ? t("button.3") : t("settings.18")}
              </Button>
            </div>
          ) : (
            <></>
          )}
          <div className={styles.divider} />
          <h7 className={styles.title}> {t("settings.22")} </h7>
          <Button
            className={styles.btn}
            onClick={() => setShowPasswordModal(true)}
          >
            {t("settings.19")}
          </Button>
          {plan?.PackageId === 100 && (
            <>
              <div className={styles.divider} />
              <h7 className={styles.title}>{t("settings.20")} </h7>
              <p className={styles.subtitle}>{t("settings.21")}</p>
              <Button
                className={`${styles.btn} ${mfaEnabled ? styles.btnRed : ""}`}
                onClick={() =>
                  mfaEnabled
                    ? disable2fa()
                    : history.push("/settings/security/enable/2fa")
                }
              >
                {mfaEnabled
                  ? `${t("settings.23")} 2FA`
                  : `${t("settings.24")} 2FA`}
              </Button>
            </>
          )}
          <div className={styles.divider} />
          <h7 className={styles.title}>
            {t("settings.25")}
            <Popover content={t("settings.26")} trigger="hover">
              <InfoCircleFilled />
            </Popover>
          </h7>
          <Button
            className={styles.btn}
            onClick={() => setShowDeleteModal(true)}
          >
            {t("settings.27")}
          </Button>
          <Modal
            visible={showDeleteModal}
            bodyStyle={ModalStyle}
            footer={null}
            onCancel={() => !loading && setShowDeleteModal(false)}
            style={{ overflow: "hidden", borderRadius: "15px", padding: "0px" }}
          >
            <h7 className={styles.title}>{t("settings.28")}</h7>
            <p className={styles.modalContent}>{t("settings.29")}</p>
            <div className={`${styles.row} ${styles.alignEnd}`}>
              <Button
                className={`${styles.btn} ${styles.modalBtn}`}
                onClick={() => !loading && setShowDeleteModal(false)}
              >
                {t("button.2")}
              </Button>
              <Button
                className={`${styles.btn} ${styles.modalBtn} ${
                  styles.deleteBtn
                } ${loading && styles.loadingBtn}`}
                onClick={() => !loading && deleteAccount()}
              >
                {loading ? <Spin /> : t("settings.30")}
              </Button>
            </div>
          </Modal>
          <Modal
            visible={showPasswordModal}
            bodyStyle={ModalStyle}
            footer={null}
            onCancel={() => !changePwdLoading && setShowPasswordModal(false)}
            maskClosable={!changePwdLoading}
            style={{ overflow: "hidden", borderRadius: "15px", padding: "0px" }}
          >
            <h7 className={styles.modalTitle}> {t("settings.31")}</h7>
            <Input.Password
              className={styles.customInput}
              placeholder={t("settings.32")}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <h7 className={styles.modalTitle}> {t("settings.33")}</h7>
            <Input.Password
              className={styles.customInput}
              placeholder={t("settings.34")}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <h7 className={styles.modalTitle}> {t("settings.35")}</h7>
            <Input.Password
              className={styles.customInput}
              placeholder={t("settings.36")}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <div className={`${styles.row} ${styles.alignEnd}`}>
              <Button
                className={`${styles.btn} ${styles.modalBtn}`}
                loading={changePwdLoading}
                onClick={changePassword}
              >
                {t("button.4")}
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </Wrapper>
  );
};

export default Representation;
