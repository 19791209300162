import React from "react";
import domAlign from "dom-align";
import { Collapse, Tooltip } from "antd";
import styles from "./style.module.scss";
import { tooltip_labels } from "../../../util/constants";
import { RightOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
const alignConfig = {
  // align top left point of sourceNode with top right point of targetNode
  // the offset sourceNode by 10px in x and 20px in y,
  targetOffset: ["20%", "5%"], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
  // auto adjust position when sourceNode is overflowed
};

const alignConfig1 = {
  // align top left point of sourceNode with top right point of targetNode
  // the offset sourceNode by 10px in x and 20px in y,
  targetOffset: ["40%", "0%"], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
  // auto adjust position when sourceNode is overflowed
};
const BoardCard = ({
  title,
  selection,
  unselect,
  children,
  extra,
  alwaysOpen,
  id,
  type,
  theme,
}) => {
  const z = typeof title === "string" ? title.slice(0, 7) : "";
  return (
    <Tooltip
      align={z === "Reviews" ? alignConfig1 : alignConfig}
      title={
        z === "Reviews"
          ? "Curious about other search results? Hit search and get tailored results based on your own chosen words!"
          : tooltip_labels.get(title)
      }
      color="#100D31"
      arrowPointAtCenter
      autoAdjustOverflow
    >
      <div className={styles.container} id={id}>
        <Collapse
          collapsible="disabled"
          defaultActiveKey={["key"]}
          bordered={false}
          ghost
          className={`${type ? styles.changeBoardHeader : undefined} ${
            type === "ai-bubble-view" && theme === "light"
              ? styles.lightBoardHeader
              : type === "ai-bubble-view" && theme === "brand"
              ? styles.brandBoardHeader
              : undefined
          }`}
        >
          <Panel
            header={
              z === "Reviews" ? (
                <div>
                  <img
                    className={styles.reviewsIcon}
                    src={
                      theme === "dark"
                        ? "/assets/reviews_icon.svg"
                        : "/assets/reviews_icon_black.svg"
                    }
                    alt=""
                  />
                  <span>{title}</span>
                </div>
              ) : selection ? (
                <h5 className={styles.header}>
                  <u className={styles.landscapeTitle} onClick={unselect}>
                    {title}
                  </u>
                  <RightOutlined className={styles.rightIcon} />
                  <span className={styles.selection}>{selection}</span>
                </h5>
              ) : (
                title
              )
            }
            key={"key"}
            showArrow={false}
            className={`${styles.content} ${
              z === "Reviews" ? styles.newLine : undefined
            }`}
            extra={<div onClick={(e) => e.stopPropagation()}>{extra}</div>}
            disabled={alwaysOpen}
          >
            {children}
          </Panel>
        </Collapse>
      </div>
    </Tooltip>
  );
};

export default BoardCard;
