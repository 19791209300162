import React, { useState, useEffect } from 'react';
import { Table, Checkbox} from 'antd';
const DashboardsSelection = ({
  dashboards,
  selectedDashboards,
  setSelectedDashboards,
  resetPopup
}) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth<950?true:false)
  useEffect(()=>{
    const handleResize = () =>{
      if (window.innerWidth<950){
        setIsMobileView(true);
      }
      else{
        setIsMobileView(false);
      }
    }
    window.addEventListener('resize', handleResize)
    
    return () =>{
      window.removeEventListener('resize',handleResize)
    }
  },[isMobileView])
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(selectedDashboards?.length === dashboards?.length);
  }, [selectedDashboards, dashboards]);

  useEffect(()=>{
    if(resetPopup){
      setSelectAll(false);
      setSelectedDashboards([]);
    }
    else{
      setSelectAll(selectedDashboards?.length===dashboards?.length)
    }
  },[resetPopup])

  useEffect(() => {
    setSelectAll(selectedDashboards?.length === dashboards?.length || (selectedDashboards?.length > 0 && selectedDashboards?.length < dashboards?.length));
  }, [selectedDashboards, dashboards]);

  const handleDashboardsSelection = (selectedDashboard) => {
    const updatedSelection = [...selectedDashboards];

    const dashboardIndex = updatedSelection.findIndex(
      (dashboard) => dashboard?.dashboardId === selectedDashboard?.dashboardId
    );

    if (dashboardIndex === -1) {
      updatedSelection.push(selectedDashboard);
    } else {
      updatedSelection.splice(dashboardIndex, 1);
    }

    setSelectedDashboards(updatedSelection);
  };

  const handleSelectAll = () => {
    const newSelectAll = !(selectedDashboards?.length === dashboards?.length || (selectedDashboards?.length > 0 && selectedDashboards?.length < dashboards?.length));
    setSelectAll(newSelectAll);
    const selected = newSelectAll ? dashboards : [];
    setSelectedDashboards(selected); 
  };
  const columns = [
    {
      title: (
        <Checkbox
          color="primary"
          checked={selectAll}
          indeterminate={(selectedDashboards?.length > 0 && selectedDashboards?.length < dashboards?.length)}
          onChange={handleSelectAll}
        />
      ),
      key: 'checkbox',
      render: (record) => (
        <Checkbox
          checked={Array.isArray(selectedDashboards) &&selectedDashboards.some(
            (dashboard) => dashboard?.dashboardId === record?.dashboardId
          )}
          onChange={() => handleDashboardsSelection(record)}
        />
      ),
    },
    {
      title: 'Dashboard Name',
      dataIndex: 'name',
      key: 'name',
      align:'center'
    },
    {
      title: 'Group Name',
      dataIndex: 'group',
      key: 'group',
      align:'center'
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      align:'center'
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      align:'center'
    },
  ];

  const data = dashboards?.map((dashboard, index) => ({
    dashboardId: dashboard.dashboardId,
    name: dashboard.name,
    group: dashboard.group,
    platform:dashboard.platform,
    lastUpdated: dashboard.lastUpdated,
  }));

  return (
    <div>
      <Table columns={isMobileView?columns?.filter((column)=>column.key !== 'platform' && column.key !== 'lastUpdated'):columns} dataSource={data} pagination={false} />
    </div>
  );
}

export default DashboardsSelection