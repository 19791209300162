import React, { useEffect, useRef, useState } from "react";
import { Doughnut, HorizontalBar, Bar, Line } from "react-chartjs-2";
import styles from "./style.module.scss";
import { Button, Drawer, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { CloseOutlined } from "@ant-design/icons";
import DropdownSelect from "helperComponents/CustomDropdown";

const ComparisonChart = ({
  chartData,
  chartType,
  setSubCategoryClicked,
  setSelectedCategoryForSubcategories,
  setSelectedGroupForReviews,
  barColorMap,
  doughnutColorMap,
  dateRange,
}) => {
  const comparisonData = chartData?.map(
    (dashboardData) => dashboardData?.comparisonData
  );
  const chartInstance = useRef(null);
  const [barType, setBarType] = useState(window.innerWidth > 949 ? "H" : "V");
  const [toggleSelection, setToggleSelection] = useState("Daily");
  const [activeBtn, setActiveBtn] = useState("Daily");
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = DashboardReducer.theme;
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedDisplayType, setSelectedDisplayType] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 949) {
        setBarType("H");
        setIsMobileView(false);
      } else {
        setBarType("V");
        setIsMobileView(true);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [barType, isMobileView]);
  const categoryLabels = comparisonData?.map((categoryData) =>
    categoryData?.map((data) => data?.category)
  )[0];
  const categoryIds = comparisonData?.map((categoryData) =>
    categoryData?.map((data) => data?.categoryId)
  )[0];
  const dashboardLabels = chartData?.map(
    (dashboardData) => dashboardData?.dashboard
  );
  const dashboardIds = chartData?.map(
    (dashboardData) => dashboardData?.dashboardId
  );
  const totalReviews = chartData?.map(
    (dashboardData) => dashboardData.total_reviews
  );
  const percentages = comparisonData?.map((categoryData) =>
    categoryData?.map((data) => data?.percentage)
  );
  const labels = comparisonData?.map((categoryData) =>
    categoryData?.map((data) => data?.category)
  );
  const ids = comparisonData?.map((categoryData) =>
    categoryData?.map((data) => data?.categoryId)
  );

  const datasets = [];
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  if (labels?.length > 0 && ids?.length > 0 && percentages?.length > 0) {
    // Iterate through the arrays and rearrange the data
    for (let i = 0; i < labels.length; i++) {
      for (let j = 0; j < labels[i].length; j++) {
        const label = labels[i][j];
        const id = ids[i][j];
        const percentage = percentages[i][j];
        const existingData = datasets.find(
          (data) => data.label === label && data.id === id
        );

        if (!existingData) {
          datasets.push({
            label: label,
            id: id,
            data: [percentage],
            backgroundColor: barColorMap[id],
            barThickness:
              barType === "H" ? 25 : dashboardIds?.length > 6 ? 25 : 35,
            borderSkipped: true,
            categoryPercentage: 0.7,
            lineTension: 0.4,
            borderRadius: {
              topLeft: 25,
              topRight: 25,
              bottomLeft: 25,
              bottomRight: 25,
            },
          });
        } else {
          existingData.data.push(percentage);
        }
      }
    }
  }
  const data = {
    labels: dashboardLabels,
    datasets: datasets,
  };
  const counts = comparisonData?.map((categoryData) =>
    categoryData?.map((data) => data?.count)
  );
  // Define a function that takes the labels, ids, and counts arrays as parameters
  function rearrangeCounts(labels, ids, counts) {
    // Create an empty array to store the rearranged counts
    let rearrangedCounts = [];
    if (labels?.length > 0 && ids?.length > 0 && counts?.length > 0) {
      // Loop through each subarray of labels, ids, and counts
      for (let i = 0; i < labels?.length; i++) {
        // Create an empty object to map each label to its corresponding id and count
        let labelMap = {};
        // Loop through each label, id, and count in the subarrays
        for (let j = 0; j < labels[i].length; j++) {
          // Assign the id and count as a value to the label as a key
          labelMap[labels[i][j]] = [ids[i][j], counts[i][j]];
        }
        // Create an empty array to store the rearranged subarray of counts
        let rearrangedSubarray = [];
        // Loop through the first subarray of labels as a reference
        for (let k = 0; k < labels[0].length; k++) {
          if (labelMap[labels[0][k]]?.length > 0) {
            let countValue = labelMap[labels[0][k]][1];
            // Push the count value to the rearranged subarray
            rearrangedSubarray.push(countValue);
          }
        }
        // Push the rearranged subarray to the rearranged counts array
        rearrangedCounts.push(rearrangedSubarray);
      }
    }
    // Return the rearranged counts array
    return rearrangedCounts;
  }
  const rearrangedCounts = rearrangeCounts(labels, ids, counts);
  const calculateNormalizedPercentages = (arr, total) => {
    if (arr?.length > 0) {
      const percentages = arr[0]?.map((_, i) => {
        const numerator = arr?.map((row, rowIndex) => {
          if (total[rowIndex] !== 0) {
            return row[i] / total[rowIndex];
          } else {
            return 0;
          }
        });
        const denominator = arr.reduce((acc, row, rowIndex) => {
          if (total[rowIndex] !== 0) {
            return acc + row[i] / total[rowIndex];
          } else {
            return acc;
          }
        }, 0);

        return numerator?.map((numerator) =>
          Math.floor(
            (numerator / (denominator === 0 ? 1 : denominator)) * 100
          ).toFixed(0)
        );
      });
      return percentages;
    }
  };
  const doughnutPercentages = calculateNormalizedPercentages(
    rearrangedCounts,
    totalReviews
  );
  const categoriesData = categoryLabels?.map((_, categoryIndex) =>
    doughnutPercentages?.map((array, arrayIndex) => array[categoryIndex])
  );
  const menuOptions = [
    {
      id: "1",
      label: "Display reviews",
      value: "1",
      img:
        theme === "dark"
          ? "/assets/display_reviews.svg"
          : "/assets/display_reviews_black.svg",
    },
    {
      id: "2",
      label: "Display subcategory stats",
      value: "2",
      img:
        theme === "dark"
          ? "/assets/display_charts.svg"
          : "/assets/display_charts_black.svg",
    },
  ];
  const handleCategoryClick = (event) => {
    if (chartInstance.current) {
      const chart = chartInstance.current.chartInstance;
      const activeElement = chart.getElementAtEvent(event);
      if (activeElement.length > 0) {
        const clickedItemIdx = activeElement[0]._index;
        const clickedDatasetIndex = activeElement[0]._datasetIndex;
        const clickedDataset = data.datasets[clickedDatasetIndex];
        const selectedStackData = {
          label: activeElement[0]._model.datasetLabel,
          id: clickedDataset.id,
        };
        if (selectedStackData.label && selectedStackData.id) {
          setSelectedCategoryForSubcategories(selectedStackData);
        }
        setSubCategoryClicked(true);
      }
    }
  };
  const handleDisplaySelectionType = (type) => {
    if (type === "1" && selectedTopic) {
      setSelectedGroupForReviews({
        label: dashboardLabels,
        bgColor: "rgb(116, 45, 224)",
        id: dashboardIds.map((item) => parseInt(item)),
        topicId: parseInt(selectedTopic.id),
        topicLabel: selectedTopic.label,
        clickIsFrom: "topics",
      });
    } else if (type === "2" && selectedTopic) {
      displaySubcategoryDoughnuts(selectedTopic.id);
    }
  };

  useEffect(() => {
    if (selectedDisplayType) {
      handleDisplaySelectionType(selectedDisplayType.id);
    }
  }, [selectedDisplayType]);
  const displaySubcategoryDoughnuts = (categoryId) => {
    const clickedDoughnut = {
      label: comparisonData?.map((categoryData) =>
        categoryData?.find((item) => item.categoryId === categoryId)
      )[0]?.category,
      id: categoryId,
    };
    if (clickedDoughnut.label && clickedDoughnut.id) {
      setSelectedCategoryForSubcategories(clickedDoughnut);
    }
    setSubCategoryClicked(true);
  };
  const adjustChartHeight = () => {
    if (dashboardIds?.length > 0) {
      switch (true) {
        case dashboardIds?.length < 5:
          return 300;
        case dashboardIds?.length >= 5 && dashboardIds?.length < 10:
          return 500;
        case dashboardIds?.length >= 10 && dashboardIds?.length < 15:
          return 800;
        case dashboardIds?.length >= 15 && dashboardIds?.length < 20:
          return 1200;
        default:
          return 1500;
      }
    }
  };
  const formatCountsForDoughnuts = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "K";
    } else {
      return count;
    }
  };
  const hasAlphabet = (str) => {
    return /[A-Za-z]/.test(str);
  };
  const options = {
    type: "horizontalBar",
    borderColor: "#808080",
    border: {
      display: false,
    },
    scales: {
      drawBorder: false,
      xAxes: [
        {
          stacked: true,
          borderSkipped: true,
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
            color:
              theme === "dark"
                ? "rgba(128, 128, 128, 0.4)"
                : "rgba(0, 0, 0, 0.2)",
          },
          axis: {
            color: "#808080",
          },
          ticks: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          borderColor: "#808080",
          border: {
            display: false,
          },
          ticks: {
            display: true,
            beginAtZero: true,
            autoSkip: false,
            padding: 20,
            fontColor: theme === "dark" ? "white" : "black",
            callback: function(value) {
              if (value && value.length > 0) {
                let maxLength = 20; // Maximum length for each line
                let words = value.split(" ");
                let firstLine = "";
                let secondLine = "";

                for (let i = 0; i < words.length; i++) {
                  let currentWord = words[i];

                  if (firstLine.length < maxLength) {
                    if ((firstLine + " " + currentWord).length <= maxLength) {
                      firstLine += (firstLine === "" ? "" : " ") + currentWord;
                    } else {
                      secondLine = currentWord;
                      break;
                    }
                  } else {
                    if (secondLine === "") {
                      secondLine =
                        currentWord.length > maxLength
                          ? currentWord.substr(0, maxLength - 3) + "..."
                          : currentWord;
                    } else if (
                      (secondLine + " " + currentWord).length <= maxLength
                    ) {
                      secondLine += " " + currentWord;
                    } else {
                      let remaining =
                        words
                          .slice(i)
                          .join(" ")
                          .substr(0, maxLength - 3) + "...";
                      secondLine += " " + remaining;
                      break;
                    }
                  }
                }

                return firstLine + "\n" + secondLine;
              }
            },
            callbacks: {
              title: function(tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
              },
            },
            displayColors: false,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            bodyFontSize: 14,
            titleFontSize: 14,
            titleFontColor: "#fff",
          },
          gridLines: {
            color:
              theme === "dark"
                ? "rgba(128, 128, 128, 0.4)"
                : "rgba(0, 0, 0, 0.2)",
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
          scaleLabel: {
            display: false,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    bar: {
      borderRadius: 15,
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          let label = data.datasets[tooltipItem.datasetIndex].label;
          return `${label}: ${round(value, 1)}`;
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event) => {
      handleCategoryClick(event);
    },
    plugins: {
      datalabels: {
        anchor: "center",
        align: "center",
        formatter: function(value, context) {
          if (
            (context.dataset.data[context.dataIndex] >= 0 &&
              context.dataset.data[context.dataIndex] < 1) ||
            isNaN(context.dataset.data[context.dataIndex])
          ) {
            return "";
          }
          return context.dataset.data[context.dataIndex] < 3
            ? Math.round(value)
            : Math.round(value) + "%";
        },
        color: (context) => {
          const bgColor = context.dataset.backgroundColor;
          if (
            bgColor === "#D2B5F2" ||
            bgColor === "#56DEC8" ||
            bgColor === "#FFBFFF" ||
            bgColor === "#CCCCCC" ||
            bgColor === "#9BC3DE" ||
            bgColor === "#E7EDEF"
          ) {
            return "#4400AA";
          } else {
            return "white";
          }
        },
      },
    },
  };
  const verticalBarOptions = {
    borderColor: "#808080",
    border: {
      display: false,
    },
    scales: {
      drawBorder: false,
      yAxes: [
        {
          stacked: true,
          beginAtZero: true,
          max: 100,
          stepSize: 5,
          borderSkipped: true,
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
            color:
              theme === "dark"
                ? "rgba(128, 128, 128, 0.4)"
                : "rgba(0, 0, 0, 0.2)",
          },
          axis: {
            color: "#808080",
          },
          ticks: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          borderColor: "#808080",
          border: {
            display: false,
          },
          ticks: {
            display: true,
            beginAtZero: true,
            max: 100,
            autoSkip: false,
            maxRotation: 90,
            minRotation: 90,
            padding: 20,
            fontColor: theme === "dark" ? "white" : "black",
            maxTicksLimit: 10,
            max: 100,
            callback: function(value) {
              if (value && value.length > 0) {
                let maxLength = 20; // Maximum length for each line
                let words = value.split(" ");
                let firstLine = "";
                let secondLine = "";

                for (let i = 0; i < words.length; i++) {
                  let currentWord = words[i];

                  if (firstLine.length < maxLength) {
                    if ((firstLine + " " + currentWord).length <= maxLength) {
                      firstLine += (firstLine === "" ? "" : " ") + currentWord;
                    } else {
                      secondLine = currentWord;
                      break;
                    }
                  } else {
                    if (secondLine === "") {
                      secondLine =
                        currentWord.length > maxLength
                          ? currentWord.substr(0, maxLength - 3) + "..."
                          : currentWord;
                    } else if (
                      (secondLine + " " + currentWord).length <= maxLength
                    ) {
                      secondLine += " " + currentWord;
                    } else {
                      let remaining =
                        words
                          .slice(i)
                          .join(" ")
                          .substr(0, maxLength - 3) + "...";
                      secondLine += " " + remaining;
                      break;
                    }
                  }
                }

                return firstLine + "\n" + secondLine;
              }
            },
            callbacks: {
              title: function(tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
              },
            },
            displayColors: false,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            bodyFontSize: 14,
            titleFontSize: 14,
            titleFontColor: "#fff",
          },
          gridLines: {
            color:
              theme === "dark"
                ? "rgba(128, 128, 128, 0.4)"
                : "rgba(0, 0, 0, 0.2)",
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
          scaleLabel: {
            display: false,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    bar: {
      borderRadius: 15,
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          let value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          let label = data.datasets[tooltipItem.datasetIndex].label;
          return `${label}: ${round(value, 1)}`;
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event) => {
      handleCategoryClick(event);
    },
    plugins: {
      // roundedBar:roundedBarPlugin,
      datalabels: {
        anchor: "center",
        align: "center",
        fontSize: dashboardIds?.length > 4 ? "8px" : "10px",
        formatter: function(value, context) {
          if (
            context.dataset.data[context.dataIndex] <= 2 ||
            isNaN(context.dataset.data[context.dataIndex])
          ) {
            return "";
          }
          return Math.round(value) + "%";
        },
        color: (context) => {
          const bgColor = context.dataset.backgroundColor;
          if (
            bgColor === "#D2B5F2" ||
            bgColor === "#56DEC8" ||
            bgColor === "#FFBFFF" ||
            bgColor === "#CCCCCC" ||
            bgColor === "#9BC3DE" ||
            bgColor === "#E7EDEF"
          ) {
            return "#4400AA";
          } else {
            return "white";
          }
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true,
          },
          wheel: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
  };
  const renderLegend = (data) => {
    if (chartType === "C2") {
      return data[0]?.labels?.map((label, index) => (
        <div key={index}>
          <span
            className={styles.legendColor}
            style={{
              backgroundColor: data[0]?.datasets[0]?.backgroundColor[index],
            }}
          />
          <span className={styles.legendLabel}>{label}</span>
        </div>
      ));
    }
    return (
      <div className={styles.legendC}>
        {data?.datasets?.map((dataset, index) => (
          <div key={`legend-${index}`} className={styles.legendDisplay}>
            <span
              className={styles.legendColor}
              style={{ backgroundColor: dataset.backgroundColor }}
            />
            <span className={styles.legendLabel}>{dataset.label}</span>
          </div>
        ))}
      </div>
    );
  };
  const handleToggleSelection = (selection) => {
    setToggleSelection(selection);
    setActiveBtn(selection);
  };
  const doughnutDataArray = [];
  const renderChart = () => {
    if (chartType === "C1") {
      if (barType === "H") {
        return (
          <div
            className={styles.HorizontalBar}
            style={{ height: adjustChartHeight() }}
          >
            <HorizontalBar
              data={data}
              options={options}
              ref={(reference) => (chartInstance.current = reference)}
            />
          </div>
        );
      } else if (barType === "V") {
        const chart = (
          <Bar
            data={data}
            options={verticalBarOptions}
            ref={(reference) => (chartInstance.current = reference)}
          />
        );

        if (dashboardIds?.length > 3) {
          return <div className={styles.overflowBars}>{chart}</div>;
        }
        return chart;
      }
    } else if (chartType === "C2") {
      const doughnutCharts = [];
      const zeroDoughnuts = [];
      for (let i = 0; i < categoriesData?.length; i++) {
        let sum = doughnutPercentages[i].reduce((acc, val) => acc + val, 0);
        const dashboardIds = chartData?.map(
          (dashboardData) => dashboardData.dashboardId
        );
        const doughnutChartData = {
          labels: chartData?.map((dashboardData) => dashboardData.dashboard),
          datasets: [
            {
              id: dashboardIds,
              data: doughnutPercentages[i],
              backgroundColor: dashboardIds?.map((id) => doughnutColorMap[id]),
              keyId: categoryIds[i],
              keyLabel: categoryLabels[i],
            },
          ],
        };
        doughnutDataArray.push(doughnutChartData);
        const handleDoughnutClick = (activeElement) => {
          if (activeElement) {
            const clickedDataset = doughnutChartData.datasets[0];
            const selectedDoughnutData = {
              label: [activeElement._model.label],
              bgColor: activeElement._model.backgroundColor,
              id: [parseInt(clickedDataset.id[activeElement._index])],
              topicId: parseInt(clickedDataset.keyId),
              topicLabel: clickedDataset.keyLabel,
              clickIsFrom: "groups",
            };
            setSelectedGroupForReviews(selectedDoughnutData);
          }
        };
        const doughnutChartOptions = {
          responsive: true,
          plugins: {
            datalabels: {
              anchor: "center",
              align: "center",
              formatter: function(value, context) {
                if (
                  context.dataset.data[context.dataIndex] == 0 ||
                  isNaN(context.dataset.data[context.dataIndex])
                ) {
                  return "";
                }
                const itemData = comparisonData[context.dataIndex].find(
                  (item) => item.categoryId === context.dataset.keyId
                );
                const formattedCount = formatCountsForDoughnuts(itemData.count);
                const lineBreak = "\n";
                const spaceBreak = "\t";
                return context.dataset.data[context.dataIndex] <= 2
                  ? value + "%"
                  : hasAlphabet(formattedCount)
                  ? `${spaceBreak}${" "}${value +
                      "%"}${lineBreak}(${formattedCount})`
                  : `${" "}${value + "%"}${lineBreak}(${formattedCount})`;
              },
              color: (context) => {
                const bgColor =
                  context.dataset.backgroundColor[context.dataIndex];

                if (
                  bgColor === "#D2B5F2" ||
                  bgColor === "#56DEC8" ||
                  bgColor === "#FFBFFF" ||
                  bgColor === "#CCCCCC" ||
                  bgColor === "#9BC3DE" ||
                  bgColor === "#E7EDEF"
                ) {
                  return "#4400AA";
                } else {
                  return "white";
                }
              },
            },
          },
          cutoutPercentage: 45,
          maintainAspectRatio: false,
          outerRadius: 45,
          cornerRadius: 7,
          reponsive: true,
          aspectRatio: 1,
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          legend: {
            display: false,
            position: "bottom",
            labels: {
              fontColor: theme === "dark" ? "white" : "black",
            },
          },
          onClick: function(e) {
            var point = this.getElementAtEvent(e)[0];
            if (point) {
              handleDoughnutClick(point);
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const itemData = comparisonData[tooltipItem.index].find(
                  (item) => item.categoryId === data.datasets[0].keyId
                );
                return `${data.labels[tooltipItem.index]}: ${itemData.count}`;
              },
            },
          },
        };
        if (Number(sum) === 0) {
          zeroDoughnuts.push(
            <div className={styles.chartWithLabel}>
              <div className={styles.doughnut}>
                <Doughnut
                  data={doughnutChartData}
                  options={doughnutChartOptions}
                  width={230}
                />
                <span className={styles.noDataDoughnutMsg}>No Data</span>
              </div>
              <h6
                title={categoryLabels[i]}
                className={styles.categoryName}
                onClick={() => displaySubcategoryDoughnuts(categoryIds[i])}
              >
                {categoryLabels[i]}
              </h6>
            </div>
          );
        } else {
          doughnutCharts.push(
            <div className={styles.chartWithLabel} key={`category-${i + 1}`}>
              <div className={styles.doughnut}>
                <Doughnut
                  data={doughnutChartData}
                  ref={(reference) => (chartInstance.current = reference)}
                  options={doughnutChartOptions}
                  width={230}
                />
              </div>
              <h6
                title={categoryLabels[i]}
                className={styles.categoryName}
                onClick={() =>
                  setSelectedTopic({
                    id: categoryIds[i],
                    label: categoryLabels[i],
                  })
                }
              >
                <div className={styles.contextMenu}>
                  <DropdownSelect
                    options={menuOptions}
                    selectedOptions={selectedDisplayType}
                    setSelectedOptions={setSelectedDisplayType}
                    hideImg={true}
                    hiddenTriggerLabel={categoryLabels[i]}
                    triggerEvent={"click"}
                  />
                </div>
              </h6>
            </div>
          );
        }
      }
      return [...doughnutCharts, ...zeroDoughnuts];
    } else if (chartType === "C3") {
      let dateArray = [];
      let uniqueAllDates = [];
      if (dateRange && dateRange.length > 0) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        let currentDate = startDate;
        while (currentDate <= endDate) {
          dateArray.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        const allDates = dateArray.map((date) => {
          let year = date.getFullYear();
          let month =
            toggleSelection === "Monthly"
              ? date.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                })
              : date.getMonth() + 1;
          let day = date.getDate();

          if (toggleSelection === "Daily") {
            day = day < 10 ? "0" + day : day;
            month = month < 10 ? "0" + month : month;
            return `${day}-${month}-${year}`;
          } else if (toggleSelection === "Monthly") {
            return month.replace(/ /g, "' ");
          } else if (toggleSelection === "Yearly") {
            return `${year}`;
          }
        });
        uniqueAllDates = [...new Set(allDates)];
      } else {
        let allTimeDates = categoryIds?.map((categoryId) => {
          const datesForOneChart = [];
          for (let i = 0; i < comparisonData.length; i++) {
            const dashboardData = comparisonData[i];
            const foundData = dashboardData.find(
              (data) => data.categoryId === categoryId
            );
            if (foundData) {
              const dataByTime =
                foundData.dataByTime[toggleSelection.toLowerCase()];
              // loop through the dataByTime array and replace the zeros with the data values
              for (let j = 0; j < dataByTime.length; j++) {
                const data = dataByTime[j];
                datesForOneChart.push(data.date);
              }
            }
          }
          return datesForOneChart;
        });
        uniqueAllDates = Array.from(new Set([].concat(...allTimeDates)));
      }

      if (uniqueAllDates && uniqueAllDates.length === 1 && uniqueAllDates[0]) {
        let date = new Date(uniqueAllDates[0]);
        let dates = [];
        let prevDate = new Date(date);
        prevDate.setDate(prevDate.getDate() - 1);
        let nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate() + 1);
        if (toggleSelection === "Daily") {
          let prevDay =
            prevDate.getDate() < 10
              ? "0" + prevDate.getDate()
              : prevDate.getDate();
          let prevMonth =
            prevDate.getMonth() + 1 < 10
              ? "0" + (prevDate.getMonth() + 1)
              : prevDate.getMonth() + 1;
          let prevYear = prevDate.getFullYear();
          let nextDay =
            nextDate.getDate() < 10
              ? "0" + nextDate.getDate()
              : nextDate.getDate();
          let nextMonth =
            nextDate.getMonth() + 1 < 10
              ? "0" + (nextDate.getMonth() + 1)
              : nextDate.getMonth() + 1;
          let nextYear = nextDate.getFullYear();
          dates.push(`${prevDay}-${prevMonth}-${prevYear}`);
          dates.push(uniqueAllDates[0]);
          dates.push(`${nextDay}-${nextMonth}-${nextYear}`);
        } else if (toggleSelection === "Monthly") {
          let prevMonth = new Date(date);
          prevMonth.setMonth(prevMonth.getMonth() - 1);
          prevMonth = prevMonth
            .toLocaleDateString("en-US", { year: "numeric", month: "short" })
            .replace(/ /g, "' ");
          let nextMonth = new Date(date);
          nextMonth.setMonth(nextMonth.getMonth() + 1);
          nextMonth = nextMonth
            .toLocaleDateString("en-US", { year: "numeric", month: "short" })
            .replace(/ /g, "' ");
          dates.push(prevMonth);
          dates.push(uniqueAllDates[0]);
          dates.push(nextMonth);
        } else if (toggleSelection === "Yearly") {
          let prevYear = new Date(date);
          prevYear.setFullYear(prevYear.getFullYear() - 1);
          prevYear = prevYear.getFullYear();
          let nextYear = new Date(date);
          nextYear.setFullYear(nextYear.getFullYear() + 1);
          nextYear = nextYear.getFullYear();
          dates.push(`${prevYear}`);
          dates.push(uniqueAllDates[0]);
          dates.push(`${nextYear}`);
        }
        uniqueAllDates = dates;
      }

      const lineChartsData = categoryIds?.map((categoryId) => {
        const dataForOneChart = { labels: [], datasets: [] };
        for (let i = 0; i < comparisonData.length; i++) {
          const dashboardData = comparisonData[i];
          const foundData = dashboardData.find(
            (data) => data.categoryId === categoryId
          );
          if (foundData) {
            const dashboardDataset = {
              label: dashboardLabels[i],
              data: [], // initialize the data array with zeros
              backgroundColor: doughnutColorMap[dashboardIds[i]],
              fill: false,
              borderColor: doughnutColorMap[dashboardIds[i]],
              lineTension: 0.5,
              borderWidth: 2,
              pointRadius: 0,
              pointHoverRadius: isMobileView ? 1 : 2,
            };
            // fill the data array with zeros based on the uniqueAllDates length
            dashboardDataset.data = Array(uniqueAllDates.length).fill(0);
            // use the toggleSelection to determine which dataByTime property to use
            const dataByTime =
              foundData.dataByTime[toggleSelection.toLowerCase()];
            // loop through the dataByTime array and replace the zeros with the data values
            for (let j = 0; j < dataByTime.length; j++) {
              const data = dataByTime[j];
              // find the index of the data date in the uniqueAllDates array
              const index = uniqueAllDates.indexOf(data.date);
              // replace the zero at that index with the data count
              dashboardDataset.data[index] = data.count;
            }
            // copy the uniqueAllDates array to the dataForOneChart labels
            dataForOneChart.labels = [...uniqueAllDates];
            // push the dashboardDataset to the dataForOneChart datasets
            dataForOneChart.datasets.push(dashboardDataset);
          }
        }
        return dataForOneChart;
      });
      const updatedCategoryData = lineChartsData?.reduce(
        (result, chartData, index) => {
          const categoryName = categoryLabels[index];
          const categoryId = categoryIds[index];
          if (
            chartData?.datasets
              ?.map((d) => d.data)
              .flat()
              .every((element) => element === 0)
          ) {
            result.emptyCharts.push({ chartData, categoryName, categoryId });
          } else {
            result.nonEmptyCharts.push({ chartData, categoryName, categoryId });
          }

          return result;
        },
        { emptyCharts: [], nonEmptyCharts: [] }
      );

      const finalCategoryData = [
        ...updatedCategoryData.nonEmptyCharts,
        ...updatedCategoryData.emptyCharts,
      ];
      const tooltipLine = {
        id: "tooltipLine",
        beforeDraw: (chart) => {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();
            const activePoint = chart.tooltip._active[0];
            ctx.beginPath();
            ctx.setLineDash([4, 6]);
            ctx.moveTo(activePoint?._view?.x, chart.chartArea.top);
            ctx.lineTo(activePoint?._view?.x, chart.chartArea.bottom);
            ctx.lineWidth = 2;
            ctx.strokeStyle = "rgba(155, 155, 155)";
            ctx.stroke();
            ctx.restore();
          }
        },
      };
      const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "point",
          intersect: true,
        },
        interaction: {
          mode: "point",
        },
        tooltips: {
          backgroundColor: "rgba(0,0,0,0.5)",
          mode: "label",
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                color: theme === "dark" ? "#262250" : "rgba(0, 0, 0, 0.2)",
                display: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                fontColor: theme === "dark" ? "white" : "black",
                padding: 15,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Reviews Count",
                fontColor: theme === "dark" ? "white" : "black",
              },
              ticks: {
                beginAtZero: true,
                display: true,
                padding: 15,
                fontColor: theme === "dark" ? "white" : "black",
              },
              gridLines: {
                display: true,
                color: theme === "dark" ? "#262250" : "rgba(0, 0, 0, 0.2)",
                drawTicks: false,
                drawOnChartArea: false,
              },
            },
          ],
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            fontColor: theme === "dark" ? "white" : "black",
            pointStyle: "circle",
            padding: 20,
          },
          borderRadius: 20,
          position: "bottom",
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      };
      const emptyLineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color:
                  theme === "dark"
                    ? "rgba(128, 128, 128, 0.4)"
                    : "rgba(0, 0, 0, 0.2)",
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                fontColor: theme === "dark" ? "white" : "black",
                padding: 20,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Reviews Count",
                fontColor: theme === "dark" ? "white" : "black",
              },
              ticks: {
                display: false,
                beginAtZero: true,
                padding: 20,
                fontColor: theme === "dark" ? "white" : "black",
                fontSize: isMobileView ? 10 : 12,
              },
              gridLines: {
                color:
                  theme === "dark"
                    ? "rgba(128, 128, 128, 0.4)"
                    : "rgba(0, 0, 0, 0.2)",
                display: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
      const displaySubcategoryLines = (categoryId) => {
        const clickedLine = {
          label: comparisonData?.map((categoryData) =>
            categoryData?.find((item) => item.categoryId === categoryId)
          )[0]?.category,
          id: categoryId,
        };
        if (clickedLine.label && clickedLine.id) {
          setSelectedCategoryForSubcategories(clickedLine);
        }
        setSubCategoryClicked(true);
      };
      return (
        <div className={styles.chartContainer}>
          <div className={styles.toggleBtnContainer}>
            <Button
              className={
                activeBtn === "Daily"
                  ? styles.activeToggleBtn
                  : styles.toggleBtn
              }
              onClick={() => handleToggleSelection("Daily")}
            >
              Daily
            </Button>
            <Button
              className={
                activeBtn === "Monthly"
                  ? styles.activeToggleBtn
                  : styles.toggleBtn
              }
              onClick={() => handleToggleSelection("Monthly")}
            >
              Monthly
            </Button>
            <Button
              className={
                activeBtn === "Yearly"
                  ? styles.activeToggleBtn
                  : styles.toggleBtn
              }
              onClick={() => handleToggleSelection("Yearly")}
            >
              Yearly
            </Button>
          </div>
          <div className={styles.lineChartContainer}>
            {finalCategoryData?.map(
              ({ chartData, categoryName, categoryId }, index) => (
                <div>
                  {!chartData?.datasets
                    ?.map((d) => d.data)
                    .flat()
                    .every((element) => element === 0) ? (
                    <div className={styles.chartWithLabel}>
                      <span
                        title={categoryName}
                        className={styles.categoryName}
                        onClick={() =>
                          setSelectedTopic({
                            id: categoryId,
                            label: categoryName,
                          })
                        }
                      >
                        <div className={styles.contextMenu}>
                          <DropdownSelect
                            options={menuOptions}
                            selectedOptions={selectedDisplayType}
                            setSelectedOptions={setSelectedDisplayType}
                            hideImg={true}
                            hiddenTriggerLabel={categoryName}
                          />
                        </div>
                      </span>
                      <div className={styles.lineChart}>
                        <Line
                          data={{
                            ...chartData,
                            datasets: chartData.datasets.map((d) => ({
                              ...d,
                              hidden: d.data.every((x) => x === 0),
                            })),
                          }}
                          options={{
                            ...lineOptions,
                            scales: {
                              xAxes: [
                                {
                                  display: true,
                                  gridLines: {
                                    color:
                                      theme === "dark"
                                        ? "rgba(255, 255, 255, 0.6)"
                                        : "rgba(0, 0, 0, 0.6)",
                                    display: true,
                                    drawOnChartArea: false,
                                    drawTicks: false,
                                  },
                                  ticks: {
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                    padding: 15,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  display: true,
                                  scaleLabel: {
                                    display: true,
                                    labelString: "Reviews Count",
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                  },
                                  ticks: {
                                    beginAtZero: true,
                                    display: true,
                                    padding: 15,
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                    suggestedMax:
                                      Math.max(
                                        ...chartData?.datasets
                                          ?.map((d) => d.data)
                                          .flat()
                                      ) + 5,
                                  },
                                  gridLines: {
                                    display: true,
                                    color:
                                      theme === "dark"
                                        ? "rgba(255, 255, 255, 0.6)"
                                        : "rgba(0, 0, 0, 0.6)",
                                    drawTicks: false,
                                    drawOnChartArea: false,
                                  },
                                },
                              ],
                            },
                          }}
                          plugins={tooltipLine}
                        />
                      </div>
                      <div className={styles.lineChartLegend}>
                        {chartData.datasets.map((d, index) => (
                          <div
                            key={`legend-${index}`}
                            style={{
                              textDecoration: d.data.every((x) => x === 0)
                                ? "line-through"
                                : "none",
                            }}
                          >
                            <span
                              className={styles.legendColor}
                              style={{ backgroundColor: d.backgroundColor }}
                            />
                            <span className={styles.legendLabel}>
                              {d.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.chartWithLabel}>
                      <span
                        title={categoryName}
                        className={styles.categoryName}
                        onClick={() => displaySubcategoryLines(categoryId)}
                      >
                        {categoryName}
                      </span>
                      <div className={styles.emptyLineChart}>
                        <Line
                          data={{
                            ...chartData,
                            datasets: chartData.datasets.map((d) => ({
                              ...d,
                              hidden: true,
                            })),
                          }}
                          options={emptyLineOptions}
                        />
                        <span className={styles.noDataMsg}>
                          No Data Available
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <div className={styles.Chart}>{renderChart()}</div>
      <div className={styles.Legend}>
        {chartType === "C1"
          ? renderLegend(data)
          : renderLegend(doughnutDataArray)}
      </div>
    </>
  );
};

export default ComparisonChart;
