import React, { useEffect, useRef, useState } from "react";
import ReviewCard from "./reviewCard";
import styles from "./style.module.scss";

const ScrollingCards = ({ reviews }) => {
    const wrapper = useRef();
    if (reviews?.length === 0) return "";
    return (
        <div className={styles.cardsWrapper} ref={wrapper}>
            {reviews.map((info, index) => {
                const reviewIndex = index % reviews?.length
                const currentReview = reviews[reviewIndex];
                return (
                    <div className={styles.cardWrapper}>
                        <ReviewCard review={currentReview} />
                    </div>
                );
            })}
        </div>
    );
};

export default ScrollingCards;