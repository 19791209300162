import React, { useRef, useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import styles from "./style.module.scss";

const BarChartWrapper = ({
  data,
  selectedTopics,
  setSelectedTopics,
  metric,
  stacked,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const chartRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 950);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);

  // Extract labels, ratings, and children for chart data
  const removeParentFromChildren = (parent) => {
    if (parent && parent !== "" && parent.includes(">")) {
      const substrs = parent.split(">").slice(-1)[0];
      return substrs;
    } else {
      return parent;
    }
  };

  const labels = data.map((item) => item.label);
  const ids = data.map((item) => item.id);
  const values = data.map((item) => item[metric]);
  const children = data.map((item) => item?.child);
  const noOfBars = labels?.length;

  // Configure chart data for stacked and non-stacked cases
  const chartData = {
    labels: labels,
    datasets: stacked
      ? [
          {
            label: "Negative",
            data: data.map((item) => item[metric]?.negative?.percentage),
            backgroundColor: "rgba(220, 37, 37, 1)",
            barThickness:
              window.innerWidth < 599 ? "flex" : noOfBars < 10 ? 20 : "flex",
            borderSkipped: true,
            categoryPercentage: 0.7,
            barPercentage: 0.7,
            lineTension: 1.0,
          },
          {
            label: "Neutral",
            data: data.map((item) => item[metric]?.neutral?.percentage),
            backgroundColor: "rgb(255, 255, 0, 0.8)",
            barThickness:
              window.innerWidth < 599 ? "flex" : noOfBars < 10 ? 20 : "flex",
            borderSkipped: true,
            categoryPercentage: 0.7,
            barPercentage: 0.7,
            lineTension: 1.0,
          },
          {
            label: "Positive",
            data: data.map((item) => item[metric]?.positive?.percentage),
            backgroundColor: "rgba(16, 200, 0, 0.8)",
            barThickness:
              window.innerWidth < 599 ? "flex" : noOfBars < 10 ? 20 : "flex",
            borderSkipped: true,
            categoryPercentage: 0.7,
            barPercentage: 0.7,
            lineTension: 1.0,
          },
          {
            label: "Unspecified",
            data: data.map((item) => item[metric]?.unspecified?.percentage),
            backgroundColor: "rgba(224, 131, 17, 0.664)",
            barThickness:
              window.innerWidth < 599 ? "flex" : noOfBars < 10 ? 20 : "flex",
            borderSkipped: true,
            categoryPercentage: 0.7,
            barPercentage: 0.7,
            lineTension: 1.0,
          },
        ]
      : [
          {
            label: metric === "rating" ? "Rating" : "Sentiment",
            data: values?.map((val) => val?.value),
            datalabels: {
              display: false,
            },
            backgroundColor: "#4400AA",
            barThickness:
              window.innerWidth < 599 ? "flex" : noOfBars < 10 ? 20 : "flex",
            borderSkipped: true,
            categoryPercentage: 0.7,
            barPercentage: 0.7,
            lineTension: 1.0,
          },
        ],
  };
  // Configure chart options
  const chartOptions = {
    scales: {
      yAxes: [
        {
          stacked: stacked,
          gridLines: {
            color: "#808080",
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
          scaleLabel: metric === "sentiment" && "Sentiment (%)",
          borderSkipped: true,
          axis: {
            color: "#808080",
          },
          display: true,
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            padding: 10,
            fontColor: theme === "dark" ? "white" : "black",
            maxTicksLimit: 6,
          },
        },
      ],
      xAxes: [
        {
          stacked: stacked,
          border: {
            display: false,
          },
          ticks: {
            display: true,
            autoSkip: false,
            padding: 10,
            maxRotation: 60,
            minRotation: 60,
            fontColor: theme === "dark" ? "white" : "black",
            fontSize: 10,
            displayColors: false,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            callback: (val) => {
              if (removeParentFromChildren(val) !== "") {
                return removeParentFromChildren(val).length > 9
                  ? removeParentFromChildren(val).substr(0, 9) + "..."
                  : removeParentFromChildren(val);
              } else {
                return val.length > 9 ? val.substr(0, 9) + "..." : val;
              }
            },
          },
          gridLines: {
            color: "#808080",
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
          },
        },
      ],
    },
    display: false,
    responsive: true,
    maintainAspectRatio: false,
    bar: {
      borderRadius: 15,
    },
    legend: {
      display: false,
    },
    plugins: {},
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event) => {
      if (chartRef.current) {
        const chart = chartRef.current.chartInstance;
        const activeElement = chart.getElementAtEvent(event);
        if (activeElement?.length > 0) {
          const clickedIndex = activeElement[0]._index;
          if (children[clickedIndex]?.length > 0) {
            if (Object.keys(selectedTopics).length === 0) {
              setSelectedTopics({
                [ids[clickedIndex]]: {
                  level: 1,
                  id: ids[clickedIndex],
                  label: labels[clickedIndex],
                },
              });
            } else {
              setSelectedTopics((prev) => {
                const prevLevels = Object.values(prev).map(
                  (item) => item.level
                );
                const maxLevel = Math.max(...prevLevels);
                const newLevel =
                  maxLevel + (prevLevels.includes(maxLevel) ? 1 : 0);
                return {
                  ...prev,
                  [ids[clickedIndex]]: {
                    level: newLevel,
                    id: ids[clickedIndex],
                    label: labels[clickedIndex],
                  },
                };
              });
            }
          } else {
            return;
          }
        }
      }
    },
  };

  const [chartWidth, setChartWidth] = useState("100%");
  useEffect(() => {
    if (noOfBars > 20) {
      setChartWidth(noOfBars * 40);
    }
  }, [noOfBars]);

  return (
    <div className={styles.barChartWrapper}>
      <div style={window.innerWidth > 1100 ? { width: chartWidth } : {}}>
        <Bar
          data={chartData}
          height={250}
          options={{
            ...chartOptions,
            tooltips: {
              enabled: false,
              custom: function(tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById("chartjs-tooltip");

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip";
                  tooltipEl.innerHTML = "<div></div>";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                  if (tooltipEl) {
                    tooltipEl.remove();
                  }
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }

                function getBody(bodyItem) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  let innerHtml = "<div class=" + styles.driversTooltip + ">";
                  const index = tooltipModel.dataPoints[0].index;
                  const hoveredPoint = tooltipModel.dataPoints[0].datasetIndex;
                  let stackVal = "";
                  stackVal = "positive";
                  if (hoveredPoint === 2) {
                  } else if (hoveredPoint === 1) {
                    stackVal = "neutral";
                  } else if (hoveredPoint === 0) {
                    stackVal = "negative";
                  } else if (hoveredPoint === 3) {
                    stackVal = "unspecified";
                  }
                  if (stacked) {
                    innerHtml += `
                    <span class=${styles.stackInfo}>
                      <span class="${styles.stackColor} ${styles[stackVal]}" ></span> 
                      <span class=${styles.stackLabel}>${stackVal}</span>
                    </span>
                  `;
                  }
                  innerHtml += `<span class=${
                    styles.parentLabel
                  }>${removeParentFromChildren(labels[index])}: ${
                    stacked
                      ? values[index][stackVal]?.percentage
                        ? `${values[index][stackVal].percentage.toFixed(1)}% (${
                            values[index][stackVal].value
                          })`
                        : 0
                      : `${values[index]["value"]} (${values[index]["reviewsCount"]})`
                  }</span>`;
                  if (children[index]?.length > 0) {
                    if (metric === "rating") {
                      innerHtml += `
                        <span class=${styles.childLabel}>
                          Child Ratings
                        </span>`;
                    } else {
                      innerHtml += `
                      <span class=${styles.childLabel}>
                        Child Sentiment
                      </span>`;
                    }
                    innerHtml += `<div class=${styles.childContainer}>`;
                    children[index].forEach((child) => {
                      innerHtml += `<span>${removeParentFromChildren(
                        child.label
                      )}: ${
                        stacked
                          ? child[metric][stackVal]?.percentage
                            ? `${child[metric][stackVal]?.percentage.toFixed(
                                1
                              )}% (${child[metric][stackVal]?.value})`
                            : 0
                          : `${child[metric]["value"]} (${child[metric]["reviewsCount"]})`
                      }</span>`;
                    });
                    innerHtml += "</div>";
                  }
                  innerHtml += "</div>";
                  const tooltipRoot = tooltipEl.querySelector("div");
                  tooltipRoot.innerHTML = innerHtml;
                }
                // this will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX +
                  "px";
                tooltipEl.style.top =
                  position.top +
                  window.pageYOffset +
                  tooltipModel.caretY +
                  "px";
                tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding =
                  tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
                tooltipEl.style.pointerEvents = "none";
              },
            },
            plugins: {
              datalabels: {
                display: false,
              },
            },
          }}
          ref={(ref) => (chartRef.current = ref)}
        />
      </div>
    </div>
  );
};

export default BarChartWrapper;
