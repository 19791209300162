import React, { useEffect, useState } from "react";
import Dropdown from "react-multilevel-dropdown";
import { Checkbox, Button, Input, Switch, Tooltip } from "antd";
import { RightOutlined } from "@ant-design/icons";
import styles from "./style.module.scss";
import DropdownSelect from "helperComponents/CustomDropdown";
const { Item, Submenu } = Dropdown;

const MLDropdown = ({
  title,
  levelWiseOptions,
  levelWiseSelections,
  setLevelWiseSelections,
  level1Pos,
  subLevelPos,
  reset,
  apply,
  disabled,
  selectedFilterType,
  setSelectedFilterType,
}) => {
  const [searchTerms, setSearchTerms] = useState({});
  const handleSelection = (path) => {
    setLevelWiseSelections((prevSelections) => {
      const updatedSelections = { ...prevSelections };
      let currentLevel = updatedSelections;
      let removeMode = false;
      path.forEach((id, index) => {
        if (index === path.length - 1) {
          if (currentLevel[id]) {
            delete currentLevel[id];
            removeMode = true;
          } else {
            currentLevel[id] = {};
          }
        } else {
          if (!currentLevel[id]) {
            currentLevel[id] = {};
          }
          currentLevel = currentLevel[id];
        }
      });

      const cleanUpEmptySelections = (obj, path) => {
        for (let i = path.length - 1; i >= 0; i--) {
          const key = path[i];
          const parent = path.slice(0, i);
          let currLevel = updatedSelections;
          parent.forEach((p) => {
            currLevel = currLevel[p];
          });
          if (currLevel[key] && Object.keys(currLevel[key]).length === 0) {
            delete currLevel[key];
          }
        }
      };
      if (removeMode) {
        cleanUpEmptySelections(updatedSelections, path);
      }
      return updatedSelections;
    });
  };

  const isChecked = (path) => {
    let currentLevel = levelWiseSelections;
    for (let id of path) {
      if (!currentLevel[id]) {
        return false;
      }
      currentLevel = currentLevel[id];
    }
    return true;
  };

  const countSelections = (selections, all = false) => {
    let count = 0;
    const traverseSelections = (obj) => {
      Object.keys(obj).forEach((key) => {
        count++;
        traverseSelections(obj[key]);
      });
    };
    if (!all) {
      traverseSelections(selections);
    } else {
      const level1Options = levelWiseOptions?.level1;
      const eachOptionSelections = level1Options?.map((option) =>
        countSelections(
          levelWiseSelections[option.id] ? levelWiseSelections[option.id] : {}
        )
      );
      count = eachOptionSelections.reduce((a, b) => a + b, 0);
    }
    return count;
  };

  const hasSelections = (selections) => {
    const traverseSelections = (obj) => {
      return Object.keys(obj).some((key) => {
        if (Object.keys(obj[key]).length > 0) {
          return traverseSelections(obj[key]);
        }
        return true;
      });
    };
    return traverseSelections(selections);
  };
  const applyOptions = [
    {
      id: "1",
      label: "Intersection",
      value: "AND",
    },
    {
      id: "2",
      label: "Union",
      value: "OR",
    },
  ];
  const renderSubmenuOptions = (options, path, parentId = "") => {
    const currentLevel = `level${path.length + 1}`;
    const filteredOptions = options.filter((option) =>
      option.label
        .toLowerCase()
        .includes(searchTerms[currentLevel]?.toLowerCase() || "")
    );

    return (
      <div key={parentId}>
        {path.length >= 1 && (
          <div className={styles.searchBar}>
            <Input
              placeholder="Search..."
              disabled={disabled}
              value={searchTerms[currentLevel] || ""}
              onChange={(e) =>
                setSearchTerms({
                  ...searchTerms,
                  [currentLevel]: e.target.value,
                })
              }
            />
          </div>
        )}
        {filteredOptions.map((option) => {
          const currentPath = [...path, option.id];
          const hasSubOptions =
            levelWiseOptions[`level${path.length + 2}`] &&
            levelWiseOptions[`level${path.length + 2}`][option.id];

          return (
            <Item
              key={option.id}
              className={
                isChecked(currentPath) ? styles.checkedItem : styles.item
              }
              isDisabled={disabled}
              isActive={!disabled}
              onClick={(e) => {
                e.stopPropagation();
                handleSelection(currentPath);
              }}
            >
              <Checkbox checked={isChecked(currentPath)} disabled={disabled}>
                <span className={styles.level2Options} title={option.label}>
                  <span
                    className={styles.optionLabel}
                    onClick={() => {
                      handleSelection(currentPath);
                    }}
                  >
                    {option.label}
                  </span>
                </span>
              </Checkbox>
              {hasSubOptions && (
                <Submenu position={subLevelPos} className={styles.subMenu}>
                  {renderSubmenuOptions(
                    levelWiseOptions[`level${path.length + 2}`][option.id],
                    currentPath,
                    option.id
                  )}
                </Submenu>
              )}
            </Item>
          );
        })}
      </div>
    );
  };
  return (
    <div className={styles.MLDropdown}>
      <Dropdown
        title={title}
        position={level1Pos}
        disabled={disabled}
        wrapperClassName={styles.wrapper}
        menuClassName={styles.menu}
        buttonClassName={styles.filtersBtn}
        isDisabled={disabled}
        isActive={!disabled}
      >
        {levelWiseOptions.level1.map((option) => {
          const hasSubOptions =
            levelWiseOptions.level2 && levelWiseOptions.level2[option.id];
          const currentPath = [option.id];

          return (
            <Item key={option.id} className={styles.item} isDisabled={disabled}>
              <span className={styles.level1Options} title={option.label}>
                <span className={styles.optionLabel}>{option.label}</span>
              </span>
              <span className={styles.level1ExtraMenu}>
                {levelWiseSelections[option.id] &&
                  countSelections(levelWiseSelections[option.id]) > 0 && (
                    <span className={styles.noOfFiltersApplied}>
                      {countSelections(levelWiseSelections[option.id])}
                    </span>
                  )}
                <RightOutlined style={{ fontSize: "12px" }} />
              </span>
              {hasSubOptions && (
                <Submenu position={subLevelPos} className={styles.subMenu}>
                  {renderSubmenuOptions(
                    levelWiseOptions.level2[option.id],
                    currentPath,
                    option.id
                  )}
                </Submenu>
              )}
            </Item>
          );
        })}
        {levelWiseSelections && hasSelections(levelWiseSelections) && (
          <div className={styles.dropdownActions}>
            {countSelections(levelWiseSelections, true) > 1 && (
              <div className={styles.actions}>
                <Button
                  className={
                    selectedFilterType === "AND"
                      ? styles.applyBtn
                      : styles.discardBtn
                  }
                  onClick={() => setSelectedFilterType("AND")}
                >
                  <Tooltip title="Intersection"> ∩ </Tooltip>
                </Button>
                <Button
                  className={
                    selectedFilterType === "OR"
                      ? styles.applyBtn
                      : styles.discardBtn
                  }
                  onClick={() => setSelectedFilterType("OR")}
                >
                  <Tooltip title="Union"> ∪ </Tooltip>
                </Button>
              </div>
            )}
            <div className={styles.actions}>
              <Button
                className={styles.discardBtn}
                disabled={disabled}
                onClick={reset}
              >
                Reset
              </Button>
              <Button
                type="primary"
                disabled={disabled}
                className={styles.applyBtn}
                onClick={() => apply(selectedFilterType)}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </Dropdown>
    </div>
  );
};

export default MLDropdown;
