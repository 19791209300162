import React, { useEffect, useState } from "react";
import { Button, Progress } from "antd";
import styles from "./style.module.scss";
import Tour from "reactour";
import { scrollToElement } from "util/common";
import Modal from "antd/lib/modal/Modal";
import i18n from 'i18next';

const quicknavsteps = [
    {
        selector: "#quicknav",
        content: () => <div>{i18n.t("share_guide.1")}</div>,
    },
];

const trendsviewsteps = [
    {
        selector: "#viewbutton",
        content: () => <div>{i18n.t("share_guide.2")}</div>,
    },
];

const trendsviewtoursteps = [
    {
        selector: "#trendsview_header",
        content: () => <div>{i18n.t("share_guide.3")}</div>,
    },
    {
        selector: "#trendsview_chart",
        content: () => <div>{i18n.t("share_guide.4")}</div>,
    },
    {
        selector: "[class^=style_itemMainWindow]",
        content: () => <div>{i18n.t("share_guide.5")}</div>,
    },
    {
        selector: "[class^=style_itemMainWindow] [class^=style_left] h4",
        content: () => <div>{i18n.t("share_guide.6")}</div>,
    },
    {
        selector:
            "[class^=style_itemMainWindow] [class^=style_left] [class^=style_topwords]",
        content: () => <div>{i18n.t("share_guide.7")}</div>,
    },
    {
        selector: "[class^=style_itemMainWindow] [class^=style_rightChart]",
        content: () => <div>{i18n.t("share_guide.8")}</div>,
    },
    {
        selector: "",
        content: () => <div>{i18n.t("share_guide.9")}</div>,
    },
];

const Guide = ({
    topicSelected,
    chosenTopics,
    allowed,
    selectQuickNav,
    openModal,
    blur,
    authenticated,
}) => {
    const [isTour1Open, setIsTour1Open] = useState(false);
    const [isTour2Open, setIsTour2Open] = useState(false);
    const [isTour3Open, setIsTour3Open] = useState(false);

    const [hidden, setHidden] = useState(true);

    const [viewClicked, setViewClicked] = useState(false);
    const [quickNavClicked, setQuickNavClicked] = useState(false);
    const [TopicSelected, setTopicSelected] = useState(false);
    const [blurred, setBlurred] = useState(false);
    const [trendsViewTourFinished, setTrendsViewTourFinished] = useState(false);
    const [scroll, setScroll] = useState(false);

    const checkFinished = [
        viewClicked,
        quickNavClicked,
        TopicSelected,
        // (blurred || authenticated === "logged"),
        trendsViewTourFinished,
        scroll,
        (allowed || authenticated === "logged"),
        selectQuickNav,
    ];

    const [modal, setModal] = useState(true);

    useEffect(() => {
        let el = document.querySelector("#guide_header");
        let el2 = document.querySelector("#guide_shared");

        var styles = window.getComputedStyle(el);
        var margin =
            parseFloat(styles["marginTop"]) +
            parseFloat(styles["marginBottom"]);

        let header = Math.ceil(el.offsetHeight + margin) - 10;
        el2.style.bottom = `-${el2.offsetHeight - header}px`;

        document.getElementById("viewbutton").addEventListener("click", () => {
            if (!viewClicked) {
                setViewClicked(true);
                setIsTour2Open(false);
            }
        });

        document.getElementById("quicknav").addEventListener("click", () => {
            if (!quickNavClicked) {
                setQuickNavClicked(true);
                setIsTour1Open(false);
            }
        });

        // setModal(checkFinished.every((cur) => cur));
    }, []);

    useEffect(() => {
        if (topicSelected) {
            setTopicSelected(true);
        }
    }, [topicSelected]);

    useEffect(() => {
        if (blur) {
            setBlurred(true);
        }
    }, [blur]);

    const hideWindow = () => {
        let el = document.querySelector("#guide_shared");
        if (!hidden) {
            el.style.transform = `translateY(0)`;
            setHidden(!hidden);
        }
    };

    const showWindow = () => {
        let el = document.querySelector("#guide_shared");
        if (hidden) {
            el.style.transform = `translateY(-${el.offsetHeight - 58}px)`;
            setHidden(!hidden);
        }
    };

    return (
        <>
            <Tour
                steps={quicknavsteps}
                isOpen={isTour1Open}
                onRequestClose={() => {
                    setIsTour1Open(false);
                }}
                onAfterOpen={() => (document.body.style.overflowY = "hidden")}
                onBeforeClose={() =>
                    (document.body.style.overflowY = "visible")
                }
                accentColor="#4400AA"
                showButtons={false}
                showNavigation={false}
                scrollOffset={1}
            />

            <Tour
                steps={trendsviewsteps}
                isOpen={isTour2Open}
                onRequestClose={() => {
                    setIsTour2Open(false);
                }}
                onAfterOpen={() => (document.body.style.overflowY = "hidden")}
                onBeforeClose={() =>
                    (document.body.style.overflowY = "visible")
                }
                accentColor="#4400AA"
                showButtons={false}
                showNavigation={false}
                scrollOffset={1}
            />

            <Tour
                steps={trendsviewtoursteps}
                isOpen={isTour3Open}
                onRequestClose={() => {
                    setIsTour3Open(false);
                    setTrendsViewTourFinished(true);
                }}
                onAfterOpen={() => (document.body.style.overflowY = "hidden")}
                onBeforeClose={() =>
                    (document.body.style.overflowY = "visible")
                }
                accentColor="#4400AA"
                scrollOffset={1}
            />

            <div id="guide_shared" className={styles.guide}>
                {!hidden && (
                    <span
                        id="guide_close"
                        className={styles.guide_close}
                        onClick={hideWindow}
                    >
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                )}
                <h4
                    id="guide_header"
                    className={styles.guide_header}
                    onClick={() => {
                        showWindow();
                        hideWindow();
                    }}
                >
                    {checkFinished.every((cur) => cur)
                        ? "User Guide"
                        : "User Guide"}
                </h4>
                <div className={styles.guide_wrapper}>
                    <ol className={styles.guide_list}>
                        <li className={styles.guide_list_item}>
                            <div
                                style={{
                                    textDecoration:
                                        TopicSelected && "line-through",
                                }}
                            >
                                {i18n.t("share_guide.10")}
                            </div>
                        </li>
                        {TopicSelected && (
                            <>
                                <li className={styles.guide_list_item}>
                                    <div
                                        style={{
                                            textDecoration:
                                                scroll && "line-through",
                                        }}
                                    >
                                        {i18n.t("share_guide.11")}
                                    </div>
                                    <Button
                                        type="primary"
                                        className="primary-background"
                                        onClick={() => {
                                            scrollToElement(
                                                "SelectedTopicInfo"
                                            );
                                            setScroll(true);
                                            hideWindow();
                                        }}
                                        disabled={scroll}
                                    >
                                        {i18n.t("share_guide.12")}
                                    </Button>
                                </li>
                                {/* {authenticated === "unlogged" && (
                                    <li className={styles.guide_list_item}>
                                        <div
                                            style={{
                                                textDecoration:
                                                    blurred && "line-through",
                                            }}
                                        >
                                            Continue exploring topics
                                        </div>

                                        <Progress
                                            percent={
                                                blurred
                                                    ? 100
                                                    : chosenTopics * 25
                                            }
                                            steps={4}
                                            strokeColor="#ffab00"
                                        />
                                    </li>
                                )} */}
                            </>
                        )}
                        {blurred && (
                            <li className={styles.guide_list_item}>
                                <div
                                    style={{
                                        textDecoration:
                                            allowed && "line-through",
                                    }}
                                >
                                    {i18n.t("share_guide.13")}
                                </div>
                                <Button
                                    type="primary"
                                    className="primary-background"
                                    onClick={() => {
                                        openModal();
                                        hideWindow();
                                    }}
                                    disabled={allowed}
                                >
                                    {i18n.t("share_guide.12")}
                                </Button>
                            </li>
                        )}

                        <li className={styles.guide_list_item}>
                            <div
                                style={{
                                    textDecoration:
                                        quickNavClicked && "line-through",
                                }}
                            >
                                {i18n.t("share_guide.14")}
                            </div>
                            <Button
                                type="primary"
                                className="primary-background"
                                onClick={() => {
                                    scrollToElement("Dashboard_wrapper");
                                    setTimeout(() => {
                                        setIsTour1Open(true);
                                    }, 1000);
                                    hideWindow();
                                }}
                                disabled={quickNavClicked}
                            >
                                {i18n.t("share_guide.12")}
                            </Button>
                        </li>
                        {quickNavClicked && (
                            <li className={styles.guide_list_item}>
                                <div
                                    style={{
                                        textDecoration:
                                            selectQuickNav && "line-through",
                                    }}
                                >
                                    {i18n.t("share_guide.15")}
                                </div>
                            </li>
                        )}

                        <li className={styles.guide_list_item}>
                            <div
                                style={{
                                    textDecoration:
                                        viewClicked && "line-through",
                                }}
                            >
                                {i18n.t("share_guide.16")}
                            </div>
                            <Button
                                type="primary"
                                className="primary-background"
                                onClick={() => {
                                    scrollToElement("Dashboard_wrapper");
                                    setTimeout(() => {
                                        setIsTour2Open(true);
                                    }, 1000);
                                    hideWindow();
                                }}
                                disabled={viewClicked}
                            >
                                {i18n.t("share_guide.12")}
                            </Button>
                        </li>
                        {viewClicked && (
                            <li className={styles.guide_list_item}>
                                <div
                                    style={{
                                        textDecoration:
                                            trendsViewTourFinished &&
                                            "line-through",
                                    }}
                                >
                                    {i18n.t("share_guide.17")}
                                </div>
                                <Button
                                    type="primary"
                                    className="primary-background"
                                    onClick={() => {
                                        scrollToElement("Dashboard_wrapper");
                                        setTimeout(() => {
                                            setIsTour3Open(true);
                                        }, 1000);
                                        hideWindow();
                                    }}
                                    disabled={trendsViewTourFinished}
                                >
                                    {i18n.t("share_guide.12")}
                                </Button>
                            </li>
                        )}
                    </ol>
                </div>
            </div>

            <Modal
                title={i18n.t("share_guide.18")}
                visible={checkFinished.every((cur) => cur) && modal}
                forceRender
                onOk={() => setModal(false)}
                onCancel={() => setModal(false)}
                okButtonProps={{ style: { borderRadius: 8 } }}
                cancelButtonProps={{ style: { borderRadius: 8 } }}
            >
                <p style={{ marginTop: "20px" }}>
                    {i18n.t("share_guide.19")}{" "}
                    <span style={{ color: "#ffab00" }}>{i18n.t("share_guide.20")}</span>
                </p>
            </Modal>
        </>
    );
};

export default Guide;
