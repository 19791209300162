import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { notification, Table } from "antd";
import Representation from "./Representation";
import { apiRequest } from "util/services";
import i18n from "i18next";

const Organizations = ({ user }) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });

  const [showNewOrganization, setShowNewOrganization] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [updateQuotaModal, setUpdateQuotaModal] = useState(false);
  const [unassignedDashboards, setUnassignedDashboards] = useState();
  const [quota, setQuota] = useState(20);
  const [showDeleteTeam, setShowDeleteTeam] = useState(false);
  const [showRenameTeam, setShowRenameTeam] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [teamDashboards, setTeamDashboards] = useState(null);
  const [teamForInfo, setTeamForInfo] = useState(null);

  const fetchOrganizations = useCallback(() => {
    const payload = {
      user_id: user.uid,
    };
    apiRequest("users/organization/info", "POST", payload, true).then(
      (result) => {
        if (!result.error) {
          setOrganizations(result.success);
          setUnassignedDashboards(result.unassigned_dashboards);
        } else {
          notification.error({
            message: result.error,
          });
        }
      }
    );
  }, [user.uid]);
  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);
  const getTeamDashboardsInfo = async (team_id) => {
    const payload = {
      team_id: team_id,
    };
    const result = await apiRequest(
      "users/team/dashboards/info",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      if (typeof result.success === "object") {
        setTeamDashboards(result.success);
      } else if (typeof result.success === "string") {
        notification.info({
          message: result.success,
        });
      }
    } else {
      notification.error({
        message: result.error
          ? result.error
          : "Unable to get team dashboards info",
      });
    }
  };

  const closeNewOrganizationView = () => {
    fetchOrganizations();
    setShowNewOrganization(false);
  };

  const deleteOrganization = (id) => {
    setDisableBtn(true);
    const payload = {
      user_id: user.uid,
      org_id: organizationId,
    };
    apiRequest("users/organization/delete", "POST", payload, true).then(
      (result) => {
        if (!result.error) {
          setDisableBtn(false);
          setShowDeleteTeam(false);
          fetchOrganizations();
          notification.success({
            message: i18n.t("notifications.126"),
          });
        } else {
          setDisableBtn(false);
          notification.error({
            message: result.error,
          });
        }
      }
    );
  };

  const inviteMembers = (id) => {
    history.push(`/settings/team/invite/${id}`);
  };

  const updateQuota = () => {
    const payload = {
      user_id: user.uid,
      organization_id: updateQuotaModal,
      new_quota: quota,
    };
    apiRequest("users/organization/updateQuota", "POST", payload, true).then(
      (result) => {
        if (!result.error) {
          fetchOrganizations();
          notification.success({
            message: i18n.t("notifications.127"),
          });
          setUpdateQuotaModal(null);
          setQuota(20);
        } else {
          notification.error({
            message: result.error,
          });
        }
      }
    );
  };

  const renameTeam = async () => {
    if (!teamName) {
      notification.error({
        message: i18n.t("notifications.128"),
      });
      return;
    }
    setDisableBtn(true);
    const payload = {
      team_name: teamName,
      team_id: organizationId,
    };

    const response = await apiRequest(
      "/users/organization/teams/rename",
      "POST",
      payload,
      true
    );

    if (response.success) {
      notification.success({
        message: i18n.t("notifications.129"),
      });
      setDisableBtn(false);
      setShowRenameTeam(false);
      fetchOrganizations();
    } else {
      notification.error({
        message: response.error,
      });
      setDisableBtn(false);
    }
  };

  const onHeaderMenuClick = (val, organizationId, organizationName) => {
    if (val === "delete") {
      setOrganizationId(organizationId);
      setShowDeleteTeam(true);
    } else if (val === "updateQuota") {
      setUpdateQuotaModal(organizationId);
    } else if (val === "rename") {
      setShowRenameTeam(true);
      setTeamName(organizationName);
      setOrganizationId(organizationId);
    } else if (val === "invite") {
      inviteMembers(organizationId);
    } else {
      setTeamForInfo(organizationName);
      getTeamDashboardsInfo(organizationId);
    }
  };

  return (
    <Representation
      user={user}
      showNewOrganization={showNewOrganization}
      setShowNewOrganization={setShowNewOrganization}
      organizations={organizations}
      onHeaderMenuClick={onHeaderMenuClick}
      closeNewOrganizationView={closeNewOrganizationView}
      updateQuotaModal={updateQuotaModal}
      setUpdateQuotaModal={setUpdateQuotaModal}
      updateQuota={updateQuota}
      setQuota={setQuota}
      quota={quota}
      isMobile={isMobile}
      unassignedDashboards={unassignedDashboards}
      showRenameTeam={showRenameTeam}
      setShowRenameTeam={setShowRenameTeam}
      renameTeam={renameTeam}
      setTeamName={setTeamName}
      teamName={teamName}
      disableBtn={disableBtn}
      showDeleteTeam={showDeleteTeam}
      setShowDeleteTeam={setShowDeleteTeam}
      deleteOrganization={deleteOrganization}
      teamDashboards={teamDashboards}
      setTeamDashboards={setTeamDashboards}
      teamForInfo={teamForInfo}
      setTeamForInfo={setTeamForInfo}
    />
  );
};

export default Organizations;
