import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import {
  Radio,
  Input,
  DatePicker,
  Select,
  Spin,
  Button,
  notification,
} from "antd";
import { apiRequest, uploadFileToS3 } from "util/services";
import moment from "moment";
import EditableTagGroup from "./EditableTagGroup";
import MultiSelectDropdown from "pages/IndustryDashboard/helperComponents/MultiSelectDropdown/MultiSelectDropdown";
import FileUpload from "components/NewDashboard/FileUpload";
import { useTranslation } from "react-i18next";

const CreationItem = ({
  item,
  columnNames,
  setColumnNames,
  localState,
  setState,
  defaultValue,
  user,
  style,
  value,
  reset,
  setReset,
  disabled,
}) => {
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [userFiles, setUserFiles] = useState([
    { S3Link: "", filename: "Choose a file or Upload" },
  ]);
  const { t } = useTranslation();
  // const [ecommerceCreationLinkType, setEcommerceCreationLinkType] = useState("")

  useEffect(() => {
    const getFiles = async () => {
      const result = await apiRequest("dashboards/files", "POST", {
        user_id: user.uid,
      });
      if (result)
        setUserFiles([
          { S3Link: "", filename: "Choose a file or Upload" },
          ...result,
        ]);
    };
    if (item.valueType === "customerFile") {
      getFiles();
    }
  }, [item.valueType]);

  const loadUserInput = () => {
    if (item.valueType === "radio") {
      if (!localState[item.key]) {
        setState({ ...localState, [item.key]: item.acceptedValues[0].key });
      }
      return (
        <div className={`${styles.content}`} id="custom_radio_input">
          <Radio.Group
            value={defaultValue ? defaultValue : item.acceptedValues[0].key}
            onChange={(e) =>
              setState({ ...localState, [item.key]: e.target.value })
            }
          >
            {item.acceptedValues.map((iterable) => {
              return <Radio value={iterable.key}>{iterable.text}</Radio>;
            })}
          </Radio.Group>
        </div>
      );
    }
    if (item.valueType === "input") {
      return (
        <div className={`${styles.content}`}>
          <Input
            id="custom_text_input"
            value={defaultValue}
            placeholder={item.placeholder}
            onChange={(e) =>
              setState({ ...localState, [item.key]: e.target.value })
            }
          />
        </div>
      );
    }
    if (item.valueType === "numeric") {
      return (
        <div className={`${styles.content}`}>
          <input
            id="custom_numeric_input"
            value={defaultValue}
            onChange={(e) => {
              // if(parseInt(e.target.value, 10) < 10 || parseInt(e.target.value, 10) > 50) {
              //   notification.error({
              //     message: "Number of topics must be between 10 and 50"
              //   })
              // }
              setState({
                ...localState,
                [item.key]: parseInt(e.target.value, 10),
              });
            }}
            placeholder={item.placeholder}
            type="number"
            min="10"
            max="50"
          />
        </div>
      );
    }
    if (item.valueType === "dropdown") {
      const options = item.acceptedValues || columnNames;
      if (!localState[item.key] && item.defaultValue) {
        setState({ ...localState, [item.key]: item.defaultValue });
      }
      return (
        <div className={`${styles.content}`} id="custom_dropdown">
          <Select
            style={{ width: "100%" }}
            onChange={(value) => setState({ ...localState, [item.key]: value })}
            value={defaultValue || "default"}
            showSearch
            allowClear
            optionFilterProp="title"
          >
            {item.placeholder && (
              <Select.Option key={item.placeholder} value={"default"}>
                {item.placeholder}
              </Select.Option>
            )}
            {options.map((item) => {
              return (
                <Select.Option title={item} key={item} value={item}>
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      );
    }
    if (item.valueType === "dropdown_2") {
      return (
        <div className={`${styles.content}`} id="custom_dropdown">
          <Select
            style={{ width: "100%" }}
            onChange={(value) => setState({ ...localState, [item.key]: value })}
            showSearch
            optionFilterProp="title"
            value={value}
            disabled={disabled}
          >
            <Select.Option key={item.placeholder} value={"default"}>
              {item.placeholder}
            </Select.Option>
            {item.acceptedValues.map((item) => {
              return (
                <Select.Option
                  title={item.text}
                  key={item.value}
                  value={item.value}
                >
                  {item.text}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      );
    }
    if (item.valueType === "dropdownMulti") {
      const options = item.acceptedValues || columnNames;
      return (
        <div
          className={`${styles.content} ${styles.multiContent}`}
          id="custom_multidropdown"
        >
          <Select
            mode="multiple"
            showSearch
            allowClear
            optionFilterProp="title"
            style={{ width: "100%" }}
            placeholder={item.placeholder}
            defaultValue={defaultValue}
            onChange={(value) => setState({ ...localState, [item.key]: value })}
          >
            {options.map((country) => {
              return (
                <Select.Option title={country} key={country}>
                  {country}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      );
    }
    if (item.valueType == "multiselect") {
      const options = item.acceptedValues || columnNames;
      const optionsForDropdown = options?.map((opt) => ({
        label: opt,
        id: opt,
        value: opt,
      }));
      return (
        <div
          className={`${styles.content} ${styles.multiselect}`}
          id="multiselectdropdown"
        >
          <MultiSelectDropdown
            selectionLimit={10}
            showSearch={true}
            placeholderOfSearchBar={item.placeholder}
            setSelectedOptions={(value) =>
              setState({ ...localState, [item.key]: value })
            }
            options={optionsForDropdown}
            tagCount={"responsive"}
          />
        </div>
      );
    }
    if (item.valueType === "date") {
      return (
        <div
          className={`${styles.content}`}
          id={"custom_data_input_" + item.key}
        >
          <DatePicker
            value={defaultValue ? moment(defaultValue) : defaultValue}
            onChange={(date, dateString) => {
              setState({ ...localState, [item.key]: dateString });
            }}
          />
        </div>
      );
    }
    if (item.valueType === "customerFile") {
      return (
        // <div className={`${styles.content} ${styles.customerFile}`} id="customer_file_input">
        //   <select
        //     onChange={(e) =>
        //       setState({
        //         ...localState,
        //         [item.key]: e.target.value,
        //       })
        //     }
        //   >
        //     {userFiles.map((file) => {
        //       return <option value={file.S3Link}>{file.filename}</option>;
        //     })}
        //   </select>
        // </div>
        <FileUpload
          fileName={localState[item.key]?.split("/")?.pop()}
          spinning={uploading}
          progress={progress}
          handleFiles={handleFiles}
          setColumnNames={setColumnNames}
        />
      );
    }
    if (item.valueType === "multiInput") {
      return (
        <div
          className={`${styles.content} ${styles.customerFile}`}
          id="custom_multiinput"
        >
          <EditableTagGroup
            type={item.key}
            addTags={(data) =>
              setState({
                ...localState,
                [item.key]: data,
              })
            }
            defaultValue={defaultValue}
            reset={reset}
            setResetKeys={setReset}
          />
        </div>
      );
    }
  };

  const handleFiles = async (files) => {
    if (!files[0].name.includes(".csv") && !files[0].name.includes(".xls")) {
      notification.error({
        message: "The file should be a .csv or a .xls file",
      });
      return;
    }
    setUploading(true);
    const urlResponse = await apiRequest("dashboards/bucket", "POST", {
      user_id: user.uid,
      filename: files[0].name,
    });
    if (urlResponse) {
      const result = await uploadFileToS3(urlResponse, files[0], setProgress);
      if (result) {
        setState({
          ...localState,
          [item.key]: urlResponse.fields.key,
        });
        notification.success({ message: t("notifications.99") });
        setUserFiles([
          { S3Link: urlResponse.fields.key, filename: files[0].name },
          ...userFiles,
        ]);
      } else {
        notification.error({
          message: t("notifications.100"),
        });
      }
    } else {
      notification.error({
        message: "Action denied!",
      });
    }
    setUploading(false);
  };

  return (
    <div className={styles.container} style={style}>
      {(item.title || item.subtitle) &&
      (item.key === "product_links" || item.key === "category_links") ? (
        <div className={styles.radioItemsWrapper}>
          <div className={styles.box}>
            <div className={styles.radioItem}>
              <input
                type="radio"
                name="ecommerce-selection-item"
                value={item.key}
                id={item.key}
                checked={localState?.ecommerceCreationLinkType === item.key}
                onChange={(e) => {
                  setState({
                    ...localState,
                    ecommerceCreationLinkType: e.target.value,
                  });
                }}
              />
              <label htmlFor={item.key}>
                {item.key === "category_links"
                  ? t("others.55")
                  : t("others.56")}
              </label>
            </div>
            {localState.ecommerceCreationLinkType &&
              localState.ecommerceCreationLinkType === item.key && (
                <div className={styles.wrapItems}>
                  <div>
                    <h5>
                      {item.title}
                      {item.required ? " *" : ""}
                    </h5>
                    <h6>{item.subtitle}</h6>
                  </div>
                  {localState.ecommerceCreationLinkType &&
                    localState.ecommerceCreationLinkType === item.key &&
                    loadUserInput()}
                </div>
              )}
          </div>
        </div>
      ) : (
        <>
          <div className={styles.label}>
            <h5>
              {item.title}
              {item.required ? " *" : ""}
            </h5>
            <h6>{item.subtitle}</h6>
          </div>
          {loadUserInput()}
        </>
      )}
    </div>
  );
};

export default CreationItem;
