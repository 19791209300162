import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.scss';
import { Select, notification, Spin, Button } from 'antd';
import SortPopup from 'helperComponents/SortPopup';
import { useSelector } from 'react-redux';
import AlertPopup from 'components/GlobalExecutiveDashboard/Alert';
import { apiRequest } from 'util/services';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { copyToClipboard } from 'util/common';
const { Option } = Select;

const Alerts = () => {
   const { t } = useTranslation();
   const [searchValue, setSearchValue] = useState('');
   const [sortValue, setSortValue] = useState('newest');
   const [showRowItems, setShowRowItems] = useState(true);
   const [showSearchBox, setShowSearchBox] = useState(false);
   const [showSortPopup, setShowSortPopup] = useState(false);
   const [conditionFilter, setConditionFilter] = useState("all");
   const [alertsList, setAlertsList] = useState([]);
   const [alertsLoading, setAlertsLoading] = useState(false);
   const [startIndex, setStartIndex] = useState(0);
   const [alertsPageNumber, setAlertsPageNumber] = useState(1);
   const [selectedPerPage, setSelectedPerPage] = useState(10);
   const [numOfAlerts, setNumOfAlerts] = useState(10);
   const [totalAlerts, setTotalAlerts] = useState(0);
   const [disableBtn, setDisableBtn] = useState(false);
   const [showMoreOption, setShowMoreOption] = useState(false);
   const [showEditAlert, setShowEditAlert] = useState(false);
   const [showAlertInfoPopup, setShowAlertInfoPopup] = useState(false);
   const [showDeleteAlert, setShowDeleteAlert] = useState(false);
   const [selectedAlertID, setSelectedAlertID] = useState('');
   const [selectedAlertUsers, setSelectedAlertUsers] = useState([]);
   const [sendSMSCheckbox,setSendSMSCheckbox] = useState(false);
   const [selectedAlertCondition, setSelectedAlertCondition] = useState('')
   const [selectedAlertThreshold, setSelectedAlertThreshold] = useState(null);
   const [selectedAlertFrequency, setSelectedAlertFrequency] = useState('');
   const [showEmptyAlert, setShowEmptyAlert] = useState(false);
   const [alertInfo, setAlertInfo] = useState([]);
   const moreOptionRef = useRef();
   const history = useHistory();

   const theme = useSelector(state => state.DashboardReducer.theme);

   const getAlerts = async (reset) => {
      const payload = {
         start_index: startIndex,
         num_per_page: numOfAlerts,
         condition: conditionFilter,
         sort: sortValue,
         keyword: reset ? "" : searchValue
      }

      const response = await apiRequest(
         'notice/alerts',
         'POST',
         payload,
         true
      )
      if (response.success) {
         setAlertsList(response.success?.alerts);
         setTotalAlerts(response.success?.number_alerts)
         setAlertsLoading(false);
         if (response.success.alerts?.length === 0) setShowEmptyAlert(true);
         else setShowEmptyAlert(true)
      }
      else {
         notification.error({
            message: response.error
         })
         setAlertsLoading(false)
      }
   }

   useEffect(() => {
      setAlertsLoading(true)
      getAlerts();
   }, [numOfAlerts, startIndex, sortValue, conditionFilter])

   const handleSearch = (event) => {
      if (event.key === 'Enter') {
         if (searchValue.trim()) {
            getAlerts();
         } else {
            getAlerts();
         }
      }
   }

   const handleShowSearch = () => {
      if (window.innerWidth < 880) {
         setShowRowItems(false);
      }
      setShowSearchBox(!showSearchBox);
   }

   const handleShowEditAlert = (alert) => {
      setSelectedAlertCondition(alert.condition);
      setSelectedAlertFrequency(alert.frequency);
      setSelectedAlertThreshold(alert.threshold);
      setSelectedAlertUsers(alert.send_to);
      setSendSMSCheckbox(alert.send_sms);
      setShowEditAlert(true);
   }

   const handleShowDeleteAlert = () => {
      setShowDeleteAlert(true);
   }

   const handleShowAlertInfo = (alert) => {
      setAlertInfo(alert)
      setShowAlertInfoPopup(true);
   }

   const handleUserPage = (e) => {
      if (e.which === 13) {
         if (!alertsPageNumber) {
            notification.info({
               message: t("notifications.2"),
            });
         }
         else if (alertsPageNumber > 0 && alertsPageNumber <= Math.ceil(totalAlerts / selectedPerPage) && /^\d+$/.test(alertsPageNumber)) {
            setStartIndex(alertsPageNumber - 1);
            e.target.blur();
         } else {
            notification.info({
               message: t("notifications.2"),
            });
         }
      }
   }

   const handlePageChange = (value) => {
      setSelectedPerPage(value);
      setNumOfAlerts(value);
      setStartIndex(0);
      setAlertsPageNumber(1);
   }

   const optionOutClick = (e) => {
      if (moreOptionRef.current && !moreOptionRef.current.contains(e.target)) {
         setShowMoreOption(false)
      }
   }
   useEffect(() => {
      document.addEventListener('click', optionOutClick, true);
      return () => document.removeEventListener('click', optionOutClick, true)
   }, []);

   const handleEditAlert = async (condition, threshold, users, frequency,smsBoxChecked) => {
      setDisableBtn(true)
      const payload = {
         alert_id: selectedAlertID,
         condition: condition,
         threshold: parseInt(threshold, 10),
         send_to: users,
         frequency: frequency,
         sms_checked: smsBoxChecked
      }

      const response = await apiRequest(
         'notice/alerts/edit',
         'POST',
         payload,
         true
      )
      if (response.success) {
         notification.success({
            message: t("notifications.145")
         })
         setDisableBtn(false);
         setShowEditAlert(false);
         getAlerts();
      }
      else {
         notification.error({
            message: response.error
         })
         setDisableBtn(false);
      }
   }

   const handleDeleteAlert = async () => {
      setDisableBtn(true);
      const payload = {
         alert_id: selectedAlertID
      }
      const response = await apiRequest(
         'notice/alerts/delete',
         'POST',
         payload,
         true
      )
      if (response.success) {
         notification.success({
            message: t("notifications.146")
         })
         setDisableBtn(false);
         setShowDeleteAlert(false);
         getAlerts();
      }
      else {
         notification.error({
            message: response.error
         })
         setDisableBtn(false);
      }
   }

   const getComparison = str => {
      if (str === 'daily') return t("alerts_page.30")
      else if (str === 'hourly') return t("alerts_page.29")
      else if (str === 'weekly') return t("alerts_page.31")
   }

   const handleCopy = str => {
      if (str) {
         copyToClipboard(str);
         notification.success({
            message: t("notifications.54")
         })
      }
   }

   return (
      <div className={styles.mainWindow}>
         <div>
            <div className={styles.headerSection}>
               <p>{t("alerts_page.1")}</p>
               <p>{t("alerts_page.2")}</p>
            </div>

            {
               alertsList?.length !== 0 && (
                  <div className={styles.container}>
                     {
                        showRowItems && (
                           <div className={styles.firstRow}>
                              <div className={styles.dropdown}>
                                 <Select
                                    style={{ width: "auto" }}
                                    value={`${t("alerts_page.7")}: ${conditionFilter}`}
                                    onChange={(value) => setConditionFilter(value)}
                                 >
                                    <Option value="all" key={"all"} >{t("others.43")}</Option>
                                    <Option value="above" key={"above"}>{t("alerts_page.34")}</Option>
                                    <Option value="below" key={"below"}>{t("alerts_page.35")}</Option>
                                 </Select>
                              </div>
                              <div className={styles.actionBtnBlock}>
                                 <button className={styles.search} onClick={handleShowSearch}><img src={theme === 'dark' ? "/assets/search.svg" : "/assets/search_black.svg"} alt="" /></button>
                                 <img src={theme === 'dark' ? "/assets/sort_icon.svg" : "/assets/sort_icon_black.svg"} alt="" className={styles.sort} onClick={(e) => {
                                    e.stopPropagation()
                                    setShowSortPopup(!showSortPopup)
                                 }} />
                                 {showSortPopup && <SortPopup setSortValue={setSortValue} sortValue={sortValue} setShowSortPopup={setShowSortPopup} />}

                                 {
                                    window.innerWidth > 880 &&
                                    <div className={`${styles.searchBox} ${showSearchBox && styles.showSearch} `}>
                                       <img src={theme === 'dark' ? "/assets/search.svg" : "/assets/search_black.svg"} alt="" />
                                       <input type="text" placeholder={t("alerts_page.3")} value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyPress={handleSearch} />
                                    </div>
                                 }
                              </div>
                           </div>
                        )
                     }
                     {
                        !showRowItems && (
                           <div className={`${styles.searchBoxSmall} ${showSearchBox && styles.showSearch} `}>
                              <i onClick={() => {
                                 setShowRowItems(true);
                                 setSearchValue("");
                                 getAlerts("reset")
                              }}
                                 className="fa-solid fa-arrow-left"></i>
                              <input type="text" placeholder={t("alerts_page.3")} value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyPress={handleSearch} />
                              <button onClick={getAlerts}>{t("button.5")}</button>
                           </div>
                        )
                     }
                     <div className={styles.tableContainer}>
                        <div className={`${styles.row} ${styles.header}`}>
                           <div className={styles.name}>{t("alerts_page.6")}</div>
                           <div className={styles.condition}>{t("alerts_page.7")}</div>
                           <div className={styles.threshold}>{t("alerts_page.8")}</div>
                           <div className={styles.lastUpdated}>{t("alerts_page.9")}</div>
                           <div className={styles.actions}></div>
                        </div>
                        <Spin spinning={alertsLoading} style={{ marginTop: "20px" }}>
                           {
                              alertsList?.map(alert => {
                                 return (
                                    <div className={styles.row} >
                                       <div className={styles.name}>{alert.alert_title}</div>
                                       <div className={styles.condition}>{alert.condition}</div>
                                       <div className={styles.threshold}>{`${alert.threshold}%`}</div>
                                       <div className={styles.conditionThreshold}>{`${alert.condition} ${alert.threshold}%`}</div>
                                       <div className={styles.lastUpdated}>{alert.lastAlertDate ?
                                          `${alert.lastAlertDate?.split(" ", 4)[1]} ${alert.lastAlertDate?.split(" ", 4)[2]} ${alert.lastAlertDate?.split(" ", 4)[3]} ${alert.lastAlertDate?.split(" ")[4].split(":", 2).join(":")}` : "N/A"}
                                       </div>
                                       <div className={styles.actions}>
                                          <img src={theme === 'dark' ? "/assets/more-vertical.svg" : "/assets/more-vertical-black.svg"} onClick={() => {
                                             setShowMoreOption(true)
                                             setSelectedAlertID(alert.alert_id)
                                          }} alt="" />
                                          {
                                             showMoreOption && selectedAlertID === alert.alert_id &&
                                             <div className={styles.moreOptionPopup} ref={moreOptionRef}>
                                                <span onClick={() => handleShowAlertInfo(alert)}>
                                                   <img src={theme === 'dark' ? "/assets/info1.svg" : "/assets/info1_black.svg"} alt="" />
                                                   {t("alerts_page.5")}
                                                </span>
                                                <span onClick={() => handleShowEditAlert(alert)} >
                                                   <img src={theme === 'dark' ? "/assets/rename.svg" : "/assets/rename_black.svg"} alt="" />
                                                   {t("button.31")}
                                                </span>
                                                <span onClick={() => handleShowDeleteAlert()}>
                                                   <img src={theme === 'dark' ? "/assets/trash.svg" : "/assets/trash_black.svg"} alt="" />
                                                   {t("button.2")}
                                                </span>
                                             </div>
                                          }
                                       </div>
                                    </div>
                                 )
                              })
                           }
                           {
                              // <div className={styles.notFound}>No  Found</div>
                           }
                        </Spin>
                     </div>

                  </div>
               )
            }
            {!alertsLoading && alertsList?.length === 0 && showEmptyAlert &&
               <div className={styles.noDataInfo}>
                  <div className={styles.title}>
                     {t("alerts_page.4")}
                     <img src={theme === 'dark' ? "/assets/alert_empty.svg" : "/assets/alert_empty_black.svg"} alt="" />
                  </div>
                  <p className={styles.stepsLabel}>{t("others.52")} <img src="/assets/double_down_arrow.svg" alt="" /></p>
                  <div className={styles.imgBlock}>
                     <img src={theme === 'dark' ? "/assets/alert_empty1.png" : theme === "light" ? "/assets/alert_empty1_light.png" : "/assets/alert_empty1_brand.png"} alt="" />
                     <img src={theme === 'dark' ? "/assets/alert_empty2.png" : theme === "light" ? "/assets/alert_empty2_light.png" : "/assets/alert_empty2_brand.png"} alt="" />
                  </div>
                  <span className={styles.link} onClick={() => history.push("/console/global_executive")}>{t("others.53")}</span>
               </div>}
         </div>
         {
            totalAlerts > 0 && (
               <div className={styles.paginationWrapper}>
                  <div className={styles.innerWrapper}>
                     {
                        <div className={styles.paginationBox}>
                           <Button disabled={startIndex === 0} onClick={() => {
                              parseInt(alertsPageNumber, 10) === startIndex + 1 && setStartIndex(prev => prev - 1)
                              parseInt(alertsPageNumber, 10) === startIndex + 1 && setAlertsPageNumber(prev => parseInt(prev, 10) - 1)
                           }}>
                              <i className="fa fa-angle-left" />
                           </Button>
                           <span className={styles.pagination}>
                              <input className={styles.pageIndex} onKeyPress={handleUserPage} type="text" value={alertsPageNumber} onChange={e => setAlertsPageNumber(e.target.value)} />
                              {totalAlerts ? ` / ${Math.ceil(totalAlerts / selectedPerPage)}` : ""}
                           </span>
                           <Button
                              disabled={
                                 alertsList?.length === 0 ||
                                 (totalAlerts && startIndex + 1 === Math.ceil(totalAlerts / selectedPerPage)) ||
                                 !alertsPageNumber
                              }
                              onClick={() => {
                                 parseInt(alertsPageNumber, 10) === startIndex + 1 && setStartIndex(prev => prev + 1)
                                 parseInt(alertsPageNumber, 10) === startIndex + 1 && setAlertsPageNumber(prev => parseInt(prev, 10) + 1)
                              }}
                           >
                              <i className="fa fa-angle-right" />
                           </Button>
                        </div>
                     }

                     <Select className='review-page-number-selector' value={selectedPerPage + ` / ${t("label.10")}`} onChange={handlePageChange} style={{ backgroundColor: "transparent" }}>
                        <Option value={10}>10</Option>
                        <Option disabled={totalAlerts <= 10 ? true : false} value={25}>25</Option>
                        <Option disabled={totalAlerts <= 25 ? true : false} value={50}>50</Option>
                        <Option disabled={totalAlerts <= 50 ? true : false} value={100}>100</Option>
                     </Select>
                  </div>
                  {
                     totalAlerts > 0 && <div className={styles.dashboardsTotal}>{((selectedPerPage * startIndex) + alertsList?.length) + " of " + totalAlerts}</div>
                  }
               </div>
            )
         }
         {
            showEditAlert &&
            <AlertPopup theme={theme} handleAction={handleEditAlert} editCondition={selectedAlertCondition} editThreshold={selectedAlertThreshold} editUsers={selectedAlertUsers} editFrequency={selectedAlertFrequency} editSendSMS={sendSMSCheckbox} close={setShowEditAlert} type="edit" disable={disableBtn} />
         }
         {
            showAlertInfoPopup && (
               <div className={styles.modal}>
                  <div className={styles.centerWrapper}>
                     <div className={styles.alertContent}>
                        <div className={styles.header}>
                           <span>
                              <img src={theme === 'dark' ? "/assets/info1.svg" : "/assets/info1_black.svg"} alt="" />
                              {t("alerts_page.10")}
                           </span>
                           <img src={theme === 'dark' ? "/assets/x.svg" : "/assets/x_black.svg"} onClick={() => setShowAlertInfoPopup(false)} alt="" />
                        </div>
                        <div className={styles.belowContent}>
                           <div className={styles.block}>
                              <span className={styles.label}>
                                 <img src={theme === 'dark' ? "/assets/solid_line.svg" : "/assets/solid_line_black.svg"} alt="" />
                                 {t("alerts_page.11")}
                              </span>
                              <span className={styles.info}>{alertInfo?.alert_kpi?.join(", ")}</span>
                           </div>
                           <div className={styles.block}>
                              <span className={styles.label}>
                                 <img src={theme === 'dark' ? "/assets/alert_icon.svg" : "/assets/alert_icon_black.svg"} alt="" />
                                 {t("alerts_page.12")}
                              </span>
                              <span className={styles.info}>{`${alertInfo?.condition} ${alertInfo?.threshold}%`}</span>
                           </div>
                           <div className={styles.underlineBlock}>
                              <span className={styles.label}>
                                 <img src={theme === 'dark' ? "/assets/users.svg" : "/assets/users_black.svg"} alt="" />
                                 {t("alerts_page.13")}
                              </span>
                              <div className={styles.wrapper}>
                                 {alertInfo?.send_to?.map(user =>
                                    <span key={user}>
                                       {user}
                                       <img src={theme === 'dark' ? "/assets/copy.svg" : "/assets/copy_black.svg"} alt="" onClick={() => handleCopy(user)} />
                                    </span>)}
                              </div>
                           </div>
                           <div className={styles.block}>
                              <span className={styles.label}>
                                 <img src={theme === 'dark' ? "/assets/clock.svg" : "/assets/clock_black.svg"} alt="" />
                                 {t("alerts_page.27")}
                              </span>
                              <span className={styles.info}>{alertInfo?.frequency.charAt(0).toUpperCase() + alertInfo?.frequency.slice(1)} ({getComparison(alertInfo?.frequency)})</span>
                           </div>
                           <div className={styles.block}>
                              <span className={styles.label}>
                                 <img width={"18px"} src={theme === 'dark' ? "/assets/calendar.svg" : "/assets/calendar_black.svg"} alt="" />
                                 {t("alerts_page.9")}
                              </span>
                              <span className={styles.info}>{alertInfo?.lastAlertDate ?
                                 `${alertInfo?.lastAlertDate?.split(" ", 4)[1]} ${alertInfo?.lastAlertDate?.split(" ", 4)[2]} ${alertInfo?.lastAlertDate?.split(" ", 4)[3]} ${alertInfo?.lastAlertDate?.split(" ")[4].split(":", 2).join(":")}` : "N/A"}
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            )
         }
         {
            showDeleteAlert && (
               <div className={styles.modal}>
                  <div className={styles.centerWrapper}>
                     <div className={styles.deleteContent}>
                        <p>{t("alerts_page.15")}</p>
                        <div className={styles.block}>
                           <button onClick={() => setShowDeleteAlert(false)}>{t("button.3")}</button>
                           <button disabled={disableBtn} onClick={handleDeleteAlert}>{t("button.33")}</button>
                        </div>
                     </div>
                  </div>
               </div>
            )
         }
      </div>
   )
}

export default Alerts;
