import React, { useEffect, useState, useRef } from "react";
import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faLongArrowAltUp,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "../FirebaseConfig.js";
import { UNLOGGED, LOGGED } from "../util/constants.js";
import * as actions from "redux/popup/actions.js";
import { useDispatch } from "react-redux";
import Popup from "./Popup/index.js";
import { SET_USER } from "redux/user/actions.js";
import { apiRequest } from "util/services.js";
import mixpanel from 'mixpanel-browser';
import { Avatar } from 'antd';
import './topMenu.scss';
import { themeColor } from 'util/common.js';
import NotificationPage from 'components/Notifcation/index.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next.js';
import SystemNotification from 'components/SystemNotification/index.js';
import { Button, notification, Tooltip } from "antd";
import { copyToClipboard } from "util/common";



const { SubMenu } = Menu;

const TopMenu = ({ authenticated, user, theme }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState([]);
  const history = useHistory();
  const [showLogin, setShowLogin] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [showKCenterPass, setshowKCenterPass] = useState(false)

  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (authenticated !== 'unlogged') {
      history.listen(() => {
        setSelectedMenuItem(document.location.pathname.split('/')[1]);
      });
      setSelectedMenuItem(document.location.pathname.split('/')[1]);

      const getPlan = async () => {
        const subscription = await apiRequest('subscriptions/all', 'POST', {
          user_id: user.uid,
        });

        // if (subscription) {
        //   dispatch({
        //     type: SET_USER,
        //     payload: subscription[0],
        //   });
        //   i18n.changeLanguage(subscription[0].language);
        // }
      };
      getPlan();
    }
  }, []);

  const copyPassword = () => {
    copyToClipboard(
      'axgQ167'
    );
    notification.success({
      message: t("notifications.54")
    });
  }

  const handleClick = (e) => {
    if (e.key === 'popup') {
      dispatch({
        type: actions.OPEN_POPUP,
      });
    } else if (e.key === 'tutorials') {
      const win = window.open(
        'https://pivony.notion.site/Pivony-Knowledge-Center-59a7a883a48349a6975bf47576edc407',
        '_blank'
      );
      win.focus();
    } else if (e.key === 'courses') {
      const win = window.open('https://learning.pivony.com/', '_blank');
    } else if (e.key === '/') {
      document.location.href = document.location.origin;
    } else if (e.key === 'feedback') {
      const win = window.open('mailto:support@pivony.com?subject=Internal%20Support%20Ticket%20Submission', '_blank');

    }
    else if (e.key !== 'logout') {
      history.push(e.key);
    }
  };

  const signout = () => {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          mixpanel.track('LOGOUT');
          window.localStorage.removeItem('selectedDashboard');
          document.location.href = document.location.origin + '/login';
        },
        function (error) {
          console.error('Sign Out Error', error);
        }
      );
  };

  const KnowledgeCenterPopup = () => {
    return <div className='popup'>
      <div className="content">
        <div className="header">
          <div className="texts">
            <div className="title">
              Please Check Your Password!
            </div>
            <div className="description">
              you will be asked to enter this password to access the knowledge Center
            </div>
          </div>

          <div className="close" onClick={() => {
            setshowKCenterPass(false)
          }}>
            <img
              src={theme === 'dark' ? '/assets/x.svg' : '/assets/x_black.svg'}
              alt='help'
            /> </div>
        </div>
        <div className="inputWrapper">
          <input type="text" placeholder='Enter text' value={'Partners12.'} disabled />
          <Button className="button" onClick={copyPassword} > <img
            src={theme === 'dark' ? '/assets/copy.svg' : '/assets/copy.svg'}
            alt='help'
          /> </Button>
        </div>
      </div>
    </div>
  }



  useEffect(() => {
    if (
      window.location.pathname === '/signup' ||
      window.location.pathname === '/'
    ) {
      setShowLogin('Login');
    }
    if (window.location.pathname === '/login') {
      setShowLogin('Signup');
    }
  }, [location.pathname]);

  return (
    //<div id="header_to_menu" style={{ backgroundColor: '#262250', minHeight: 60 ,display: "flex",justifyContent: "space-between",maxHeight: 100}}>
    <div
      id='header_to_menu'
      style={{
        backgroundColor: themeColor(theme),
        minHeight: 60,
        position: 'relative',
      }}
    >
      <a
        href={
          authenticated === LOGGED && user.plan
            ? user.plan == 68
              ? document.location.origin + '/sentiment_intent_analyzer'
              : document.location.origin + '/console/myDashboards'
            : '//pivony.com'
        }
      >
        <div className='nav-logo'>
          <div className='nav-logo-container'>
            {theme === 'brand' ? (
              <img
                src='/assets/pivony-logo1.png'
                alt='logo'
              />
            ) : (
              <img
                src='/assets/pivony-logo.png'
                alt='logo'
              />
            )}
          </div>
        </div>
      </a>
      <></>
      {/* {(authenticated === LOGGED  && user.plan) &&(    
        <div style={{display:'flex', flexDirection:"column", alignItems: "center",width:"220px", justifyContent: "center"}}>  
      <Avatar size={60} shape ="square" src="/assets/vodafone.png"/>

      </div>)
     } */}
      {/* {(authenticated === LOGGED  && user.plan && user.logo_url) &&(    
        <div style={{display:'flex', flexDirection:"column", alignItems: "center",width:"220px", justifyContent: "center"}}>  
          <img src={user.logo_url} style={{width:"140px",height:"33px"}}/>

      </div>)
     } */}
      {/* {(authenticated === LOGGED  && user.plan) &&(    
        <div style={{display:'flex', flexDirection:"column", alignItems: "center",width:"220px", justifyContent: "center"}}>  
      <Avatar size={60} src="/assets/yw.png"/>

      </div>)
     } */}
      {
        showKCenterPass ? <KnowledgeCenterPopup />
          : ""
      }

      <Popup
        user={user}
        auth={authenticated}
      />
      <Menu
        onClick={handleClick}
        selectedKeys={[selectedMenuItem]}
        mode='horizontal'
        theme='dark'
        className='top-navigation-bar'
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: themeColor(theme),
        }}
      >
        {/* {(authenticated === LOGGED && user.plan !== 68) && (
          <Menu.Item
            style={{ color: "#fff" }}
            key="/console/myDashboards"
            icon={<FontAwesomeIcon />}
          >
            Home
          </Menu.Item>
        )}
        {(authenticated === LOGGED && user.plan === 68) ?
          <Menu.Item
            style={{ color: "#fff" }}
            key="/sentiment_intent_analyzer"
          >
            Home
          </Menu.Item>
          :
          <Menu.Item
            id="popup_selector"
            style={{ color: "#fff" }}
            key="popup"
            icon={<FontAwesomeIcon />}
          >
            Examples
          </Menu.Item>} */}
        {authenticated === LOGGED && (
          <div
            className='topbar-notification-icon'
            key='notification'
            onClick={() => {
              setShowNotification(showNotification ? false : true);
            }}
          >
            <img
              src='/assets/bell.svg'
              alt=''
            />
          </div>
        )}
        <SubMenu
          id='help'
          className='sub-menu-item'
          title={
            <span>
              <img
                src='/assets/help-circle.svg'
                alt='help'
              />
            </span>
          }
        >
          {' '}
          <Menu.Item
            key='tutorials'
            className='underline-item'
          >
            {t('top_menu.2')} {theme === 'dark' ? (<div style={{ display: "flex", columnGap: "5px" }}>
              <img
                src='/assets/arrow-up-right.svg'
                alt=''
              />
            </div>
            ) : (
              <div style={{ display: "flex", columnGap: "5px" }} >
                <img
                  src='/assets/arrow-up-right_black.svg'
                  alt=''
                />
              </div>
            )}
          </Menu.Item>
          <Menu.Item
            key='courses'
            className='underline-item'
          >
            {t('top_menu.3')} {theme === 'dark' ? (<div style={{ display: "flex", columnGap: "5px" }}>
              <img
                src='/assets/arrow-up-right.svg'
                alt=''
              />
            </div>
            ) : (
              <div style={{ display: "flex", columnGap: "5px" }} >
                <img
                  src='/assets/arrow-up-right_black.svg'
                  alt=''
                />
              </div>
            )}
          </Menu.Item>
          <Menu.Item
            key='feedback'
            className='underline-item'
          >
            {t('top_menu.6')} {theme === 'dark' ? (<div style={{ display: "flex", columnGap: "5px" }}>
              <img
                src='/assets/arrow-up-right.svg'
                alt=''
              />
            </div>
            ) : (
              <div style={{ display: "flex", columnGap: "5px" }} >
                <img
                  src='/assets/arrow-up-right_black.svg'
                  alt=''
                />
              </div>
            )}
          </Menu.Item>
        </SubMenu>
        {authenticated === LOGGED && (
          <SubMenu
            id='myaccount'
            style={{ color: '#fff', width: '24px', height: '24px' }}
            className='avatar-box'
            title={
              <span className='avatar-icon'>
                <span className='user-name-letter'>
                  {user?.firstName
                    ? user.firstName.charAt(0).toUpperCase()
                    : user?.email
                      ? user?.email?.charAt(0).toUpperCase()
                      : 'P'}
                </span>
              </span>
            }
          >
            {user.plan && (
              <Menu.Item
                onClick={() => {
                  mixpanel.track('NAVIGATE_SETTINGS');
                }}
                key='/settings'
                icon={
                  theme === 'dark' ? (
                    <img
                      src='/assets/settings.svg'
                      alt=''
                    />
                  ) : (
                    <img
                      src='/assets/settings_black.svg'
                      alt=''
                    />
                  )
                }
              >
                {t('top_menu.4')}
              </Menu.Item>
            )}
            <Menu.Item
              onClick={signout}
              key='logout'
              icon={
                theme === 'dark' ? (
                  <img
                    src='/assets/log-out.svg'
                    alt=''
                  />
                ) : (
                  <img
                    src='/assets/log-out-black.svg'
                    alt=''
                  />
                )
              }
            >
              {t('top_menu.5')}
            </Menu.Item>
          </SubMenu>
        )}
        {authenticated === UNLOGGED && showLogin === 'Signup' && (
          <Menu.Item
            key='/signup'
            icon={<FontAwesomeIcon icon={faLongArrowAltUp} />}
            style={{ float: 'right' }}
          >
            Signup
          </Menu.Item>
        )}
        {authenticated === UNLOGGED && showLogin === 'Login' && (
          <Menu.Item
            key='/login'
            icon={<FontAwesomeIcon icon={faSignInAlt} />}
            style={{ float: 'right' }}
          >
            Login
          </Menu.Item>
        )}
      </Menu>
      {showNotification && (
        <NotificationPage
          user={user}
          theme={theme}
          showNotification={showNotification}
          setShowNotification={setShowNotification}
        />
      )}
      {/* render only if url is not login or user is authenticated */}
      {authenticated === LOGGED && window.location.href.split('/').includes('login') === false && (<div style={{ position: 'relative' }}>
        <SystemNotification />
      </div>)
      }
    </div>
  );
};


export default TopMenu;
