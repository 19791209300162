import React, { useState, useEffect } from "react";
import { Select, Checkbox } from "antd";
import styles from "./style.module.scss";
import { DownOutlined } from "@ant-design/icons";
const { Option } = Select;

const MultiSelectDropdown = ({
  isLoading,
  selectionLimit,
  showSearch,
  defaultSelectedOptions,
  placeholder,
  options,
  reset,
  setSelectedOptions,
  placeholderOfSearchBar,
  tagCount,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  useEffect(() => {
    if (defaultSelectedOptions?.length > 0) {
      const defaultSelection = options
        ?.filter((option) => defaultSelectedOptions?.includes(option?.id))
        ?.map((option) => option.value);
      setSelectedItems(defaultSelection);
    }
  }, [defaultSelectedOptions]);
  const handleChange = (selected) => {
    if (!isLoading) {
      let updatedSelection = [...selected];

      if (selected.includes("select-all")) {
        updatedSelection =
          selected.length === options.length
            ? []
            : options.slice(0, selectionLimit).map((opt) => opt.value);
      } else if (selected.length > selectionLimit) {
        const overflow = selected.length - selectionLimit;
        updatedSelection = selected.slice(overflow, selected.length);
      }

      setSelectedItems(updatedSelection);
      setSelectAllChecked(updatedSelection.length === options.length);
      console.log(updatedSelection);
      setSelectedOptions(updatedSelection);
    }
  };
  useEffect(() => {
    if (reset && defaultSelectedOptions?.length > 0) {
      const defaultSelection = options
        ?.filter((option) => defaultSelectedOptions?.includes(option?.id))
        ?.map((option) => option.value);
      setSelectedItems(defaultSelection);
      setSelectAllChecked(false);
    } else if (reset) {
      setSelectedItems([]);
      setSelectAllChecked(false);
      setSelectedOptions([]);
    }
  }, [reset]);

  const handleSelectAll = () => {
    const newSelected = selectAllChecked
      ? []
      : options.slice(0, selectionLimit).map((opt) => opt.value);
    handleChange(newSelected);
  };

  return (
    <div className={styles.Dropdown}>
      <div className={styles.DropdownWithPlaceholder}>
        {placeholder && (
          <p className={styles.DropdownPlaceholder}>{placeholder}</p>
        )}
        <div className={styles.MultiSelectDropdown}>
          <Select
            mode="multiple"
            suffixIcon={<DownOutlined />}
            showSearch={showSearch ? true : false}
            allowClear
            placeholder={
              <span className={styles.placeholderOfSearchBar}>
                {placeholderOfSearchBar ? (
                  placeholderOfSearchBar
                ) : (
                  <span>
                    Select <DownOutlined className={styles.suffixIcon} />
                  </span>
                )}
              </span>
            }
            value={selectedItems}
            optionFilterProp="title"
            maxTagCount={tagCount ? "responsive" : 1}
            listHeight={192}
            className={styles.selection}
            onChange={handleChange}
          >
            <Option
              key="select-all"
              value="select-all"
              className={styles.option}
            >
              <Checkbox
                checked={selectAllChecked}
                indeterminate={
                  selectedItems.length > 0 &&
                  selectedItems.length < options.length
                }
                onChange={handleSelectAll}
              >
                Select All
              </Checkbox>
            </Option>

            {options.map((option, index) => (
              <Option
                className={
                  selectedItems.includes(option.value)
                    ? styles.checkedOption
                    : styles.option
                }
                key={option.value}
                value={option.value}
                title={option.label}
              >
                <Checkbox
                  checked={selectedItems.includes(option.value)}
                  onChange={(e) => {
                    const event = e.nativeEvent;
                    if (!event.target.closest(`.ant-select-selection-item`)) {
                      const newSelectedItems = selectedItems.includes(
                        option.value
                      )
                        ? selectedItems.filter((item) => item !== option.value)
                        : [...selectedItems, option.value];

                      if (newSelectedItems.length > selectionLimit) {
                        const overflow =
                          newSelectedItems.length - selectionLimit;
                        const trimmedSelection = newSelectedItems.slice(
                          overflow,
                          newSelectedItems.length
                        );
                        handleChange(trimmedSelection);
                      } else {
                        handleChange(newSelectedItems);
                      }
                    }
                  }}
                >
                  {option.label}
                </Checkbox>
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
