import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { apiRequest } from 'util/services';
import { notification, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

const NotificationPage = ({ theme, setShowNotification, showNotification, user }) => {
   const [showBackArrow, setShowBackArrow] = useState(window.innerWidth <= 700 ? true : false);
   const [notificationList, setNotificationList] = useState([]);
   const [tempList, setTempList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [filterUnread, setFilterUnread] = useState(2);
   const [showNoUnreadAvailable, setShowNoUnreadAvailable] = useState(false);
   const [showLast30Days, setShowLast30Days] = useState(false);
   const [showEmptyState, setShowEmptyState] = useState(false);
   const notificationRef = useRef();
   const history = useHistory();
   const { t } = useTranslation();

   const getAllNotification = async () => {
      setLoading(true);
      const payload = {
         user_id: user?.uid,
         filter_unread: filterUnread
      }

      const response = await apiRequest(
         'notice/notification/all',
         'POST',
         payload,
         true
      )
      if (response?.notifications || response?.success) {
         if (response?.success) {
            setShowNoUnreadAvailable(true);
            setTempList([]);
            setNotificationList([]);
         } else {
            if (response?.notifications?.length === 0) {
               setShowEmptyState(true);
               setTempList([]);
               setNotificationList([]);
            } else {
               setShowEmptyState(false);
               setNotificationList(response.notifications)
               if (response.notifications?.length > 5 && !showLast30Days) {
                  let arr = [];
                  for (let i = 0; i < 5; i++) {
                     arr.push(response.notifications[i])
                  }
                  setTempList(arr);
               } else {
                  setTempList(response.notifications)
               }
            }
         }
         setLoading(false);
      } else {
         notification.error({
            message: t("notification_page.1")
         })
         setLoading(false);
      }
   }

   useEffect(() => {
      setLoading(true);
      getAllNotification();
   }, [filterUnread, showLast30Days]);

   useLayoutEffect(() => {
      const handleResize = () => {
         if (window.innerWidth > 700) {
            setShowBackArrow(false);
         } else {
            setShowBackArrow(true);
            document.body.style.overflowY = "hidden";
         }
      }
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   const notificationOutClick = (e) => {
      if (notificationRef.current && !notificationRef.current.contains(e.target)) {
         setShowNotification(false)
      }
   }

   useEffect(() => {
      document.addEventListener('click', notificationOutClick, true);
      return () => document.removeEventListener('click', notificationOutClick, true)
   }, []);

   const handleMarkAll = async () => {
      setLoading(true);
      const payload = {
         user_id: user?.uid
      }

      const response = await apiRequest(
         'notice/notification/read-all',
         'POST',
         payload,
         true
      )
      if (response.success) {
         getAllNotification();
      } else {
         notification.error({
            message: t("notification_page.2")
         })
      }
   }

   const updateNotification = async (id, status) => {
      setLoading(true);
      const payload = {
         notification_id: id,
         unread: status
      }

      const response = await apiRequest(
         'notice/notification/read',
         'POST',
         payload,
         true
      )
      if (response.success) {
         getAllNotification();
      } else {
         notification.error({
            message: t("notification_page.2")
         })
         setLoading(false);
      }
   }

   const handleMarkNotification = (mark, id) => {
      const status = mark === 1 ? true : false;
      updateNotification(id, status);
   }

   const handleFilterUnread = (e) => {
      setShowNoUnreadAvailable(false);
      if (e.target.checked) {
         setFilterUnread(0)
      } else {
         setFilterUnread(2)
      }
   }

   const handleShowAllNotifications = () => {
      setShowLast30Days(true);
   }

   return (
      <div className={styles.notificationContainer} ref={notificationRef}>
         <div className={styles.header}>
            <span className={styles.title}>{showBackArrow && <i className="fa-solid fa-arrow-left" onClick={() => setShowNotification(false)}></i>}{t("notification_page.3")}</span>
            <div className={styles.actions}>
               <div className={`${styles.dynamicBtn} ${styles.hideOnMobile}`}>
                  <input
                     type="checkbox"
                     id="filter-unread-switch"
                     onChange={handleFilterUnread}
                  />
                  <label for="filter-unread-switch">Toggle</label>
                  <span>{t("notification_page.4")}</span>
               </div>

               <img src={theme === 'dark' ? "/assets/refresh.svg" : "/assets/refresh_black.svg"} onClick={getAllNotification} alt="" />
               <img src={theme === 'dark' ? "/assets/settings.svg" : "/assets/settings_black.svg"} alt="" onClick={() => {
                  setShowNotification(false)
                  history.push('/settings/preferences')
               }} />
            </div>
         </div>
         <div className={styles.mobileRow}>
            <div className={styles.dynamicBtn}>
               <input
                  type="checkbox"
                  id="email-switch"
               />
               <label for="email-switch">Toggle</label>
               <span>{t("notification_page.4")}</span>
            </div>
         </div>
         <div className={styles.firstRow}>
            <span>{t("notification_page.5")}</span>
            <span onClick={handleMarkAll}>{t("notification_page.6")}</span>
         </div>
         <Spin spinning={loading} style={{ marginTop: "10px" }}>
            {showNoUnreadAvailable && <p style={{ margin: "20px 0", fontSize: "16px", textAlign: "center" }}>{t("notification_page.7")}</p>}
            <div className={styles.notificationContent}>
               {
                  tempList?.map((notification, index) => {
                     return (
                        <div key={index} className={`${styles.notificationItem} ${notification?.status === 0 ? styles.unreadItem : undefined}`}>
                           <div className={styles.notificationIcon}>
                              {
                                 notification.type_id === 1 ? <img src={theme === 'dark' ? "/assets/alert_icon.svg" : "/assets/alert_icon_black.svg"} alt="" /> :
                                    notification.type_id === 2 ? <img src={theme === 'dark' ? "/assets/error_outline.svg" : "/assets/error_outline_black.svg"} alt="" /> :
                                       <img src={theme === 'dark' ? "/assets/check_circle_outline.svg" : "/assets/check_circle_outline-24px.svg"} alt="" />
                              }
                           </div>
                           <div className={styles.wrapper}>
                              <div className={styles.notification}>
                                 <p>{notification.notification_Text}</p>
                                 <span>{notification.date}</span>
                              </div>
                              <div className={`${styles.numbering} ${notification?.status === 0 ? styles.unreadMark : undefined}`} onClick={() => handleMarkNotification(notification.status, notification.notification_id)}></div>
                           </div>
                        </div>
                     )
                  })
               }
               { }
               {!loading && !showLast30Days && notificationList?.length > 5 && <div className={styles.lastDayLabel} onClick={handleShowAllNotifications}>{t("notification_page.8")}</div>}
               {showEmptyState && !loading && <p style={{ margin: "20px 0", fontSize: "16px", textAlign: "center" }}>{t("notification_page.9")}</p>}
            </div>
         </Spin>
      </div>
   )
}

export default NotificationPage;