import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import {
  Tag,
  Select,
  Spin,
  notification,
  Button,
  Tree,
  Icon,
  Badge,
} from "antd";
import { Collapse } from "antd";
import SortPopup from "helperComponents/SortPopup";
import { lang } from "util/constants";
import { apiRequest } from "util/services";
import RenameDeletePopup from "helperComponents/RenameDeletePopup";
import { copyToClipboard } from "util/common";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ChildTopicPopup from "./ChildTopicPopup";
import RuleBuilder from "./RuleBuilder";
import { Modal } from "components/General/ModalFormComponents";
import TopicLibraryListing from "./TopicListing";
const { Option } = Select;
const { TreeNode } = Tree;
const TopicLibrary = () => {
  const [topicType, setTopicType] = useState(
    window.location.pathname.includes("/industry_topics")
      ? "industry"
      : "custom"
  );
  const [showAddTopic, setShowAddTopic] = useState(false);
  const [showRunTopics, setShowRunTopics] = useState(false);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [sortValue, setSortValue] = useState("newest");
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showRunDashboard, setShowRunDashboard] = useState(false);
  const [renameRuleValue, setRenameRuleValue] = useState("");
  const [showRenameRulePopup, setShowRenameRulePopup] = useState(false);
  const [showRenameTopicPopup, setShowRenameTopicPopup] = useState(false);
  const [renameTopicValue, setRenameTopicValue] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(undefined);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(undefined);
  const [createSelectedLanguage, setCreateSelectedLanguage] = useState(
    "Turkish"
  );
  const [ruleWords, setRuleWords] = useState("");
  const [topicName, setTopicName] = useState("");
  const [topicsList, setTopicsList] = useState();
  const [topicsLoading, setTopicsLoading] = useState(false);
  const [topicID, setTopicID] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [showDeleteTopicPopup, setShowDeleteTopicPopup] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboardID, setSelectedDashboardID] = useState([]);
  const [selectedTopicIds, setSelectedTopicIds] = useState([]);
  const [showRowItems, setShowRowItems] = useState(true);
  const [selectedTopicName, setSelectedTopicName] = useState("");
  const [selectedParentFilter, setSelectedParentFilter] = useState("All");
  const [selectedTagName, setSelectedTagName] = useState("All");
  const [allTags, setAllTags] = useState([]);
  const [addTag, setAddTag] = useState("");
  const [showTagTopicPopup, setShowTagTopicPopup] = useState(false);
  const [showMobileViewFilter, setShowMobileViewFilter] = useState(false);
  const [showExtraFilterRow, setShowExtraFilterRow] = useState(false);
  const [checkedTopicListID, setCheckedTopicListID] = useState([]);
  const [checkedTopicTagList, setCheckedTopicTagList] = useState([]);
  const [showExtraFilterOption, setShowExtraFilterOption] = useState(false);
  const [showAddRemoveLabel, setShowAddRemoveLabel] = useState(false);
  const [allowToTag, setAllowToTag] = useState(false);
  const [selectedTagID, setSelectedTagID] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [topicsPageNumber, setTopicsPageNumber] = useState(1);
  const [selectedPerPage, setSelectedPerPage] = useState(10);
  const [numOfTopics, setNumOfTopics] = useState(10);
  const [totalTopics, setTotalTopics] = useState(0);
  const [viewType, setViewType] = useState("tree");
  const [showAddRelationPopup, setShowAddRelationPopup] = useState(false);
  const [relationPopupType, setRelationPopupType] = useState("");
  const [selectedParents, setSelectedParents] = useState(null);
  const [selectedChildren, setSelectedChildren] = useState(null);
  const [showChildTopicPopup, setShowChildTopicPopup] = useState(false);
  const [showDeleteRelationPopup, setShowDeleteRelationPopup] = useState(false);
  const [parents, setParents] = useState([]);
  const [children, setChildren] = useState([]);
  const [selectedChildID, setSelectedChildID] = useState();
  const [selectedChildInfo, setSelectedChildInfo] = useState({});
  const [selectedParentName, setSelectedParentName] = useState("");
  const [allTopicsList, setAllTopicsList] = useState([]);
  const [showCreateTopicOption, setShowCreateTopicOption] = useState(false);
  const [selectedCreateTopicType, setSelectedCreateTopicType] = useState(
    "new_rule"
  );
  const [createTopicObject, setCreateTopicObject] = useState([
    {},
    { operator: "and" },
  ]);
  const [showCreateTopicMoreOption, setShowCreateTopicMoreOption] = useState(
    false
  );
  const [forIntersection, setForIntersection] = useState(false);
  const [topicQueryList, setTopicQueryList] = useState([]);
  const [classifiers, setClassifiers] = useState([]);
  const [confidenceScore, setConfidenceScore] = useState();
  const [selectedClassifier, setSelectedClassifier] = useState(null);
  const [ruleInputType, setRuleInputType] = useState("manual");
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const ruleInputTypes = {
    manual: {
      id: "manual",
      label: "Manual",
    },
    ruleBuilder: {
      id: "ruleBuilder",
      label: "rule Builder",
    },
  };
  const extraFilterRef = useRef();
  const newTopicRef = useRef();
  const history = useHistory();
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  const [allTopicsListWithHierarchy, setAllTopicsListWithHierarchy] = useState(
    []
  );
  const getIndustries = async () => {
    const response = await apiRequest("library/industries", "POST", true);
    if (response.success) {
      setIndustries(response.success);
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  useEffect(() => {
    setSearchValue("");
    setSortValue("newest");
    if (topicType === "industry") {
      getIndustries();
    }
  }, [topicType]);

  const getAllCustomTopics = async (currentLang, prevLang) => {
    // setCheckedTopicListID([]);
    // setCheckedTopicTagList([])
    setTopicsLoading(true);
    setShowSortPopup(false);
    const payload = {
      language:
        currentLang && currentLang !== "All"
          ? currentLang
          : currentLang === "All"
          ? undefined
          : selectedLanguage,
      filter: sortValue,
      keyword: searchValue,
      // parent_id: selectedParentFilter?.map(item => item === 'All' || item === 'others' ? item : parseInt(item, 10)),
      step: numOfTopics,
      offset: startIndex,
      view: viewType,
    };
    const response = await apiRequest("library/all", "POST", payload, true);
    if (response.success) {
      if (forIntersection) {
        setTopicQueryList(response.success);
        setTopicsLoading(false);
      } else {
        setAllowToTag(response.success?.tag_allowed);
        setTotalTopics(response.success?.num_topics);
        setTopicsList(response.success?.topics);
        setAllTopicsListWithHierarchy((prev) => {
          const currTopics = response.success?.topics;
          const prevTpcs = prev.map((tpc) => tpc.id);
          const currTpcs = currTopics.map((tpc) => tpc.id);
          const newTpcs = currTpcs.filter((tpc) => !prevTpcs.includes(tpc));
          return [
            ...prev,
            ...currTopics.filter((tpc) => newTpcs.includes(tpc.id)),
          ];
        });
        setAllTags(response.success?.tags);
        setAllTopicsList(response.success?.dropdown_topic_list);
        setTopicsLoading(false);
        setClassifiers(response.success?.classifiers);
        if (response.success.length === 0 && topicType === "custom") {
          if (prevLang) newTopicRef.current.style.display = "block";
          else newTopicRef.current.style.display = "none";
        }
      }
    } else {
      notification.error({
        message: response.error,
      });
      setTopicsLoading(false);
    }
  };

  const getDropdownTopicsList = async (currentLang, prevLang) => {
    const payload = {
      language:
        currentLang && currentLang !== "All"
          ? currentLang
          : currentLang === "All"
          ? undefined
          : selectedLanguage,
      filter: sortValue,
      keyword: searchValue,
      // parent_id: selectedParentFilter?.map(item => item === 'All' || item === 'others' ? item : parseInt(item, 10)),
      step: numOfTopics,
      offset: startIndex,
      view: viewType,
    };
    const response = await apiRequest(
      "library/dropdown/all",
      "POST",
      payload,
      true
    );
    if (response.success) {
      setAllTopicsList(response.success?.dropdown_topic_list);
    } else {
      setAllTopicsList([]);
      notification.error({
        message: response.error ? response.error : "Unable to get topics list",
      });
    }
  };
  useEffect(() => {
    if (showRunTopics || selectedCreateTopicType === "existing_rule") {
      getDropdownTopicsList();
    }
  }, [showRunTopics, selectedCreateTopicType]);
  const getAllTopicsQuery = async () => {
    // setCheckedTopicTagList([])
    const payload = {
      for_intersection: forIntersection,
    };
    const response = await apiRequest("library/all", "POST", payload, true);
    if (response.success) {
      setTopicQueryList(response.success);
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  useEffect(() => {
    if (forIntersection) {
      getAllTopicsQuery();
    }
  }, [forIntersection]);

  const getAllIndustryTopics = async (currentLang, prevLang) => {
    setTopicsLoading(true);
    setShowSortPopup(false);
    const payload = {
      language: currentLang ? currentLang : selectedLanguage,
      filter: sortValue,
      keyword: searchValue,
      industry: selectedIndustry,
      num_topics: numOfTopics,
      start_index: startIndex,
    };
    const response = await apiRequest(
      "library/industry_topics/all",
      "POST",
      payload,
      true
    );
    if (response.success) {
      setTotalTopics(response.success?.num_topics);
      setTopicsList(response.success?.topics);
      setTopicsLoading(false);
    } else {
      notification.error({
        message: response.error,
      });
      setTopicsLoading(false);
    }
  };

  useEffect(() => {
    if (!showMobileViewFilter) {
      if (topicType === "custom") {
        getAllCustomTopics();
      } else {
        getAllIndustryTopics();
      }
    }
  }, [
    sortValue,
    topicType,
    selectedIndustry,
    selectedParentFilter,
    startIndex,
    numOfTopics,
    viewType,
  ]);

  const getParents = async (id) => {
    const payload = {
      id: id,
    };
    const response = await apiRequest("library/parents", "POST", payload, true);
    if (response.success) {
      setParents(response.success);
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  // useEffect(() => {
  //    getParents();
  // }, []);

  const handleCreateTopic = async (rule, name) => {
    if (!createSelectedLanguage) {
      notification.error({
        message: t("topic_library.1"),
      });
      return;
    } else if (topicName === "") {
      notification.error({
        message: t("others.12"),
      });
      return;
    }
    setDisableButton(true);
    const payload = {
      language: createSelectedLanguage,
      name: topicName,
      rule:
        rule && selectedCreateTopicType === "existing_rule" ? rule : ruleWords,
    };
    if (selectedClassifier) {
      payload.classifier = selectedClassifier;
      if (confidenceScore) {
        payload.confidence_score = confidenceScore;
      }
    }

    const response = await apiRequest(
      "library/custom_topics/create",
      "POST",
      payload,
      true
    );
    if (response.success) {
      if (rule && selectedCreateTopicType === "existing_rule") {
        setCreateTopicObject([{}, { operator: "and" }]);
      }
      setDisableButton(false);
      setShowCreateTopicOption(false);
      setShowAddTopic(false);
      if (viewType === "tree") {
        setViewType("list");
      } else {
        getAllCustomTopics();
      }
    } else {
      notification.error({
        message: response.error,
      });
      setDisableButton(false);
    }
  };

  const handleRuleRename = async () => {
    setDisableButton(true);
    const payload = {
      topic_id: topicID,
      new_rule: renameRuleValue,
    };
    const response = await apiRequest(
      "library/custom_topics/edit_rule",
      "POST",
      payload,
      true
    );

    if (response.success) {
      notification.success({
        message: t("notifications.27"),
      });
      setDisableButton(false);
      setShowRenameRulePopup(false);
      setShowChildTopicPopup(false);
      setRenameRuleValue("");
      getAllCustomTopics();
    } else {
      setDisableButton(false);
      notification.error({
        message: response.error,
      });
    }
  };

  const handleTopicRename = async () => {
    setDisableButton(true);
    const payload = {
      topic_id: topicID,
      new_name: renameTopicValue,
    };
    const response = await apiRequest(
      "library/custom_topics/rename",
      "POST",
      payload,
      true
    );

    if (response.success) {
      notification.success({
        message: t("notifications.28"),
      });
      setDisableButton(false);
      setShowRenameTopicPopup(false);
      setShowChildTopicPopup(false);
      getAllCustomTopics();
    } else {
      notification.error({
        message: response.error,
      });
      setDisableButton(false);
    }
  };

  const handleTopicDelete = async () => {
    setDisableButton(true);
    const payload = {
      topic_ids: [topicID],
    };
    setDeletionInProgress(true);
    const response = await apiRequest(
      "library/custom_topics/delete",
      "POST",
      payload,
      true
    );

    if (response.success) {
      notification.success({
        message: t("notifications.29"),
      });
      setDisableButton(false);
      setShowDeleteTopicPopup(false);
      setDeletionInProgress(false);
      getAllCustomTopics();
    } else {
      setDisableButton(false);
      setDeletionInProgress(false);
      notification.error({
        message: response.error,
      });
      setShowDeleteTopicPopup(false);
    }
  };

  const getDashboards = async () => {
    const response = await apiRequest("library/dashboards", "POST", true);
    if (response.success) {
      setDashboards(response.success);
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  const topicRunOnDashboard = async () => {
    setDisableButton(true);
    const payload = {
      topic_ids: selectedTopicIds,
      dashboard_ids: selectedDashboardID,
    };
    const response = await apiRequest(
      "library/custom_topics/runOn",
      "POST",
      payload,
      true
    );

    if (response.success && !response.messages) {
      notification.success({
        message: t("notifications.30"),
      });
      setDisableButton(false);
      setShowRunDashboard(false);
      getAllCustomTopics();
    } else if (response.success && response.messages) {
      response.messages.map((msg) => {
        notification.warning({
          message: msg,
        });
      });
      setDisableButton(false);
      setShowRunDashboard(false);
      getAllCustomTopics();
    } else {
      notification.error({
        message: response?.error,
      });
      setDisableButton(false);
    }
    if (allTopicsListWithHierarchy?.length > 0) {
      allTopicsListWithHierarchy.forEach((tpc) => {
        tpc["checked"] = false;
        if (tpc.child && tpc.child.length > 0) {
          const childIds = getNestedLibsOfParentLib(
            allTopicsListWithHierarchy,
            tpc.id
          );
          childIds.forEach((childId) => {
            if (searchTopics(allTopicsListWithHierarchy, childId)?.id) {
              searchTopics(allTopicsListWithHierarchy, childId)[
                "checked"
              ] = false;
            }
          });
        }
      });
    }
    setCheckedTopicListID([]);
  };

  const handleTopicsRunOnDashboard = async () => {
    setDisableButton(true);
    const payload = {
      topic_ids: selectedTopicIds,
      dashboard_ids: selectedDashboardID,
    };
    const response = await apiRequest(
      "library/custom_topics/runOn",
      "POST",
      payload,
      true
    );

    if (response.success && !response.messages) {
      notification.success({
        message: t("notifications.30"),
      });
      setDisableButton(false);
      setShowRunTopics(false);
      getAllCustomTopics();
    } else if (response.success && response.messages) {
      response.messages.map((msg) => {
        notification.warning({
          message: msg,
        });
      });
      setDisableButton(false);
      setShowRunTopics(false);
      getAllCustomTopics();
    } else {
      notification.error({
        message: response.error,
      });
      setDisableButton(false);
    }
    if (allTopicsListWithHierarchy?.length > 0) {
      allTopicsListWithHierarchy.forEach((tpc) => {
        tpc["checked"] = false;
        if (tpc.child && tpc.child.length > 0) {
          const childIds = getNestedLibsOfParentLib(
            allTopicsListWithHierarchy,
            tpc.id
          );
          childIds.forEach((childId) => {
            if (searchTopics(allTopicsListWithHierarchy, childId)?.id) {
              searchTopics(allTopicsListWithHierarchy, childId)[
                "checked"
              ] = false;
            }
          });
        }
      });
    }
    setCheckedTopicListID([]);
  };
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchValue.trim()) {
        getAllCustomTopics();
      } else {
        getAllCustomTopics();
      }
    }
  };

  const handleRunTopics = () => {
    setShowRunTopics(true);
    let arr = [];
    for (let i = 0; i < allTopicsList?.length; i++) {
      arr.push({});
    }
    getDashboards();
  };

  const handleTopicRunOn = (value) => {
    setSelectedDashboardID(value);
  };

  const handleClassifierSelection = (value) => {
    setSelectedClassifier(value);
  };

  const handleTopicsID = (value) => {
    setSelectedTopicIds(value);
  };

  const handleSelectedLanguage = (value) => {
    setCheckedTopicListID([]);
    // setCheckedTopicTagList([]);
    topicType === "custom"
      ? getAllCustomTopics(value, selectedLanguage)
      : getAllIndustryTopics(value);
    setSelectedLanguage(value);
  };

  const handleSelectedIndustry = (value) => {
    setSelectedIndustry(value);
  };

  const handleCreateSelectedLanguage = (value) => {
    setCreateSelectedLanguage(value);
  };

  const handleRunDashboard = (id, name) => {
    getDashboards();
    setSelectedTopicIds([id]);
    setShowMoreOption(false);
    setShowRunDashboard(true);
    setSelectedTopicName(name);
  };

  const handleRenameTopicModal = (value, id) => {
    setShowRenameTopicPopup(true);
    setShowMoreOption(false);
    setRenameTopicValue(value);
    setTopicID(id);
  };

  const handleRenameRuleModal = (rule, id) => {
    setShowRenameRulePopup(true);
    setShowMoreOption(false);
    setRenameRuleValue(rule);
    setTopicID(id);
  };

  const handleShowDeleteTopic = () => {
    setShowDeleteTopicPopup(true);
    setShowMoreOption(false);
  };

  const handleCopyRule = (rule) => {
    copyToClipboard(rule);
    notification.success({
      message: t("notifications.31"),
    });
  };

  const handleShowSearch = () => {
    if (window.innerWidth < 880) {
      setShowRowItems(false);
    }
    setShowSearchBox(!showSearchBox);
  };
  const handleSelectedParentFilter = (value, label) => {
    setCheckedTopicListID([]);
    // setCheckedTopicTagList([])
    setSelectedParentFilter(value);
    // setSelectedTagName(value);
  };
  const handleShowTagTopic = (item) => {
    setShowAddRemoveLabel(false);
    setAddTag(item.tag_name ? item.tag_name : "");
    setTopicID([item.topic_id]);
    setShowTagTopicPopup(true);
  };
  useEffect(() => {
    const handleOutOfChildPopupClick = (e) => {
      const keepOpen = e.target.closest(
        `.${styles.childTopicPopup}, .ant-collapse-header-text, .ant-tree-title, .${styles.topicHoverState}`
      );
      if (!keepOpen) {
        setShowChildTopicPopup(false);
        setSelectedChildInfo({});
      }
    };
    document.addEventListener("click", handleOutOfChildPopupClick);
    return () => {
      document.removeEventListener("click", handleOutOfChildPopupClick);
    };
  });
  const handleMobileViewFilter = () => {
    setCheckedTopicListID([]);
    // setCheckedTopicTagList([]);
    getAllCustomTopics(selectedLanguage);
    setShowMobileViewFilter(false);
  };

  const handleMobileFilterReset = () => {
    setShowMobileViewFilter(false);
    getAllCustomTopics("All");
    setSelectedParentFilter(null);
  };

  const searchTopics = (topicsArr, id) => {
    for (let tpc of topicsArr) {
      if (tpc.id === parseInt(id, 10)) {
        return tpc;
      }
      if (tpc.child && tpc.child.length > 0) {
        const res = searchTopics(tpc.child, id);
        if (res) {
          return res;
        }
      }
    }
  };
  const getNestedLibsOfParentLib = (topicsArr, id) => {
    const childLibs = [];
    const parentLib = topicsArr.find((topic) => topic.id === parseInt(id, 10));
    if (parentLib && parentLib.child && parentLib.child.length > 0) {
      for (let childTpc of parentLib.child) {
        childLibs.push(childTpc.id);
        const childIdsOfChild = getNestedLibsOfParentLib(
          parentLib.child,
          childTpc.id
        );
        childLibs.push(...childIdsOfChild);
      }
    }
    return childLibs;
  };
  const handleCheckboxChange = (e) => {
    const { value, checked, id, name } = e.target;
    const checkedTpc = searchTopics(allTopicsListWithHierarchy, id);
    if (checkedTpc?.id) {
      checkedTpc["checked"] = checked;
    }
    if (checked) {
      if (checkedTopicListID.indexOf(parseInt(id, 10)) > -1) {
        console.log("already selected before");
      } else {
        setCheckedTopicListID([...checkedTopicListID, parseInt(id, 10)]);
      }
    } else {
      setCheckedTopicListID(
        checkedTopicListID.filter((e) => e !== parseInt(id, 10))
      );
    }
  };

  const handleCheckAllChildItems = (e, child, query, checked, i) => {
    for (let j = 0; j < child?.length; j++) {
      if (child[j].child?.length) {
        handleCheckAllChildItems(0, child[j].child, "", checked, i);
      }
      if (child[j].id === allTopicsList[i].id) {
        allTopicsList[i]["checked"] = checked;
      }
    }
  };
  const handleSelectedChildItems = (child, checked, i) => {
    let arr = [];
    for (let j = 0; j < child?.length; j++) {
      if (child[j].child?.length) {
        arr.push(...handleSelectedChildItems(child[j].child, checked, i));
      }
      if (child[j].id === allTopicsList[i].id) {
        arr.push(parseInt(child[j].id, 10));
      }
    }
    return arr;
  };

  const handleUnSelectedChildItems = (child, checked, i, ids) => {
    for (let j = 0; j < child?.length; j++) {
      if (child[j].id === allTopicsList[i].id) {
        ids = ids?.filter((item) => item !== child[j].id);
      }
      if (child[j].child?.length) {
        ids = handleUnSelectedChildItems(child[j].child, checked, i, ids);
      }
    }
    return ids;
  };
  const handleParentCheckboxChange = (e, child, query) => {
    const { value, checked, id, name } = e.target;
    if (viewType === "tree") {
      let childIds = [];
      const checkedTpc = searchTopics(allTopicsListWithHierarchy, id);
      if (checkedTpc?.id) {
        let t = null;
        if (checkedTpc?.parent_id) {
          t = searchTopics(allTopicsListWithHierarchy, checkedTpc.parent_id);
        }
        if (child?.length) {
          childIds = t
            ? getNestedLibsOfParentLib(t.child, id)
            : getNestedLibsOfParentLib(allTopicsListWithHierarchy, id);
        }
        checkedTpc["checked"] = checked;
      }
      if (childIds?.length > 0) {
        childIds.forEach((childId) => {
          if (searchTopics(allTopicsListWithHierarchy, childId)?.id) {
            searchTopics(allTopicsListWithHierarchy, childId)[
              "checked"
            ] = checked;
          }
        });
      }
      if (checked) {
        let arr = [];
        arr.push(parseInt(id, 10));
        if (childIds?.length > 0) {
          arr.push(...childIds);
        }
        let newArr = [...new Set(checkedTopicListID.concat(arr))];
        setCheckedTopicListID(newArr);
      } else {
        const itemsToUncheck = [parseInt(id, 10), ...childIds];
        let ids = checkedTopicListID;
        ids = ids?.filter((item) => !itemsToUncheck.includes(item));
        setCheckedTopicListID(ids);
      }
    } else {
      for (let i = 0; i < allTopicsList.length; i++) {
        if (parseInt(id, 10) === allTopicsList[i].id) {
          allTopicsList[i]["checked"] = checked;
        }
      }
      if (checked) {
        let arr = [parseInt(id, 10)];
        setCheckedTopicListID(checkedTopicListID.concat(arr));
      } else {
        let ids = checkedTopicListID;
        ids = ids?.filter((item) => item !== parseInt(id, 10));
        setCheckedTopicListID(ids);
      }
    }
  };

  useEffect(() => {
    if (checkedTopicListID.length > 0) {
      setShowExtraFilterRow(true);
      setSelectedTopicIds(checkedTopicListID);
      const allEqual = checkedTopicTagList.every(
        (v) => v === checkedTopicTagList[0]
      );
      if (allEqual) setShowAddRemoveLabel(true);
      else setShowAddRemoveLabel(false);
    } else if (checkedTopicListID.length === 0) {
      setShowExtraFilterRow(false);
      setSelectedTopicIds([]);
    }
  }, [checkedTopicListID, checkedTopicTagList, allTopicsList]);

  const filterOutClick = (e) => {
    if (extraFilterRef.current && !extraFilterRef.current.contains(e.target)) {
      setShowExtraFilterOption(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", filterOutClick, true);
    return () => document.removeEventListener("click", filterOutClick, true);
  }, []);

  useEffect(() => {
    if (
      !showRunDashboard &&
      !showRunTopics &&
      checkedTopicListID?.length === 0
    ) {
      setSelectedTopicIds([]);
      setSelectedDashboardID([]);
    }
  }, [showRunDashboard, showRunTopics]);

  const checkedChildTopic = (arr, newArr) => {
    for (let i = 0; i < allTopicsList?.length; i++) {
      for (let j = 0; j < arr?.length; j++) {
        if (allTopicsList[i].id === arr[j].id) {
          allTopicsList[i]["checked"] = true;
          newArr.push(allTopicsList[i].id);
          if (arr[j]?.child && arr[j]?.child?.length > 0) {
            checkedChildTopic(arr[j]?.child, newArr);
          }
        }
      }
    }
  };

  const checkedAllTopic = (e) => {
    e.stopPropagation();
    setShowExtraFilterOption(false);
    if (topicsList?.length > 0) {
      let filterId = [];
      // let filterValue = [];
      topicsList.forEach((tpc) => {
        tpc["checked"] = true;
        filterId.push(tpc.id);
        if (tpc.child && tpc.child.length > 0) {
          const childIds = getNestedLibsOfParentLib(
            allTopicsListWithHierarchy,
            tpc.id
          );
          childIds.forEach((childId) => {
            if (searchTopics(allTopicsListWithHierarchy, childId)?.id) {
              searchTopics(allTopicsListWithHierarchy, childId)[
                "checked"
              ] = true;
            }
            filterId.push(childId);
          });
        }
      });
      allTopicsListWithHierarchy.forEach((topic) => {
        const findTopic = topicsList.find((tpc) => tpc.id === topic.id);
        if (findTopic) {
          topic["checked"] = true;
        }
      });
      setCheckedTopicListID((prev) => {
        return [...new Set([...prev, ...filterId])];
      });
      // setCheckedTopicTagList(filterValue)
    }
  };

  const uncheckedAllTopic = (e) => {
    if (e) e.stopPropagation();
    setShowExtraFilterOption(false);
    if (allTopicsListWithHierarchy?.length > 0) {
      allTopicsListWithHierarchy.forEach((tpc) => {
        tpc["checked"] = false;
        if (tpc.child && tpc.child.length > 0) {
          const childIds = getNestedLibsOfParentLib(
            allTopicsListWithHierarchy,
            tpc.id
          );
          childIds.forEach((childId) => {
            if (searchTopics(allTopicsListWithHierarchy, childId)?.id) {
              searchTopics(allTopicsListWithHierarchy, childId)[
                "checked"
              ] = false;
            }
          });
        }
      });
    }
    setTimeout(() => {
      setCheckedTopicListID([]);
      // setCheckedTopicTagList([]);
    }, 100);
  };
  useEffect(() => {
    if (checkedTopicListID?.length > 0) {
      setCheckedTopicListID([]);
    }
    setAllTopicsListWithHierarchy([]);
  }, [viewType]);
  useEffect(() => {
    if (topicsList?.length === 0 && viewType === "tree") {
      setViewType("list");
    }
  }, [topicsList]);
  const handleMainCheckbox = (e) => {
    if (e.target.checked) {
      checkedAllTopic(e);
    } else {
      uncheckedAllTopic(e);
    }
  };

  // const getTags = async () => {
  //    const response = await apiRequest(
  //       'library/tags/get',
  //       'POST',
  //       true
  //    );
  //    if (response.success) {
  //       if (response.success.length > 0) {
  //          setAllTags(response.success);
  //          setShowTagTopicPopup(true);
  //       } else {
  //          notification.error({
  //             message: "No Tags Found!"
  //          })
  //       }
  //    } else {
  //       notification.error({
  //          message: response.error
  //       })
  //    }
  // }

  // const handleAddTag = async () => {
  //    const payload = {
  //       topic_ids: showAddRemoveLabel ? checkedTopicListID : topicID,
  //       tag_id: selectedTagID,
  //    }
  //    const response = await apiRequest(
  //       'library/custom_topics/tag_action',
  //       'POST',
  //       payload,
  //       true
  //    );

  //    if (response.success) {
  //       selectedTagID ? notification.success({
  //          message: t("topic_library.22")
  //       }) :
  //          notification.success({
  //             message: t("topic_library.23")
  //          })
  //       setShowTagTopicPopup(false);
  //       setSelectedTagID(undefined);
  //       getAllCustomTopics();
  //       setAddTag('');
  //       if (showAddRemoveLabel) {
  //          setShowAddRemoveLabel(false);
  //          uncheckedAllTopic();
  //          setCheckedTopicTagList([]);
  //       }
  //    } else {
  //       notification.error({
  //          message: response.error
  //       })
  //    }
  // }

  const handleAddRemoveMultiTopics = () => {
    setAddTag(checkedTopicTagList[0]);
    setSelectedTagID(undefined);
    setTopicID([checkedTopicListID[0]]);
    setShowTagTopicPopup(true);
  };

  const handleUserPage = (e) => {
    if (e.which === 13) {
      if (!topicsPageNumber) {
        notification.info({
          message: t("notifications.2"),
        });
      } else if (
        topicsPageNumber > 0 &&
        topicsPageNumber <= Math.ceil(totalTopics / selectedPerPage) &&
        /^\d+$/.test(topicsPageNumber)
      ) {
        setStartIndex(topicsPageNumber - 1);
        e.target.blur();
      } else {
        notification.info({
          message: t("notifications.2"),
        });
      }
    }
  };

  const handlePageChange = (value) => {
    setSelectedPerPage(value);
    setNumOfTopics(value);
    setStartIndex(0);
    setTopicsPageNumber(1);
  };
  const handlePageReset = () => {
    setStartIndex(0);
    setTopicsPageNumber(1);
  };

  const getChildren = async (id) => {
    const payload = {
      id: id,
    };

    const response = await apiRequest(
      "library/children",
      "POST",
      payload,
      true
    );

    if (response.success) {
      setChildren(response.success);
    } else {
      notification.error({
        message: "Failed to get childrens!",
      });
    }
  };

  const handleTypeChange = (e) => {
    setViewType(e.target.value);
    setCheckedTopicListID([]);
    handlePageReset();
  };

  const handleShowRelationPopup = (type, id) => {
    setRelationPopupType(type);
    type === "parent" ? getParents(id) : getChildren(id);
    type === "parent"
      ? setSelectedChildren(parseInt(id, 10))
      : setSelectedParents(parseInt(id, 10));
    setShowAddRelationPopup(true);
  };

  const handleAddRelationChange = (value) => {
    if (relationPopupType === "parent") setSelectedParents(value);
    else setSelectedChildren(value);
  };

  const handleAddRelation = async () => {
    const payload = {
      parent_id: selectedParents,
      child_id: selectedChildren,
    };

    const response =
      relationPopupType === "parent"
        ? await apiRequest("library/add_parent", "POST", payload, true)
        : await apiRequest("library/add_child", "POST", payload, true);

    if (response.success) {
      setShowAddRelationPopup(false);
      if (viewType === "tree") {
        getAllCustomTopics();
      } else {
        setViewType("tree");
      }
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  const removeChild = async () => {
    const payload = {
      id: selectedChildID,
    };

    const response = await apiRequest(
      "library/remove_child",
      "POST",
      payload,
      true
    );

    if (response.success) {
      notification.success({
        message: "Successfully removed child!",
      });
      getAllCustomTopics();
      setShowDeleteRelationPopup(false);
    } else {
      notification.error({
        message: "Failed to remove child!",
      });
    }
  };

  const handleCreateTypeChange = (e) => {
    setSelectedCreateTopicType(e.target.value);
  };

  const handleAddOperatorBox = () => {
    setCreateTopicObject([...createTopicObject, { operator: "and" }]);
  };

  const handlePushValue = (val, ind) => {
    if (createTopicObject[ind]) {
      let arr = [...createTopicObject];
      for (let i = 0; i < arr.length; i++) {
        if (i === ind) {
          arr[i].id = val;
        }
      }
      setCreateTopicObject(arr);
    }
  };

  const handleOperatorChange = (val, index) => {
    if (createTopicObject[index]) {
      let arr = [...createTopicObject];
      for (let i = 0; i < arr.length; i++) {
        if (i === index) {
          arr[i].operator = val;
        }
      }
      setCreateTopicObject(arr);
    }
  };
  const removeAddTopic = (i) => {
    setCreateTopicObject(
      createTopicObject?.filter((item, index) => index !== i)
    );
  };

  const handleConvert = () => {
    let rule = "";
    let arr = [...topicQueryList];
    for (let i = 0; i < createTopicObject?.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (createTopicObject[i].id === arr[j].id) {
          rule +=
            i === 0
              ? arr[j]?.query
                ? `(${arr[j]?.query}) `
                : ""
              : arr[j]?.query
              ? `${createTopicObject[i]?.operator?.toUpperCase()} (${
                  arr[j]?.query
                }) `
              : "";
        }
      }
    }
    if (rule && rule?.trim()?.slice(0, 3) === "AND") {
      rule = rule
        ?.trim()
        ?.slice(3)
        ?.trim();
    }
    if (rule) {
      handleCreateTopic(rule?.trim());
    } else {
      notification.error({
        message: t("others.12"),
      });
      return;
    }
  };

  const toggleRuleInputType = (id) => {
    setRuleInputType(id);
  };

  const handleShowAddTopic = () => {
    setShowAddTopic(true);
    if (selectedCreateTopicType === "existing_rule") {
      setForIntersection(true);
    }
  };

  return (
    <div className={styles.mainWindow} onClick={() => setShowMoreOption(false)}>
      <div className={styles.topicsLibraryContainer}>
        <div className={styles.header}>
          <p>{t("dashboard_sidebar.3")}</p>
          {topicType === "custom" && (
            <button className={styles.btn} onClick={handleRunTopics}>
              <img src={"/assets/send.svg"} alt="" />
              {`${t("topic_library.3")}${
                checkedTopicListID.length > 0
                  ? `(${checkedTopicListID.length})`
                  : ""
              }`}
            </button>
          )}
        </div>
        <div className={styles.tabsContainer}>
          <div className={styles.topicsWrapper}>
            <span
              onClick={() => {
                setTopicType("custom");
                history.push("/console/topicLibrary/custom_topics");
              }}
              className={topicType === "custom" && styles.activeTopic}
            >
              {t("selected_dashboard_sidebar.4")}
            </span>
            {/* <span onClick={() => {
                     setTopicType('industry')
                     history.push('/console/topicLibrary/industry_topics')
                  }} className={topicType === 'industry' && styles.activeTopic}>{t("selected_dashboard_sidebar.3")}</span> */}
            <div className={styles.radioInput}>
              <div className={styles.item}>
                <input
                  type="radio"
                  id="category"
                  value="tree"
                  checked={viewType === "tree"}
                  name="view-type"
                  onChange={handleTypeChange}
                />
                <label htmlFor="category">Hierarchical View</label>
              </div>
              <div className={styles.item}>
                <input
                  type="radio"
                  id="plain"
                  value="list"
                  name="view-type"
                  checked={viewType === "list"}
                  onChange={handleTypeChange}
                />
                <label htmlFor="plain">Plain View</label>
              </div>
            </div>
          </div>

          {
            <div className={styles.topicContents}>
              {showRowItems && (
                <div className={styles.firstRow}>
                  {topicType === "custom" && (
                    <span
                      className={styles.mobileViewFilter}
                      onClick={() => setShowMobileViewFilter(true)}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/filter.svg"
                            : "/assets/filter_black.svg"
                        }
                        alt=""
                      />
                    </span>
                  )}
                  <div className={styles.dropdowns}>
                    <Select
                      placeholder={t("topic_library.19")}
                      style={{ width: 150 }}
                      className={styles.languageDropdown}
                      onChange={handleSelectedLanguage}
                      value={selectedLanguage}
                      showSearch
                      optionFilterProp="title"
                    >
                      {" "}
                      <option value="All" key="All">
                        All
                      </option>
                      {lang.map((x) => (
                        <option value={x} key={x} title={x}>
                          {x}
                        </option>
                      ))}
                    </Select>
                    {/* {
                                    viewType === 'tree' &&
                                    <Select
                                       style={{ width: "auto", minWidth: 100 }}
                                       className={styles.languageDropdown}
                                       onChange={handleSelectedParentFilter}
                                       value={selectedParentFilter}
                                    >
                                       <Option key="All">All</Option>
                                       <Option key="others">Non-hierarchical</Option>
                                    </Select>
                                 } */}

                    {topicType === "industry" && (
                      <Select
                        placeholder="Industry"
                        style={{ width: 150 }}
                        className={styles.languageDropdown}
                        onChange={handleSelectedIndustry}
                        value={selectedIndustry}
                        showSearch
                        optionFilterProp="title"
                      >
                        {industries?.map((x) => (
                          <Option value={x} key={x} title={x}>
                            {x}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>

                  <div className={styles.actionBtnBlock}>
                    <button
                      className={styles.search}
                      onClick={handleShowSearch}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/search.svg"
                            : "/assets/search_black.svg"
                        }
                        alt=""
                      />
                    </button>
                    {topicType === "custom" && (
                      <button
                        className={styles.newTopic}
                        onClick={() => setShowCreateTopicOption(true)}
                      >
                        {" "}
                        <i className="fa-solid fa-plus"></i>{" "}
                        <span>{t("topic_library.2")}</span>
                      </button>
                    )}
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/sort_icon.svg"
                          : "/assets/sort_icon_black.svg"
                      }
                      alt=""
                      className={styles.sort}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSortPopup(!showSortPopup);
                      }}
                    />
                    {showSortPopup && (
                      <SortPopup
                        setSortValue={setSortValue}
                        sortValue={sortValue}
                        setShowSortPopup={setShowSortPopup}
                      />
                    )}

                    {window.innerWidth > 880 && (
                      <div
                        className={`${styles.searchBox} ${showSearchBox &&
                          styles.showSearch} `}
                      >
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/search.svg"
                              : "/assets/search_black.svg"
                          }
                          alt=""
                        />
                        <input
                          type="text"
                          placeholder={t("placeholder.2")}
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          onKeyPress={handleSearch}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!showRowItems && (
                <div
                  className={`${styles.searchBoxSmall} ${showSearchBox &&
                    styles.showSearch} `}
                >
                  <i
                    onClick={() => {
                      setShowRowItems(true);
                      setSearchValue("");
                    }}
                    className="fa-solid fa-arrow-left"
                  ></i>
                  <input
                    type="text"
                    placeholder={t("placeholder.2")}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyPress={handleSearch}
                  />
                  <button onClick={getAllCustomTopics}>{t("button.5")}</button>
                </div>
              )}

              <div className={styles.collapseItemsContainer}>
                {showExtraFilterRow && (
                  <div className={styles.extraFilterRow}>
                    <div
                      className={styles.firstItem}
                      onClick={() => setShowExtraFilterOption(true)}
                    >
                      <div className={styles.wrapper}>
                        <input
                          type="checkbox"
                          checked={
                            checkedTopicListID?.length > 0 ? true : false
                          }
                          onChange={handleMainCheckbox}
                          className={`${styles.topicCheckBox} ${
                            checkedTopicListID?.length !== topicsList.length
                              ? styles.fewSelectedState
                              : undefined
                          }`}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <i className="fa-sharp fa-solid fa-caret-down"></i>
                      </div>
                      {t("label.11")} ({checkedTopicListID.length})
                      {showExtraFilterOption && (
                        <div
                          className={styles.extraFilterOption}
                          ref={extraFilterRef}
                        >
                          <span onClick={uncheckedAllTopic}>
                            {t("topic_library.25")}
                          </span>
                          <span onClick={checkedAllTopic}>
                            {t("topic_library.26")}
                          </span>
                        </div>
                      )}
                    </div>
                    {showAddRemoveLabel && allowToTag && (
                      <div className={styles.secondItem}>
                        <span onClick={handleAddRemoveMultiTopics}>
                          <img
                            src={
                              theme === "dark"
                                ? "/assets/tag.svg"
                                : "/assets/tag_black.svg"
                            }
                            alt=""
                          />
                          {t("topic_library.27")}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <Spin spinning={topicsLoading}>
                  {viewType === "list" ? (
                    <Collapse
                      className={styles.collapse}
                      activeKey={[]}
                      bordered={false}
                      collapsible={viewType === "list" ? "header" : "icon"}
                    >
                      {topicsList?.length > 0
                        ? topicsList?.map((topic, index) => (
                            <Collapse.Panel
                              key={index + 1}
                              showArrow={false}
                              header={
                                <div
                                  className={styles.topicHeader}
                                  key={topic.id}
                                >
                                  {topicType !== "industry" && (
                                    <input
                                      id={topic.id}
                                      checked={
                                        searchTopics(
                                          allTopicsListWithHierarchy,
                                          topic.id
                                        )?.checked
                                      }
                                      className={styles.topicCheckBox}
                                      type="checkbox"
                                      name={topic.name}
                                      onChange={(e) => handleCheckboxChange(e)}
                                      value={topic.tag_name}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  )}
                                  {viewType === "tree" && (
                                    <i className="fa-solid fa-chevron-right"></i>
                                  )}
                                  <span
                                    style={{
                                      marginLeft:
                                        viewType === "list" ? "8px" : "0px",
                                    }}
                                    className={styles.topicHoverState}
                                    onClick={() => {
                                      if (topic?.id !== selectedChildInfo?.id) {
                                        setShowChildTopicPopup(true);
                                        setSelectedChildInfo(topic);
                                      } else {
                                        setShowChildTopicPopup(false);
                                        setSelectedChildInfo({});
                                      }
                                      setSelectedParentName("");
                                    }}
                                  >
                                    {topic.name}
                                  </span>
                                </div>
                              }
                              extra={
                                topicType === "custom" && (
                                  <>
                                    {/* <div className={styles.tagLabel}>
                                                      {topic.parent && topic.parent?.length !== 0 &&
                                                         <>
                                                            <span>{topic.parent[0].name}</span>
                                                            {topic.parent?.length > 1 && <span style={{ marginLeft: "5px" }}>+{topic.parent?.length - 1}</span>}
                                                         </>
                                                      }
                                                   </div> */}
                                    {checkedTopicListID.length === 0 && (
                                      <div
                                        className={styles.moreOption}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <img
                                          className={styles.moreOptionIcon}
                                          src={
                                            theme === "dark"
                                              ? "/assets/more-vertical.svg"
                                              : "/assets/more-vertical-black.svg"
                                          }
                                          alt=""
                                          onClick={() => {
                                            setShowMoreOption(true);
                                            setTopicID(topic.id);
                                          }}
                                        />
                                        {showMoreOption &&
                                          topicID === topic.id && (
                                            <div
                                              className={styles.moreOptionPopup}
                                            >
                                              <p
                                                onClick={() =>
                                                  handleRunDashboard(
                                                    topic.id,
                                                    topic.name
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/send.svg"
                                                      : "/assets/send_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                {t("topic_library.4")}
                                              </p>
                                              {allowToTag && (
                                                <p
                                                  onClick={() =>
                                                    handleShowTagTopic(topic)
                                                  }
                                                >
                                                  <img
                                                    src={
                                                      theme === "dark"
                                                        ? "/assets/tag.svg"
                                                        : "/assets/tag_black.svg"
                                                    }
                                                    alt=""
                                                  />
                                                  {t("topic_library.27")}
                                                </p>
                                              )}
                                              <p
                                                onClick={() =>
                                                  handleRenameTopicModal(
                                                    topic.name,
                                                    topic.id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/edit.svg"
                                                      : "/assets/edit_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                {t("topic_library.5")}
                                              </p>
                                              <p
                                                onClick={handleShowDeleteTopic}
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/trash.svg"
                                                      : "/assets/trash_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                {t("topic_library.6")}
                                              </p>
                                              <p
                                                onClick={() =>
                                                  handleShowRelationPopup(
                                                    "parent",
                                                    topic.id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/relation.svg"
                                                      : "/assets/relation_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                Add Parent Relation
                                              </p>
                                              <p
                                                onClick={() =>
                                                  handleShowRelationPopup(
                                                    "child",
                                                    topic.id
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/relation.svg"
                                                      : "/assets/relation_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                Add Child Relation
                                              </p>
                                            </div>
                                          )}
                                      </div>
                                    )}
                                  </>
                                )
                              }
                            ></Collapse.Panel>
                          ))
                        : topicsList?.length === 0 && (
                            <div className={styles.notFoundInfo}>
                              <p className={styles.heading}>
                                {t("empty_states.8")}{" "}
                                <img
                                  src={
                                    theme === "dark"
                                      ? "/assets/emptyStates/topic_library_icon.svg"
                                      : "/assets/emptyStates/topic_library_black.svg"
                                  }
                                  alt=""
                                />
                              </p>
                              <div className={styles.secondRow}>
                                <img
                                  src={
                                    theme === "dark"
                                      ? "/assets/error_outline.svg"
                                      : "/assets/error_outline_black.svg"
                                  }
                                  alt=""
                                />
                                {t("empty_states.9")}
                              </div>
                              <p className={styles.secondHeading}>
                                {t("empty_states.10")}{" "}
                                <img
                                  src={"/assets/double_down_arrow.svg"}
                                  alt=""
                                />
                              </p>
                              <div className={styles.imgBlock}>
                                <div>
                                  <img
                                    src={
                                      theme === "dark"
                                        ? "/assets/emptyStates/topic_library1.png"
                                        : theme === "light"
                                        ? "/assets/emptyStates/topic_library1_light.png"
                                        : "/assets/emptyStates/topic_library1_brand.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src={
                                      theme === "dark"
                                        ? "/assets/emptyStates/topic_library2.png"
                                        : theme === "light"
                                        ? "/assets/emptyStates/topic_library2_light.png"
                                        : "/assets/emptyStates/topic_library2_brand.png"
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                    </Collapse>
                  ) : (
                    <Collapse
                      className={styles.collapse}
                      bordered={false}
                      collapsible={viewType === "list" ? "header" : "icon"}
                    >
                      {topicsList?.length > 0 ? (
                        <TopicLibraryListing
                          editTopicRule={(topic) => {
                            handleRenameRuleModal(topic.query, topic.id);
                            // setShowChildTopicPopup(true)
                            // setSelectedParentName("")
                            // setSelectedChildInfo(topic)
                          }}
                          handleSelectTopic={(topic) => {
                            if (topic?.id !== selectedChildInfo?.id) {
                              setShowChildTopicPopup(true);
                              setSelectedChildInfo(topic);
                            } else {
                              setShowChildTopicPopup(false);
                              setSelectedChildInfo({});
                            }
                            setSelectedParentName("");
                          }}
                          handleCheckboxChange={handleCheckboxChange}
                          topics={topicsList}
                          allTopicsList={allTopicsListWithHierarchy}
                          handleParentCheckboxChange={
                            handleParentCheckboxChange
                          }
                          handleRunDashboard={handleRunDashboard}
                          handleRenameTopicModal={handleRenameTopicModal}
                          handleShowRelationPopup={handleShowRelationPopup}
                          setTopicID={setTopicID}
                          handleShowDeleteTopic={handleShowDeleteTopic}
                        />
                      ) : (
                        topicsList?.length === 0 && (
                          <div className={styles.noCategoricalView}>
                            <p className={styles.heading}>
                              {t("empty_states.19")}{" "}
                              <img
                                src={
                                  theme === "dark"
                                    ? "/assets/emptyStates/categorical_view.svg"
                                    : "/assets/emptyStates/categorical_view_black.svg"
                                }
                                alt=""
                              />
                            </p>
                            <div className={styles.secondRow}>
                              <img
                                src={
                                  theme === "dark"
                                    ? "/assets/error_outline.svg"
                                    : "/assets/error_outline_black.svg"
                                }
                                alt=""
                              />
                              {t("empty_states.20")}
                            </div>
                            <p className={styles.secondHeading}>
                              {t("empty_states.21")}{" "}
                              <img
                                src={"/assets/double_down_arrow.svg"}
                                alt=""
                              />
                            </p>
                            <div className={styles.imgBlock}>
                              <div>
                                <img
                                  src={
                                    theme === "dark"
                                      ? "/assets/emptyStates/categorical1.png"
                                      : theme === "light"
                                      ? "/assets/emptyStates/categorical1_light.png"
                                      : "/assets/emptyStates/categorical1_brand.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div>
                                <img
                                  src={
                                    theme === "dark"
                                      ? "/assets/emptyStates/categorical2.png"
                                      : theme === "light"
                                      ? "/assets/emptyStates/categorical2_light.png"
                                      : "/assets/emptyStates/categorical2_brand.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div>
                                <img
                                  src={
                                    theme === "dark"
                                      ? "/assets/emptyStates/categorical3.png"
                                      : theme === "light"
                                      ? "/assets/emptyStates/categorical3_light.png"
                                      : "/assets/emptyStates/categorical3_brand.png"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </Collapse>
                  )}
                </Spin>
              </div>
            </div>
          }
        </div>
      </div>
      {topicsList && topicsList?.length !== 0 && (
        <div className={styles.paginationWrapper}>
          <div className={styles.innerWrapper}>
            {
              <div className={styles.paginationBox}>
                <Button
                  disabled={startIndex === 0}
                  onClick={() => {
                    parseInt(topicsPageNumber, 10) === startIndex + 1 &&
                      setStartIndex((prev) => prev - 1);
                    parseInt(topicsPageNumber, 10) === startIndex + 1 &&
                      setTopicsPageNumber((prev) => parseInt(prev, 10) - 1);
                  }}
                >
                  <i className="fa fa-angle-left" />
                </Button>
                <span className={styles.pagination}>
                  <input
                    className={styles.pageIndex}
                    onKeyPress={handleUserPage}
                    type="text"
                    value={topicsPageNumber}
                    onChange={(e) => setTopicsPageNumber(e.target.value)}
                  />
                  {totalTopics
                    ? ` / ${Math.ceil(totalTopics / selectedPerPage)}`
                    : ""}
                </span>
                <Button
                  disabled={
                    topicsList?.length === 0 ||
                    (totalTopics &&
                      startIndex + 1 ===
                        Math.ceil(totalTopics / selectedPerPage)) ||
                    !topicsPageNumber
                  }
                  onClick={() => {
                    parseInt(topicsPageNumber, 10) === startIndex + 1 &&
                      setStartIndex((prev) => prev + 1);
                    parseInt(topicsPageNumber, 10) === startIndex + 1 &&
                      setTopicsPageNumber((prev) => parseInt(prev, 10) + 1);
                  }}
                >
                  <i className="fa fa-angle-right" />
                </Button>
              </div>
            }

            {topicsList?.length > 0 && (
              <Select
                className="review-page-number-selector"
                value={selectedPerPage + ` / ${t("label.10")}`}
                onChange={handlePageChange}
                style={{ backgroundColor: "transparent" }}
              >
                <Option value={10}>10</Option>
                <Option disabled={totalTopics <= 10 ? true : false} value={25}>
                  25
                </Option>
                <Option disabled={totalTopics <= 25 ? true : false} value={50}>
                  50
                </Option>
                <Option disabled={totalTopics <= 50 ? true : false} value={100}>
                  100
                </Option>
              </Select>
            )}
          </div>
          {totalTopics > 0 && (
            <div className={styles.dashboardsTotal}>
              {selectedPerPage * startIndex +
                topicsList?.length +
                " of " +
                totalTopics}
            </div>
          )}
        </div>
      )}
      {showCreateTopicOption && (
        <div className={styles.modal}>
          {ruleInputType && ruleInputType === "manual" ? (
            <div className={styles.contentCenterWrapper}>
              <div className={styles.modalContent}>
                <p className={styles.optionHeading}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/custom_words.svg"
                        : "/assets/custom_words_black"
                    }
                    alt=""
                  />
                  New Custom Topic
                </p>
                <div className={styles.radioInput}>
                  {!showAddTopic && (
                    <div className={styles.item}>
                      <input
                        type="radio"
                        id="new_rule"
                        value="new_rule"
                        name="create_type"
                        checked={selectedCreateTopicType === "new_rule"}
                        onChange={handleCreateTypeChange}
                      />
                      <label htmlFor="new_rule">Define a new Rule</label>
                    </div>
                  )}
                  {/* {!showAddTopic && (
                                 <div className={styles.item}>
                                    <input type="radio" id="classifier" value="classifier" checked={selectedCreateTopicType === "classifier"} name="create_type" onChange={handleCreateTypeChange} />
                                    <label htmlFor="classifier">Choose a classifier</label>
                                 </div>
                              )} */}
                  {!showAddTopic && (
                    <div className={styles.item}>
                      <input
                        type="radio"
                        id="existing_rule"
                        value="existing_rule"
                        checked={selectedCreateTopicType === "existing_rule"}
                        name="create_type"
                        onChange={handleCreateTypeChange}
                      />
                      <label htmlFor="existing_rule">Use existing topics</label>
                    </div>
                  )}
                </div>
                {!showAddTopic && (
                  <div className={styles.btns}>
                    <button
                      onClick={() => {
                        setShowCreateTopicOption(false);
                        setSelectedCreateTopicType("new_rule");
                      }}
                    >
                      {t("button.3")}
                    </button>
                    <button
                      disabled={disableButton}
                      onClick={() => {
                        handleShowAddTopic();
                      }}
                    >
                      {t("button.29")}
                    </button>
                  </div>
                )}

                {showAddTopic && selectedCreateTopicType === "classifier" ? (
                  <div className={styles.fields}>
                    <div className={styles.field}>
                      <span>{t("topic_library.20")}*</span>
                      <input
                        type="text"
                        value={topicName}
                        onChange={(e) => setTopicName(e.target.value)}
                        placeholder="Enter text"
                      />
                    </div>
                    {/* <div className={styles.borderless}>
                                          <span className={styles.boxLabel}>Choose a classifier</span>
                                          <Select
                                             placeholder="Search"
                                             style={{ width: "100%" }}
                                             onChange={handleClassifierSelection}
                                             showSearch
                                             showArrow
                                             value={selectedClassifier}
                                             filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                          >
                                          {
                                             classifiers?.map(item => <Option className={styles.item} value={item.id} key={item.id}>{item.name}</Option>)
                                          }
                                          </Select>
                                    </div> */}
                    <div className={styles.field}>
                      <span> Confidence Percentage(%)</span>
                      <input
                        type="number"
                        value={confidenceScore}
                        onChange={(e) => setConfidenceScore(e.target.value)}
                        placeholder="0%-100%"
                        min="1"
                        max="100"
                      />
                    </div>
                    <div className={styles.btns}>
                      <button
                        onClick={() => {
                          setShowCreateTopicOption(false);
                          setShowAddTopic(false);
                        }}
                      >
                        {t("button.3")}
                      </button>
                      <button
                        disabled={disableButton}
                        onClick={handleCreateTopic}
                      >
                        {t("button.7")}
                      </button>
                    </div>
                  </div>
                ) : showAddTopic && selectedCreateTopicType === "new_rule" ? (
                  <div className={styles.fields}>
                    <div className={styles.field}>
                      <span>{t("topic_library.20")}*</span>
                      <input
                        type="text"
                        value={topicName}
                        onChange={(e) => setTopicName(e.target.value)}
                        placeholder="Enter text"
                      />
                    </div>
                    <div className={styles.field}>
                      <span>{t("topic_library.21")} (optional)</span>
                      <textarea
                        value={ruleWords}
                        onChange={(e) => setRuleWords(e.target.value)}
                        placeholder={"Enter rules"}
                        rows="3"
                      />
                      <p style={{ fontSize: "12px", marginTop: "3px" }}>
                        E.g. "Credi" AND Card" AND (Cancel OR Payment)
                      </p>
                    </div>
                    <div className={styles.moreOptionBlock}>
                      <p
                        className={styles.inputTypeToggle}
                        onClick={() => {
                          toggleRuleInputType("ruleBuilder");
                        }}
                      >
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/rule_builder.svg"
                              : "/assets/rule_builder_black.svg"
                          }
                          alt=""
                        />
                        Easy Query Builder
                      </p>

                      {showCreateTopicMoreOption && (
                        <>
                          <div className={styles.field}>
                            <span>{t("topic_library.19")}</span>
                            <Select
                              placeholder={t("topic_library.1")}
                              style={{ width: "100%" }}
                              onChange={handleCreateSelectedLanguage}
                              value={createSelectedLanguage}
                              showSearch
                              optionFilterProp="title"
                            >
                              {lang.map((x) => (
                                <option value={x} key={x} title={x}>
                                  {x}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={styles.btns}>
                      <button
                        onClick={() => {
                          setShowCreateTopicOption(false);
                          setShowAddTopic(false);
                        }}
                      >
                        {t("button.3")}
                      </button>
                      <button
                        disabled={disableButton}
                        onClick={handleCreateTopic}
                      >
                        {t("button.7")}
                      </button>
                    </div>
                  </div>
                ) : (
                  showAddTopic &&
                  selectedCreateTopicType === "existing_rule" && (
                    <div className={styles.existingTopicContent}>
                      <div className={styles.field}>
                        <span>{t("topic_library.20")}*</span>
                        <input
                          type="text"
                          value={topicName}
                          onChange={(e) => setTopicName(e.target.value)}
                          placeholder="Enter text"
                        />
                      </div>
                      <div className={styles.field}>
                        <span>{t("topic_library.19")}</span>
                        <Select
                          placeholder={t("topic_library.1")}
                          style={{ width: "100%" }}
                          onChange={handleCreateSelectedLanguage}
                          value={createSelectedLanguage}
                          showSearch
                          optionFilterProp="title"
                        >
                          {lang.map((x) => (
                            <option value={x} key={x} title={x}>
                              {x}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className={styles.intersectTopicsBox}>
                        <p className={styles.label}>Intersect Topics Below</p>
                        <div className={styles.itemsColumn}>
                          {createTopicObject?.map((item, index) => {
                            return (
                              <div className={styles.item}>
                                {index === 0 ? (
                                  <span className={styles.subLabel}></span>
                                ) : (
                                  <Select
                                    value={
                                      item?.operator ? item?.operator : "and"
                                    }
                                    className={styles.operatorSelector}
                                    onChange={(value) =>
                                      handleOperatorChange(value, index)
                                    }
                                  >
                                    <Option value="and" key="and">
                                      AND
                                    </Option>
                                  </Select>
                                )}
                                <Select
                                  placeholder={t("topic_library.15")}
                                  showSearch
                                  showArrow
                                  onChange={(v) => handlePushValue(v, index)}
                                  maxTagCount="responsive"
                                  value={item?.id}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                >
                                  {allTopicsList?.map((item, index) => (
                                    <Option
                                      className="topic-metric-option"
                                      value={item.id}
                                      key={item.id}
                                    >
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                                {index > 1 && (
                                  <img
                                    className={styles.removeTopicIcon}
                                    src={
                                      theme === "dark"
                                        ? "/assets/trash.svg"
                                        : "/assets/trash_black.svg"
                                    }
                                    onClick={() => removeAddTopic(index)}
                                    alt=""
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className={styles.addOperatorBox}>
                          <span onClick={handleAddOperatorBox}>
                            <i className="fa-solid fa-plus"></i>
                            Add Topic
                          </span>
                        </div>
                      </div>
                      <div className={styles.btns}>
                        <button
                          onClick={() => {
                            setShowCreateTopicOption(false);
                            setShowAddTopic(false);
                            setCreateTopicObject([{}, { operator: "and" }]);
                          }}
                        >
                          {t("button.3")}
                        </button>
                        <button
                          disabled={disableButton}
                          onClick={handleConvert}
                        >
                          {t("button.7")}
                        </button>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            <div className={styles.modalContent}>
              <RuleBuilder
                close={() => {
                  setRuleInputType("manual");
                }}
                save={(query) => {
                  setRuleWords(query);
                  setRuleInputType("manual");
                }}
              />{" "}
            </div>
          )}
        </div>
      )}

      {showRunTopics && (
        <div className={styles.modal}>
          <div className={styles.contentCenterWrapper}>
            <div className={styles.runTopicsModalContent}>
              <p className={styles.title}>
                <p>{t("topic_library.3")}</p>
                <p>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/help-circle.svg"
                        : "/assets/help-circle-black.svg"
                    }
                    alt=""
                  />
                  {t("topic_library.13")}
                </p>
              </p>
              <div className={styles.fields}>
                <div className={styles.field}>
                  <span className={styles.boxLabel}>
                    {t("topic_library.14")}
                  </span>
                  <Select
                    placeholder={t("topic_library.15")}
                    style={{ width: "100%" }}
                    mode="multiple"
                    showSearch
                    showArrow
                    onChange={(val) => handleTopicsID(val)}
                    value={selectedTopicIds}
                    maxTagCount="responsive"
                    optionFilterProp="title"
                  >
                    {allTopicsList?.map((item, index) => (
                      <Option
                        className="topic-metric-option"
                        value={item.id}
                        key={item.id}
                        title={item.name}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.field}>
                  <span className={styles.boxLabel}>
                    {t("topic_library.9")}
                  </span>
                  <Select
                    placeholder={t("topic_library.7")}
                    style={{ width: "100%" }}
                    onChange={handleTopicRunOn}
                    showSearch
                    showArrow
                    mode="multiple"
                    value={selectedDashboardID}
                    maxTagCount="responsive"
                    optionFilterProp="title"
                  >
                    {dashboards?.map((item) => (
                      <Option
                        value={item.id}
                        key={item.id}
                        title={item.name}
                        disabled={item.status !== "Ready"}
                      >
                        {`${item.name} (${item.id}) `}{" "}
                        {item.status !== "Ready" ? "(" + item.status + ")" : ""}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className={styles.btns}>
                  <button onClick={() => setShowRunTopics(false)}>
                    {t("button.3")}
                  </button>
                  <button
                    disabled={disableButton}
                    onClick={handleTopicsRunOnDashboard}
                  >
                    {t("button.8")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRunDashboard && (
        <div className={styles.modal}>
          <div className={styles.contentCenterWrapper}>
            <div className={styles.popupContent}>
              <span className={styles.topicLabel}>
                {t("topic_library.8")} - {selectedTopicName}
              </span>
              <span className={styles.title}>{t("topic_library.9")}</span>
              <Select
                placeholder={t("topic_library.7")}
                style={{ width: "100%" }}
                onChange={handleTopicRunOn}
                showSearch
                showArrow
                mode="multiple"
                value={selectedDashboardID}
                maxTagCount="responsive"
                optionFilterProp="title"
              >
                {dashboards?.map((item) => (
                  <Option
                    value={item.id}
                    key={item.id}
                    title={item.name}
                    disabled={item.status !== "Ready"}
                  >
                    {`${item.name} (${item.id}) `}{" "}
                    {item.status !== "Ready" ? "(" + item.status + ")" : ""}
                  </Option>
                ))}
              </Select>

              <div className={styles.btns}>
                <button onClick={() => setShowRunDashboard(false)}>
                  {t("button.3")}
                </button>
                <button disabled={disableButton} onClick={topicRunOnDashboard}>
                  {t("button.8")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {
            showTagTopicPopup && (
               <div className={styles.modal}>
                  <div className={styles.contentCenterWrapper}>
                     <div className={styles.tagTopicContent}>
                        <div className={styles.title}>
                           <span>{t("topic_library.27")}</span>
                           <span onClick={() => history.push("/console/tags")}>{t("topic_library.28")}</span>
                        </div>
                        <div className={styles.searchTagBox}>
                           <Select
                              style={{ width: "100%" }}
                              onChange={handleChangeSelectedTag}
                              disabled={addTag ? true : false}
                              showSearch
                              value={addTag ? "" : "Select a tag"}
                              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                           >
                              {
                                 allTags.map(tag => <Option value={tag.tag_name} key={tag.tag_id}>{tag.tag_name}</Option>)
                              }
                           </Select>
                           {addTag && (
                              <>
                                 <span className={styles.firstItem}>{addTag}</span>
                                 <span className={styles.secondItem} onClick={() => {
                                    setAddTag('')
                                    setSelectedTagID(undefined)
                                 }}>{t("topic_library.29")}</span>
                              </>
                           )}
                        </div>
                        <div className={styles.buttonBlock}>
                           <button onClick={() => setShowTagTopicPopup(false)}>{t("button.3")}</button>
                           <button onClick={handleAddTag}>{t("button.30")}</button>
                        </div>
                     </div>
                  </div>
               </div>
            )
         } */}
      {showRenameRulePopup && (
        <RenameDeletePopup
          text={renameRuleValue}
          setText={setRenameRuleValue}
          label="Topic Rule"
          handleAction={handleRuleRename}
          placeholder={"Add Topic rule"}
          closePopup={setShowRenameRulePopup}
          type="update"
          disableBtn={disableButton}
          element="textarea"
        />
      )}
      {showRenameTopicPopup && (
        <RenameDeletePopup
          text={renameTopicValue}
          setText={setRenameTopicValue}
          label={t("topic_library.5")}
          handleAction={handleTopicRename}
          placeholder={"Add Topic rule"}
          closePopup={setShowRenameTopicPopup}
          disableBtn={disableButton}
          type="rename"
        />
      )}
      {showDeleteTopicPopup && (
        <Modal
          showModal={showDeleteTopicPopup}
          onClose={() => setShowDeleteTopicPopup(false)}
          onSave={handleTopicDelete}
          showSaveButton={true}
          showCancelButton={true}
          hideCloseIcon={true}
          textonButton={t("button.2")}
          titleAsset=""
          title={"Delete topic"}
          loadingSaveClick={deletionInProgress}
          widthofmodal="450px"
          disabled={deletionInProgress}
          isDeleteModal={true}
        >
          <p className={styles.deletetext}>
            {t("selected_dashboard_page.80")}{" "}
            <b>
              {
                allTopicsList?.find(
                  (item) => item.id?.toString() === topicID?.toString()
                )?.name
              }
            </b>{" "}
            {t("selected_dashboard_page.85")}
          </p>
        </Modal>
      )}
      {showMobileViewFilter && (
        <div
          className={styles.modal}
          onClick={() => setShowMobileViewFilter(false)}
        >
          <div className={styles.contentCenterWrapper}>
            <div
              className={styles.mobileViewContent}
              onClick={(e) => e.stopPropagation()}
            >
              <p className={styles.title}>
                <span>{t("others.23")}</span>
                <span onClick={handleMobileFilterReset}>
                  {t("selected_dashboard_page.5")}
                </span>
              </p>
              <p className={styles.languageDropdown}>{t("topic_library.19")}</p>
              <Select
                placeholder={t("topic_library.1")}
                showSearch
                style={{ width: "100%" }}
                value={selectedLanguage}
                onChange={(value) => setSelectedLanguage(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {" "}
                <option value="All" key="All">
                  All
                </option>
                {lang.map((x) => (
                  <option value={x} key={x}>
                    {x}
                  </option>
                ))}
              </Select>
              {/* <p className={styles.tagDropdown}>Parent</p>
                        <Select
                           showSearch
                           style={{ width: "100%" }}
                           onChange={handleSelectedParentFilter}
                           mode={"multiple"}
                           maxTagCount={1}
                           placeholder={"Parent"}
                           filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                           <Option key="All">All</Option>
                           <Option key="others">Others</Option>
                           {parents?.map((x) => (
                              <Option key={x.id}>{x.name}</Option>
                           ))}
                        </Select> */}

              <button
                className={styles.applyBtn}
                onClick={handleMobileViewFilter}
              >
                {t("topic_library.30")}
              </button>
            </div>
          </div>
        </div>
      )}
      {showAddRelationPopup && (
        <div className={styles.modal}>
          <div className={styles.contentCenterWrapper}>
            <div className={styles.addRelationContent}>
              <p className={styles.title}>
                <span>
                  Add {relationPopupType === "parent" ? "Parent" : "Child"}
                </span>
                <img
                  onClick={() => setShowAddRelationPopup(false)}
                  src={
                    theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"
                  }
                  alt=""
                />
              </p>
              <p className={styles.subTitle}>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/info1.svg"
                      : "/assets/info1_black.svg"
                  }
                  alt=""
                />
                Add hierarchical relationship with selected topics
              </p>
              <p className={styles.dropdownLabel}>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/relation.svg"
                      : "/assets/relation_black.svg"
                  }
                  alt=""
                />
                {relationPopupType === "parent" ? "Parent" : "Child"}
              </p>
              <Select
                placeholder="Select Custom Topics"
                style={{ width: "100%" }}
                onChange={handleAddRelationChange}
                showSearch
                showArrow
                mode="multiple"
                maxTagCount="responsive"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {relationPopupType === "parent"
                  ? parents?.map((item) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))
                  : children?.map((item) => (
                      <Option value={item.id}>{item.name}</Option>
                    ))}
              </Select>

              <div className={styles.btns}>
                <button onClick={() => setShowAddRelationPopup(false)}>
                  {t("button.3")}
                </button>
                <button disabled={disableButton} onClick={handleAddRelation}>
                  {t("button.30")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showChildTopicPopup && (
        <ChildTopicPopup
          setShowChildTopicPopup={setShowChildTopicPopup}
          handleShowRelationPopup={handleShowRelationPopup}
          setShowDeleteRelationPopup={setShowDeleteRelationPopup}
          selectedChildInfo={selectedChildInfo}
          selectedParentName={selectedParentName}
          setSelectedChildInfo={setSelectedChildInfo}
          setSelectedParentName={setSelectedParentName}
          setSelectedChildID={setSelectedChildID}
          handleRenameRuleModal={handleRenameRuleModal}
          handleCheckboxChange={handleCheckboxChange}
          allTopicsList={allTopicsList}
          type={viewType}
          setTopicID={setTopicID}
          renameRuleValue={renameRuleValue}
          setRenameRuleValue={setRenameRuleValue}
          disableBtn={disableButton}
          addRuleAction={handleRuleRename}
        />
      )}
      {showDeleteRelationPopup && (
        <div className={styles.modal}>
          <div className={styles.contentCenterWrapper}>
            <div className={styles.addRelationContent}>
              <p className={styles.title}>
                <span>Remove Child</span>
                <img
                  onClick={() => setShowDeleteRelationPopup(false)}
                  src={
                    theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"
                  }
                  alt=""
                />
              </p>
              <p className={styles.subTitle}>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/info1.svg"
                      : "/assets/info1_black.svg"
                  }
                  alt=""
                />
                It will remove solely the relationship, not the custom topic
                itself
              </p>
              <p className={styles.deleteLabel}>
                Are you sure you want to delete?
              </p>

              <div className={styles.btns}>
                <button onClick={() => setShowDeleteRelationPopup(false)}>
                  {t("button.3")}
                </button>
                <button disabled={disableButton} onClick={removeChild}>
                  {t("button.2")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopicLibrary;
