import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import DashboardHeader from "components/Dashboard/Header";
import { UNLOGGED } from "util/constants";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import dayjs from "dayjs";
import {
  notification,
  Button,
  Spin,
  Input,
  Menu,
  Select,
  Dropdown,
  Tag,
} from "antd";
import { apiRequest } from "util/services";
import {
  hexToRgbA,
  getWithExpiry,
  validateEmail,
  setWithExpiry,
  scrollToElement,
} from "util/common";
import { CloseOutlined } from "@ant-design/icons";
import { options } from "./chartOptions";
import { Bubble, Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import BoardCard from "components/Dashboard/BoardCard";
import SelectedTopicInfo from "components/Dashboard/SelectedTopicInfo";
import BoardError from "components/Dashboard/BoardError";
import Modal from "antd/lib/modal/Modal";
import { getToolTip } from "./tooltips";
import zoom from "chartjs-plugin-zoom";
import TrendsView from "../TrendsView";
import Guide from "./Guide";
import mixpanel from "mixpanel-browser";
import CustomSummary from "components/CustomSummary";
import NestedTabs from "components/NestedTabs";
import { Modal as CustomModal } from "components/General/ModalFormComponents";
import CreationItem from "components/NewDashboard/CreationItem";
import { useTranslation } from "react-i18next";
import CustomTopicFilter from "../CustomTopicFilter/index ";

const { SubMenu } = Menu;

const DashboardWrapper = ({
  shared,
  authenticated,
  user,
  currentDashboardHash,
  currentDashboard,
  topicsType,
  match,
}) => {
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const history = useHistory();
  const location = useLocation();
  const [queryFilters, setQueryFilters] = useState({});
  const { search } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const [summaryItems, setSummaryitems] = useState([]);
  const [tempSummaryItems, setTempSummaryItems] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [trends, setTrends] = useState(null);
  const [tempTrends, setTempTrends] = useState(null);
  const [trendsLoading, setTrendsLoading] = useState(false);
  if (!currentDashboard && !DashboardReducer.dashboard && !match.params.id) {
    history.push("/console/myDashboards");
  }

  const initialState = {
    infoLoading: false,
    isBubbleView: params.get("view_type") === "trends" ? false : true,
    currentDashboard: shared
      ? currentDashboard
      : match.params.id || DashboardReducer.dashboard?.ID,
  };
  const initialShareableState = {
    chosenTopics: getWithExpiry("VISITOR_CHOSEN_TOPICS")
      ? getWithExpiry("VISITOR_CHOSEN_TOPICS")
      : [],
    mailChimpModal: false,
    showMoreInfo: false,
    allowedVisitor: getWithExpiry("VISITOR_SUBSCRIBED"),
    checkTerms: false,
    showGoToLogin: false,
    blur: false,
  };
  const [localState, setState] = useState(initialState);
  const [disableDateRange, setDisableDateRange] = useState([true, true]);
  const [bubbleOptions, setBubbleOptions] = useState(options);
  const [shareableState, setShareableState] = useState(initialShareableState);
  const [searchByKeywordModal, setSearchByKeywordModal] = useState(false);
  const [searchTopicModal, setSearchTopicModal] = useState(false);
  const [searchTopicWord, setSearchTopicWord] = useState("");
  const [selectedTopic, setSelectedTopic] = useState({ index: -1 });
  const [activeTopic, setActiveTopic] = useState({});
  const [selectedTopicData, setSelectedTopicData] = useState(null);
  const [topicsLoading, setTopicsLoading] = useState(null);

  const [selectQuickNav, setSelectQuickNav] = useState(false);
  const dispatch = useDispatch();
  let bubbleChart = useRef(null);
  const [changeDateRange, setChangeDateRange] = useState(false);
  const [downloadMonthlyStats, setDownloadMonthlyStats] = useState(false);
  const [downloadUnmatchedReviews, setDownloadUnmatchedReviews] = useState(
    false
  );
  const [createCustomTopicModal, setCreateCustomTopicModal] = useState(false);
  const [customTopicState, setCustomTopicState] = useState({
    top_words: [],
    semantic_enrich: false,
  });
  const [reset, setReset] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);
  const { t } = useTranslation();
  const [topicChanged, setTopicChanged] = useState(false);
  const [showDownload, setShowDownload] = useState(true);
  const [closeDateFilter, setCloseDateFilter] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(true);
  const [dashboardLoaded, setDashboardLoaded] = useState(false);
  const closeSelectedTopic = () => {
    if (params.get("insightTopicSearch")) {
      updateQParams("insightTopicClosed", "true");
    }
    [
      "sentiment",
      "intent",
      "rating",
      "top_topics",
      "topics_operator",
      "platform_id",
      "match_date",
      "hotterm",
      "nps",
      "keyword",
      "pivotFilter",
      "pivots_operator",
      "level1Selection",
      "level2Selection",
      "level1OfSelected",
      "level2OfSelected",
      "selected_topic",
      "insightTopicSearch",
    ].map((item) => params.delete(item));
    history.push({ search: params.toString() });
    setSelectedTopic({ index: -1 });
    setSelectedTopicData();
    setActiveTopic({});
  };
  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected" ||
          key === "selected_topic" ||
          key === "active_topic_data"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);

  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };
  const [toggleListCard, setToggleListCard] = useState(
    params.get("view_type") === "trends" ? "card" : "list"
  );

  useEffect(() => {
    if (topicsType) {
      updateQParams("topics_type", topicsType);
    }
  }, [topicsType]);

  useEffect(() => {
    if (
      topicsLoading === true ||
      localState.infoLoading === true ||
      localState.loading === true
    ) {
      setDisableDateRange([true, true]);
    } else {
      setDisableDateRange([false, false]);
    }
  }, [topicsLoading, localState.infoLoading, localState.loading]);

  useEffect(() => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: true,
      },
    });
    if (authenticated === UNLOGGED && !shared) {
      history.push("/");
      return;
    }
    if (!localState.currentDashboard) {
      notification.error({
        message: t("notifications.42"),
      });
      history.push("/console/myDashboards");
      return;
    }
    checkDashboardStatus(localState.currentDashboard);
  }, [DashboardReducer.selected_topic]);

  useEffect(() => {
    if (
      topicsLoading === true ||
      localState.infoLoading === true ||
      localState.loading === true
    ) {
      setDisableDateRange([true, true]);
    } else {
      setDisableDateRange([false, false]);
    }
  }, [topicsLoading, localState.infoLoading, localState.loading]);

  useEffect(() => {
    if (selectedTopic?.id && topicsType === "ai") {
      decreaseOpacity(selectedTopic.id);
    } else if (DashboardReducer.topics && topicsType === "ai") {
      decreaseOpacity(-1, true);
    }
  }, [selectedTopic]);
  const daysRangeRef = useRef(params.get("daysRange"));
  useEffect(() => {
    if (
      params.get("topics_type") === "ai" &&
      params.get("view_type") !== "trends" &&
      (!localState.loading || !localState.infoLoading || !topicsLoading)
    ) {
      if (daysRangeRef.current !== params.get("daysRange")) {
        getDashboardTopics(localState.currentDashboard);
        daysRangeRef.current = params.get("daysRange");
      }
    }
  }, [queryFilters]);

  useEffect(() => {
    setSelectedTopicData();
    setSelectedTopic({ index: -1 });
    if (
      !location.state?.searchInsight ||
      (DashboardReducer?.insightBasedSearch?.topic_type === "ai" &&
        !localState.isBubbleView)
    ) {
      dispatch({
        type: actions.SELECT_TOPIC,
        payload: {
          selected_topic: undefined,
          insightBasedSearch: {},
          trends: {},
        },
      });
    }
  }, [localState.isBubbleView]);

  useEffect(() => {
    setSelectedTopicData();
    setSelectedTopic({ index: -1 });
  }, [toggleListCard]);

  useEffect(() => {
    handleZoomInOut();
  }, [zoomLevel]);

  const checkDashboardStatus = async (DashboardId) => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: true,
      },
    });
    const result = await apiRequest(
      "dashboards/v2/get",
      "POST",
      {
        dashboard_id: parseInt(DashboardId, 10),
      },
      true
    );
    if (result.error || result.cancel || result.validation_error) {
      notification.error({
        message: result?.error
          ? result.error
          : "Unable to get dashboard status",
      });
      dispatch({
        type: actions.SET_Dashboard,
        payload: {
          failureMsg: result.error,
        },
      });
    } else if (result.status?.ID === -1 || result.status?.ID === 5) {
      notification.info({
        message: t("notifications.44"),
      });
      history.push(`/console/myDashboards`);
    } else if (
      result.status.ID !== 4 &&
      result.status.ID !== 6 &&
      result.status.ID !== 7 &&
      !result?.dashboard?.IsDynamic
    ) {
      notification.info({
        message: t("notifications.4"),
      });
      history.push(`/console/journey/${initialState.currentDashboard}`);
    } else {
      if (
        !params.has("since") &&
        !params.has("until") &&
        params.get("daysRange") !== "allTime"
      ) {
        if (!result.end_date && !result.start_date) {
          updateQParams("daysRange", "allTime");
          dispatch({
            type: actions.UPDATE_SEARCH_STATE,
            payload: {
              ...result,
              selectedDaysRange: "allTime",
              dateRange: [],
            },
          });
        } else {
          // Create a dateRange array for last 7 days with the end_date
          // Create a dateRange array for last 7 days with the end_date
          const cd = new Date(
            DashboardReducer.dashboard?.RefreshRate
              ? new Date()
              : result.end_date
          );
          const pd = new Date(
            DashboardReducer.dashboard?.RefreshRate
              ? new Date()
              : result.end_date
          );
          cd.setDate(cd.getDate());
          pd.setDate(pd.getDate());
          pd.setDate(pd.getDate() - 1);
          const formattedCurrentDate = cd.toISOString().split("T")[0];
          const formattedPastDate = pd.toISOString().split("T")[0];
          const sinceAlreadyExists = params.get("since") === formattedPastDate;
          const untilAlreadyExists =
            params.get("until") === formattedCurrentDate;
          if (sinceAlreadyExists && formattedPastDate) {
            updateQParams("since", formattedPastDate);
          } else if (!sinceAlreadyExists && formattedPastDate) {
            updateQParams("since", formattedPastDate);
          }
          if (untilAlreadyExists && formattedCurrentDate) {
            updateQParams("until", formattedCurrentDate);
          } else if (!untilAlreadyExists && formattedCurrentDate) {
            updateQParams("until", formattedCurrentDate);
          }
          updateQParams("daysRange", "last24");
          history.push({ search: params.toString() });
          dispatch({
            type: actions.UPDATE_SEARCH_STATE,
            payload: {
              ...result,
              dateRange: [formattedPastDate, formattedCurrentDate],
            },
          });
        }
      }
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenKeywords: [],
          chosenFetchType: "topic",
          chosenHotTerm: "",
          chosenRestaurantName: "",
          chosenNPS: "",
          chosenTopTopic: [],
        },
      });
      if (result.status.ID === 6 && topicsType === "custom") {
        notification.info({ message: t("notifications.55") });
      }
      if (params.get("topics_type") === "fixed") {
        dispatch({
          type: actions.UPDATE_Dashboard,
          payload: {
            ...result,
            loading: false,
          },
        });
      } else if (params.get("topics_type") === "custom") {
        dispatch({
          type: actions.UPDATE_Dashboard,
          payload: {
            ...result,
            loading: false,
          },
        });
      } else {
        dispatch({
          type: actions.UPDATE_Dashboard,
          payload: {
            ...result,
            loading: false,
          },
        });
        getDashboardTopics(result.dashboard.ID);
      }
    }
    setDashboardLoaded(true);
  };

  const getDashboardTopics = async (currentDashboard) => {
    setTopicsLoading(true);
    setState({
      ...localState,
      loading: true,
      infoLoading: true,
      topicRatingsLoading: true,
    });
    const result = await apiRequest(
      "dashboards/get/topics",
      "POST",
      {
        dashboard_id: parseInt(currentDashboard, 10),
        topic_type: 1,
        since: params?.has("since") ? params.get("since") : "",
        until: params?.has("until") ? params.get("until") : "",
      },
      true
    );
    if (!result.error) {
      let dataPoints = { datasets: [] };
      result.forEach((item, index) => {
        dataPoints.datasets.push({
          label: item.ID,
          tweet: item.Top20Words,
          data: [
            {
              x: item?.x,
              y: item?.y,
              r: item?.r,
              name: item?.Label,
            },
          ],
          AvgSentiment: item?.AvgSentiment,
          topWords: item?.Top20Words,
          backgroundColor: hexToRgbA(item?.BackgroundColor, 1),
          hoverBackgroundColor: item?.HoverBackgroundColor,
          fontColor: item.FontColor,
        });
      });
      dataPoints.datasets.forEach((v, i) => {
        if (v.label === DashboardReducer.selected_topic) {
          const newTopic = {
            id: v.label,
            label: v.data[0].name,
            index: i,
            backgroundColor: v.backgroundColor,
          };
          getSelectedTopicData(newTopic.id, newTopic);
        }
      });
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          topics: { ...dataPoints },
          loading: false,
        },
      });
    } else {
      dispatch({
        type: actions.SET_Dashboard,
        payload: {
          failureMsg: result.error,
        },
      });
    }
    setTopicsLoading(false);
    setState({
      ...localState,
      loading: false,
      infoLoading: false,
      topicRatingsLoading: false,
    });
  };

  const checkShareableState = (index) => {
    const { chosenTopics, allowedVisitor } = shareableState;
    if (!shared || user?.plan !== undefined || allowedVisitor) return true;
    if (shareableState.chosenTopics.length < 3) {
      setShareableState({
        ...shareableState,
        chosenTopics: [...chosenTopics, index],
        blur: false,
      });
      setWithExpiry("VISITOR_CHOSEN_TOPICS", [...chosenTopics, index], 48);
      return true;
    } else if (chosenTopics.includes(index)) {
      setShareableState({
        ...shareableState,
        blur: false,
      });
      return true;
    } else {
      if (!getWithExpiry("VISITOR_SUBSCRIBED")) {
        setShareableState({
          ...shareableState,
          mailChimpModal: true,
          blur: true,
        });
      }
    }
  };

  const handleBubbleClick = (event, item) => {
    if (item[0]) {
      if (params.has("selected_topic")) setTopicChanged(true);
      const index = item[0]._datasetIndex;
      const id = DashboardReducer.topics.datasets[index].label;
      mixpanel.track("CLICK_AI_TOPIC");
      for (let l = 1; l >= 1; l++) {
        if (params.has(`level${l}Selection`)) {
          params.delete(`level${l}Selection`);
          history.push({ search: params.toString() });
          dispatch({
            type: actions.UPDATE_SEARCH_STATE,
            payload: {
              [`selectedL${l}Topic`]: null,
            },
          });
        } else {
          break;
        }
      }
      [
        "top_topics",
        "topics_operator",
        "hotterm",
        "nps",
        "keyword",
        "pivotFilter",
        "pivots_operator",
        "level1OfSelected",
        "level2OfSelected",
      ].forEach((item) => {
        if (params.has(item)) {
          params.delete(item);
        }
      });
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenKeywords: [],
          chosenFetchType: "topic",
          chosenHotTerm: "",
          chosenRestaurantName: "",
          chosenNPS: "",
          chosenTopTopic: [],
        },
      });
      if (id === selectedTopic.id) {
        getSelectedTopicData(id);
      } else {
        let label = "";
        DashboardReducer.topics.datasets.forEach((item) => {
          if (item.label === id) {
            label = item.data[0].name;
          }
        });
        const newTopic = {
          index,
          backgroundColor: item[0]._model.backgroundColor,
          label,
          id,
        };
        getSelectedTopicData(id, newTopic);
      }
    }
  };

  const decreaseOpacity = (topicId, reset) => {
    let data = { ...DashboardReducer.topics };
    let rgba, id;
    if (data?.datasets?.length > 0) {
      for (let i = 0; i < data?.datasets?.length; i++) {
        id = data.datasets[i].label;
        if (!data.datasets[i].backgroundColor) continue;
        rgba = data.datasets[i].backgroundColor
          .replace(/^rgba?\(|\s+|\)$/g, "")
          .split(",");
        if (id === topicId || reset) {
          data.datasets[
            i
          ].backgroundColor = `rgba(${rgba[0]},${rgba[1]},${rgba[2]}, 1)`;
        } else {
          data.datasets[
            i
          ].backgroundColor = `rgba(${rgba[0]},${rgba[1]},${rgba[2]}, 0.1)`;
        }
      }
    }
    let newState = {
      ...localState,
      topics: data,
    };
    setState(newState);
    if (reset) {
      setSelectedTopicData();
    }
  };

  useEffect(() => {
    if (
      params.has("selected_topic") &&
      (params.get("topics_type") !== "ai" ||
        (params.get("topics_type") === "ai" &&
          params.get("view_type") === "trends")) &&
      queryFilters?.selected_topic?.id &&
      !localState.infoLoading
    ) {
      if (queryFilters?.selected_topic?.id === selectedTopic?.id) {
        return;
      }
      getSelectedTopicData(
        queryFilters?.selected_topic?.id,
        queryFilters?.selected_topic
      );
    }
  }, [queryFilters.selected_topic]);

  const getSelectedTopicData = async (topicId, newTopic, activeTopicData) => {
    if (topicId === selectedTopic.id) {
      setState({
        ...localState,
        infoLoading: false,
      });
      setSelectedTopicData();
      params.delete("selected_topic");
      params.delete("active_topic_data");
      [
        "top_topics",
        "topics_operator",
        "hotterm",
        "nps",
        "keyword",
        "pivotFilter",
        "pivots_operator",
        "level1OfSelected",
        "level2OfSelected",
      ].forEach((item) => params.delete(item));
      history.push({ search: params.toString() });
      setSelectedTopic({ index: -1 });
      return;
    }
    checkShareableState(topicId);
    scrollToElement("SelectedTopicInfo");
    setState({ ...localState, infoLoading: true });
    const result = await apiRequest(
      "dashboards/v2/get/topics/selected_topic",
      "POST",
      {
        dashboard_id:
          DashboardReducer.dashboard?.ID ||
          parseInt(initialState.currentDashboard),
        topic_id: topicId,
        topic_type: topicsType,
        n: 5,
        since: params.get("since") ? params.get("since") : "",
        until: params.get("until") ? params.get("until") : "",
      },
      true
    );
    if (!result.error) {
      let data = { ...result, id: topicId, infoLoading: false };
      if (typeof data.TopWords === "string") {
        data.TopWords = data.TopWords.replace(/[\[|\]|\']/g, "").split(", ");
      }
      setSelectedTopicData(data);
      updateQParams("selected_topic", JSON.stringify(newTopic));
      setSelectedTopic(newTopic);
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          showNewReviewsLabel: false,
        },
      });
      setState({ ...localState, blur: false, infoLoading: false });
      scrollToElement("SelectedTopicInfo");
      if (
        DashboardReducer.insightBasedSearch &&
        location?.state?.selected_topic === topicId
      ) {
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: DashboardReducer.insightBasedSearch,
        });
        dispatch({
          type: actions.UPDATE_Dashboard,
          payload: {
            showNewReviewsLabel: true,
          },
        });
      }
    } else {
      notification.error({
        message: result.error,
      });
      setState({ ...localState, blur: true, infoLoading: false });
    }
  };

  const handleAddVisitor = async () => {
    const { checkTerms } = shareableState;
    if (!checkTerms) {
      notification.error({
        message: t("notifications.56"),
      });
      return;
    }

    let visitorEmail = document.getElementById("visitorEmail").value;
    if (!validateEmail(visitorEmail)) {
      notification.error({
        message: t("notifications.57"),
      });
      return;
    }
    const result = await apiRequest(
      "users/add_visitor",
      "POST",
      {
        email: visitorEmail,
      },
      true
    );
    if (result.success) {
      notification.success({
        message: t("notifications.58"),
      });
      setShareableState({
        ...shareableState,
        allowedVisitor: true,
        mailChimpModal: false,
        blur: false,
      });
      setWithExpiry("VISITOR_SUBSCRIBED", true, 48);
    } else if (result.status === "user") {
      notification.info({
        message: t("notifications.59"),
      });
      setShareableState({ ...shareableState, showGoToLogin: true });
    } else if (result.status === "pending") {
      notification.info({
        message: t("notifications.60"),
      });
    } else {
      notification.error({
        message: t("notifications.61"),
      });
    }
  };

  const selectMostBubble = (type) => {
    let most = type === "positive" ? 0 : 1;
    most = type === "least_rating" ? 1000 : most;
    let mostIndex = -1;
    DashboardReducer.topics.datasets.forEach((topic, i) => {
      if (type === "positive") {
        mostIndex = most < topic.AvgSentiment ? i : mostIndex;
        most = most < topic.AvgSentiment ? topic.AvgSentiment : most;
      } else if (type === "negative") {
        mostIndex = most > topic.AvgSentiment ? i : mostIndex;
        most = most > topic.AvgSentiment ? topic.AvgSentiment : most;
      } else if (type === "most_complaint") {
        mostIndex = most < topic.Complaint ? i : mostIndex;
        most = most < topic.Complaint ? topic.Complaint : most;
      } else if (type === "least_rating") {
        mostIndex = most > topic.AvgRating ? i : mostIndex;
        most = most > topic.AvgRating ? topic.AvgRating : most;
      }
    });
    if (type === "search") {
      mostIndex = searchTopicWord ? searchTopicWord : 0;
      setSearchTopicModal(false);
    } else if (type === "searchByKeyword") {
      const searchKeyword = document.getElementById("searchByKeywordInput")
        .value;
      DashboardReducer.topics.datasets.forEach((topic, i) => {
        topic.topWords.forEach((word) => {
          if (word.toLowerCase() === searchKeyword.toLowerCase()) {
            mostIndex = i;
            return;
          }
        });
      });
      if (mostIndex !== -1) {
        notification.success({
          message: t("notifications.62", {
            search: searchKeyword,
            name: DashboardReducer.topics.datasets[mostIndex].data[0].name,
          }),
        });
      } else {
        notification.info({
          message: t("notifications.63", searchKeyword),
        });
      }
      setSearchByKeywordModal(false);
    }

    if (mostIndex !== -1) {
      handleBubbleClick(null, [
        {
          _datasetIndex: mostIndex,
          _model: {
            backgroundColor:
              DashboardReducer.topics.datasets[mostIndex]?.backgroundColor,
          },
        },
      ]);
    } else if (type === "positive" || type === "negative") {
      notification.info({
        message: t("notifications.64"),
      });
    }
    setSelectQuickNav(true);
  };
  const bubbleDataSets = DashboardReducer?.topics?.datasets;
  const xValues = bubbleDataSets?.map((item) => item?.data[0]?.x);
  const yValues = bubbleDataSets?.map((item) => item?.data[0]?.y);
  const filteredValues = {
    x: xValues?.filter((v) => {
      if (v) {
        return v;
      }
    }),
    y: yValues?.filter((v) => {
      if (v) {
        return v;
      }
    }),
  };
  const quickNavMenu = (
    <Menu
      theme="dark"
      style={{
        color: theme === "dark" ? "white" : "black",
        backgroundColor: "#000c17",
      }}
    >
      <Menu.ItemGroup
        key="sub1"
        title={
          <span style={{ color: theme === "dark" ? "white" : "black" }}>
            Quick Navigation
          </span>
        }
      >
        <Menu.Item
          icon={getToolTip("selectTopic")}
          onClick={() => setSearchTopicModal(true)}
          key="7"
        >
          {t("selected_dashboard_page.25")}
        </Menu.Item>
        <Menu.Item
          icon={getToolTip("selectByKeyword")}
          onClick={() => setSearchByKeywordModal(true)}
          key="8"
        >
          {t("selected_dashboard_page.26")}
        </Menu.Item>
        <Menu.Item
          icon={getToolTip("selectMostPositive")}
          onClick={() => selectMostBubble("positive")}
          key="5"
        >
          {t("selected_dashboard_page.27")}
        </Menu.Item>
        <Menu.Item
          icon={getToolTip("selectMostNegative")}
          onClick={() => selectMostBubble("negative")}
          key="6"
        >
          {t("selected_dashboard_page.28")}
        </Menu.Item>
        {/* <Menu.Item
          icon={getToolTip("selectMostComplaints")}
          onClick={() => selectMostBubble("most_complaint")}
          key="7"
        >
          Get the most complaint topic 
        </Menu.Item>
        <Menu.Item
          icon={getToolTip("selectLeastRating")}
          onClick={() => selectMostBubble("least_rating")}
          key="8"
        >
          Get the topic with least rating
        </Menu.Item> */}
      </Menu.ItemGroup>
    </Menu>
  );
  let uniqueAllDates = [];
  if (tempSummaryItems?.length > 0) {
    let dateArray = [];
    let period = "monthly";
    const since = params.get("since")
      ? params.get("since")
      : DashboardReducer.dashboard_start_date;
    const until = params.get("until")
      ? params.get("until")
      : DashboardReducer.dashboard_end_date;
    if (since && until) {
      const sinceDate = dayjs(since);
      const untilDate = dayjs(until);
      const noOfDays =
        since === until ||
        (untilDate.diff(sinceDate, "day") === 1 && since !== until)
          ? untilDate.diff(sinceDate, "day")
          : untilDate.diff(sinceDate, "day") + 1;
      period = noOfDays < 30 ? "daily" : noOfDays === 30 ? "weekly" : "monthly";

      const endDate = new Date(until);
      const startDate = new Date(since);

      let currentDate = startDate;
      while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      const allDates = dateArray?.map((date) => {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        if (
          period &&
          (period.toLowerCase() === "daily" ||
            period.toLowerCase() === "weekly")
        ) {
          return `${year}-${month}-${day}`;
        } else if (period && period.toLowerCase() === "monthly") {
          return `${year}-${month}`;
        } else if (period && period.toLowerCase() === "yearly") {
          return `${year}`;
        } else {
          return `${year}-${month}-${day}`;
        }
      });
      uniqueAllDates = [...new Set(allDates)];
      if (period === "weekly") {
        const endDateWeekday = endDate.getDay();
        uniqueAllDates = uniqueAllDates.filter((date) => {
          const dateObj = new Date(date);
          return dateObj.getDay() === endDateWeekday;
        });
      }
      uniqueAllDates.sort((a, b) => {
        return new Date(a) - new Date(b);
      });
    } else {
      const allTrends = tempSummaryItems?.map((item) => item.trend);
      if (allTrends?.length > 0) {
        const datesArr = [];
        allTrends.forEach((trend) => {
          trend.forEach((item) => {
            datesArr.push(item.date);
          });
        });
        uniqueAllDates = [...new Set(datesArr.flat())];
      }
      uniqueAllDates.sort((a, b) => {
        return new Date(a) - new Date(b);
      });
    }
  }
  const fillZeros = (trend) => {
    const trendData = uniqueAllDates.map((date) => {
      const item = trend.find((item) => item.date === date);
      return item ? item.trend : 0;
    });
    return trendData;
  };
  if (DashboardReducer.failureMsg === 1) {
    let message = "";
    switch (DashboardReducer.dashboard?.PlatformId) {
      case 1: //Twitter
        message = t("notifications.65");
        break;
      case 2: //Google Play
        message = t("notifications.66");
        break;
      case 3: // Customer Data
        message = t("notifications.67");
        break;
      case 4: // App Store
        message = t("notifications.66");
        break;
      case 5: // Şikayet var
        message = t("notifications.66");
        break;
      case 6: // Instagram
        message = t("notifications.68");
        break;
      case 7: // Zendesk Tickets
        message = t("notifications.69");
        break;
      case 8: // Zendesk LiveChat
        message = t("notifications.70");
        break;
      default:
        message = t("notifications.71");
    }
    return (
      <BoardError failureMsg={message} title={t("notifications.72")}>
        <div
          className={`${styles.navBtn}`}
          onClick={() =>
            history.push(
              `${shared ? "/share/" : "/console/"}DashboardData/${
                currentDashboardHash
                  ? currentDashboardHash
                  : `${initialState.currentDashboard}`
              }`
            )
          }
        >
          <Button>{t("selected_dashboard_page.67")}</Button>
        </div>
      </BoardError>
    );
  }

  if (DashboardReducer.failureMsg) {
    return (
      <BoardError failureMsg={DashboardReducer.failureMsg}>
        <div
          className={`${styles.navBtn}`}
          onClick={() =>
            history.push(
              `${shared ? "/share/" : "/console/"}DashboardData/${
                currentDashboardHash
                  ? currentDashboardHash
                  : `${initialState.currentDashboard}`
              }`
            )
          }
        >
          <Button>{t("selected_dashboard_page.67")}</Button>
        </div>
      </BoardError>
    );
  }
  const toggleZoom = () => {
    let opt = { ...bubbleOptions };
    opt.plugins.zoom.zoom.enabled = !bubbleOptions.plugins.zoom.zoom.enabled;
    opt.plugins.zoom.pan.enabled = !bubbleOptions.plugins.zoom.pan.enabled;
    if (
      !opt.plugins.zoom.zoom.enabled &&
      !opt.plugins.zoom.pan.enabled &&
      bubbleChart.current?.chartInstance
    ) {
      bubbleChart.current.chartInstance.resetZoom();
    }
    setBubbleOptions(opt);
  };

  const downloadUnmatched = async (key) => {
    setDownloadUnmatchedReviews(true);
    notification.success({
      message: (
        <span>
          {t("notifications.47")}.
          <br />
          Go to{" "}
          <a
            onClick={() => {
              history.push({
                pathname: `/console/report`,
              });
            }}
          >
            downloads page
          </a>
        </span>
      ),
    });
    const payload = {
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
      type: key.key,
    };
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      level1Selection,
      level2Selection,
      sortByDate,
    } = queryFilters;

    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = params.get("pivots_operator");
    }
    if (level1Selection?.id && !level2Selection?.id) {
      payload.topic_id = level1Selection?.id;
    } else if (level2Selection?.id) {
      payload.topic_id = level2Selection?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (sortByDate) payload.sortByDate = true;

    const result = await apiRequest(
      "dashboards/v2/custom_topics/download_unmatched",
      "POST",
      payload,
      true
    );
    setDownloadUnmatchedReviews(false);
  };
  const downloadStats = async (item) => {
    notification.success({
      message: (
        <span>
          {t("notifications.47")}.
          <br />
          Go to{" "}
          <a
            onClick={() => {
              history.push({
                pathname: `/console/report`,
              });
            }}
          >
            downloads page
          </a>
        </span>
      ),
    });
    setDownloadMonthlyStats(true);
    const payload = {
      dashboard_id: String(parseInt(DashboardReducer.dashboard?.ID, 10)),
    };
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      level1Selection,
      level2Selection,
      sortByDate,
    } = queryFilters;

    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = params.get("pivots_operator");
    }
    if (level1Selection?.id && !level2Selection?.id) {
      payload.topic_id = level1Selection?.id;
    } else if (level2Selection?.id) {
      payload.topic_id = level2Selection?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (sortByDate) payload.sortByDate = true;
    payload.period = item.key === "5" ? "daily" : "monthly";
    const result = await apiRequest(
      "dashboards/v2/custom_topics/download_volumetric_info",
      "POST",
      payload,
      true
    );
    setDownloadMonthlyStats(false);
  };
  const imgRef1 = useRef({});
  const imgRef2 = useRef({});
  const imgRef3 = useRef({});
  const imgRef4 = useRef({});
  const imgRef5 = useRef({});
  const menu = (
    <Menu className={styles.customMenu} theme="dark">
      <Menu.ItemGroup
        key="g1"
        title={
          <div className={styles.customMenuGroup}>
            <img
              src={
                theme === "dark"
                  ? "/assets/download.svg"
                  : "/assets/download_black.svg"
              }
              alt=""
              width={20}
            />{" "}
            Unmatched reviews
          </div>
        }
      >
        <Menu.Item
          key={1}
          onMouseEnter={(e) => {
            if (imgRef1.current) {
              imgRef1.current.src = "/assets/not_related_topic.svg";
            }
          }}
          onMouseLeave={(e) => {
            if (imgRef1.current) {
              imgRef1.current.src =
                theme === "dark"
                  ? "/assets/not_related_topic.svg"
                  : "/assets/not_related_topic_black.svg";
            }
          }}
          onClick={!downloadUnmatchedReviews && downloadUnmatched}
        >
          <img
            ref={imgRef1}
            src={
              theme === "dark"
                ? "/assets/not_related_topic.svg"
                : "/assets/not_related_topic_black.svg"
            }
            alt=""
          />
          Not related to any custom topic
        </Menu.Item>
        <Menu.Item
          key={2}
          onClick={!downloadUnmatchedReviews && downloadUnmatched}
          onMouseEnter={(e) => {
            if (imgRef2.current) {
              imgRef2.current.src = "/assets/not_related_ai_topic.svg";
            }
          }}
          onMouseLeave={(e) => {
            if (imgRef2.current) {
              imgRef2.current.src =
                theme === "dark"
                  ? "/assets/not_related_ai_topic.svg"
                  : "/assets/not_related_ai_topic_black.svg";
            }
          }}
        >
          <img
            ref={imgRef2}
            src={
              theme === "dark"
                ? "/assets/not_related_ai_topic.svg"
                : "/assets/not_related_ai_topic_black.svg"
            }
            alt=""
          />
          Not related to any AI topics
        </Menu.Item>
        <Menu.Item
          key={3}
          onClick={!downloadUnmatchedReviews && downloadUnmatched}
          onMouseEnter={(e) => {
            if (imgRef3.current) {
              imgRef3.current.src = "/assets/not_related_any_topic.svg";
            }
          }}
          onMouseLeave={(e) => {
            if (imgRef3.current) {
              imgRef3.current.src =
                theme === "dark"
                  ? "/assets/not_related_any_topic.svg"
                  : "/assets/not_related_any_topic_black.svg";
            }
          }}
        >
          <img
            ref={imgRef3}
            src={
              theme === "dark"
                ? "/assets/not_related_any_topic.svg"
                : "/assets/not_related_any_topic_black.svg"
            }
            alt=""
          />
          Not related to any topics
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Item
        key={4}
        onMouseEnter={(e) => {
          if (imgRef4.current) {
            imgRef4.current.src = "/assets/download.svg";
          }
        }}
        onMouseLeave={(e) => {
          if (imgRef4.current) {
            imgRef4.current.src =
              theme === "dark"
                ? "/assets/download.svg"
                : "/assets/download_black.svg";
          }
        }}
        onClick={!downloadMonthlyStats && downloadStats}
      >
        <img
          ref={imgRef4}
          src={
            theme === "dark"
              ? "/assets/download.svg"
              : "/assets/download_black.svg"
          }
          alt=""
          width={20}
        />
        {t("selected_dashboard_page.69")}
      </Menu.Item>
      <Menu.Item
        key={5}
        onMouseEnter={(e) => {
          if (imgRef5.current) {
            imgRef5.current.src = "/assets/download.svg";
          }
        }}
        onMouseLeave={(e) => {
          if (imgRef5.current) {
            imgRef5.current.src =
              theme === "dark"
                ? "/assets/download.svg"
                : "/assets/download_black.svg";
          }
        }}
        onClick={!downloadMonthlyStats && downloadStats}
      >
        <img
          ref={imgRef5}
          src={
            theme === "dark"
              ? "/assets/download.svg"
              : "/assets/download_black.svg"
          }
          alt=""
          width={20}
        />
        {t("selected_dashboard_page.83")}
      </Menu.Item>
    </Menu>
  );

  const createCustomTopic = async () => {
    if (!customTopicState.topic_label) {
      notification.error({ message: t("notifications.74") });
      return;
    }
    if (customTopicState.all_keywords.length === 0) {
      notification.error({ message: t("notifications.75") });
      return;
    }
    let url = "dashboards/add/custom_topic";
    let payload = {
      ...customTopicState,
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    const result = await apiRequest(url, "POST", payload, true);

    if (!result.error) {
      mixpanel.track("CREATE_CUSTOM_TOPIC");
      notification.success({
        message: t("notifications.76"),
      });
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          loadTrends: true,
        },
      });
    } else {
      notification.error({
        message: result.error,
      });
    }
    setCreateCustomTopicModal(false);
    setReset(true);
  };

  const handleZoomInOut = (zoom) => {
    if (bubbleChart.current?.chartInstance) {
      let ticksX =
        bubbleChart.current.chartInstance.options.scales.xAxes[0].ticks;
      let ticksY =
        bubbleChart.current.chartInstance.options.scales.yAxes[0].ticks;

      var newMin = ticksX.min + zoomLevel;
      var newMax = ticksX.max - zoomLevel;

      ticksX.min = parseInt(newMin, 10);
      ticksX.max = parseInt(newMax, 10);
      ticksY.min = parseInt(newMin, 10);
      ticksY.max = parseInt(newMax, 10);
      bubbleChart.current.chartInstance.update();
    }
  };

  const resetZoom = () => {
    let ticksX =
      bubbleChart.current.chartInstance.options.scales.xAxes[0].ticks;
    let ticksY =
      bubbleChart.current.chartInstance.options.scales.yAxes[0].ticks;
    ticksX.min = -300;
    ticksX.max = 300;
    ticksY.min = -300;
    ticksY.max = 300;
    bubbleChart.current.chartInstance.update();
    setZoomLevel(0);

    let opt = { ...bubbleOptions };
    opt.plugins.zoom.zoom.enabled = false;
    opt.plugins.zoom.pan.enabled = false;
    setBubbleOptions(opt);
  };

  const handleZoomState = (value) => {
    if (
      (zoomLevel === 0 && value === -25) ||
      (zoomLevel === 275 && value === 25)
    ) {
      return;
    } else {
      let opt = { ...bubbleOptions };
      opt.plugins.zoom.zoom.enabled = true;
      opt.plugins.zoom.pan.enabled = true;

      setBubbleOptions(opt);
      setZoomLevel((prev) => prev + value);
    }
  };

  // if (DashboardReducer.loading) return <Loading />;
  const handleCloseExtraContent = (e, chosen) => {
    e.preventDefault();
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        [chosen]: "",
      },
    });
  };

  const clearDateFilter = (chosen) => {
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        [chosen]: "",
      },
    });
  };
  return (
    <div className={styles.mainWindow}>
      {shared && (
        <Guide
          topicSelected={!localState.infoLoading && selectedTopicData !== null}
          chosenTopics={shareableState.chosenTopics.length}
          allowed={shareableState.allowedVisitor}
          selectQuickNav={selectQuickNav}
          openModal={() =>
            setShareableState({ ...shareableState, mailChimpModal: true })
          }
          blur={shareableState.blur}
          authenticated={authenticated}
        />
      )}
      <DashboardHeader
        shared={shared}
        currentDashboardHash={currentDashboardHash}
        topicsType={topicsType}
        user={user}
        blur={shareableState.blur}
        openModal={() =>
          setShareableState({ ...shareableState, mailChimpModal: true })
        }
        disableDateRange={disableDateRange}
        setChangeDateRange={setChangeDateRange}
        setShowDateFilter={setShowDateFilter}
      />

      {!shared && <NestedTabs />}
      {params.get("since") && params.get("until") && showDateFilter && (
        <div className={styles.dateFilterTag}>
          <Tag
            closable={!topicsLoading || !disableDateRange}
            onClose={() => {
              setShowDateFilter(false);
              setCloseDateFilter(true);
            }}
          >
            <span className={styles.roundIcon}>
              <img src={"/assets/calendar_filter.svg"} alt="" />
            </span>
            from {params.get("since")} to {params.get("until")}
          </Tag>
        </div>
      )}
      {topicsType === "custom" && 1 === 2 && (
        <div className={styles.customFirstRow}>
          <div className={styles.toggleListCard}>
            <div
              className={styles.toggleIcon}
              onClick={() => {
                [
                  "sentiment",
                  "intent",
                  "rating",
                  "top_topics",
                  "topics_operator",
                  "platform_id",
                  "match_date",
                  "hotterm",
                  "nps",
                  "keyword",
                  "pivotFilter",
                  "pivots_operator",
                  "level1Selection",
                  "level2Selection",
                  "level1OfSelected",
                  "level2OfSelected",
                  "selected_topic",
                  "active_topic_data",
                ].map((item) => params.delete(item));
                updateQParams("view_type", "trends");
                history.push({ search: params.toString() });
                setActiveTopic({ index: -1 });
                setSelectedTopicData();
                setToggleListCard("card");
              }}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                style={{
                  backgroundColor:
                    toggleListCard === "card"
                      ? theme === "brand"
                        ? "#4a4d4e"
                        : "#4400AA"
                      : "transparent",
                }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 29 12)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 29 24.5547)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 29 18.2812)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 23 12)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 23 24.5547)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 23 18.2812)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 17 12)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 17 24.5547)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="4"
                  height="3.44199"
                  transform="matrix(-1 0 0 1 17 18.2812)"
                  fill={
                    toggleListCard === "card"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
              </svg>
              <span className={styles.toolTipText1}>
                {t("selected_dashboard_page.29")}
              </span>
            </div>
            <div
              className={styles.toggleIcon}
              onClick={() => {
                [
                  "sentiment",
                  "intent",
                  "rating",
                  "top_topics",
                  "topics_operator",
                  "platform_id",
                  "match_date",
                  "hotterm",
                  "nps",
                  "keyword",
                  "pivotFilter",
                  "pivots_operator",
                  "level1Selection",
                  "level2Selection",
                  "level1OfSelected",
                  "level2OfSelected",
                  "selected_topic",
                  "active_topic_data",
                ].map((item) => params.delete(item));
                updateQParams("view_type", "list");
                history.push({ search: params.toString() });
                setActiveTopic({ index: -1 });
                setSelectedTopicData();
                setToggleListCard("list");
              }}
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="40"
                  height="40"
                  transform="matrix(-1 0 0 1 40 -0.203125)"
                  fill={
                    toggleListCard === "list"
                      ? theme === "brand"
                        ? "#4a4d4e"
                        : "#4400AA"
                      : ""
                  }
                />
                <rect
                  width="20"
                  height="2"
                  transform="matrix(-1 0 0 1 30 12.7969)"
                  fill={
                    toggleListCard === "list"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="20"
                  height="2"
                  transform="matrix(-1 0 0 1 30 20.7969)"
                  fill={
                    toggleListCard === "list"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="20"
                  height="2"
                  transform="matrix(-1 0 0 1 30 16.7969)"
                  fill={
                    toggleListCard === "list"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
                <rect
                  width="20"
                  height="2"
                  transform="matrix(-1 0 0 1 30 24.7969)"
                  fill={
                    toggleListCard === "list"
                      ? "#d9d9d9"
                      : theme === "dark"
                      ? "#d9d9d9"
                      : "#222222"
                  }
                />
              </svg>

              <span className={styles.toolTipText2}>
                {t("selected_dashboard_page.30")}
              </span>
            </div>
          </div>

          {!shared && showDownload && (
            <div className={styles.createToggleWrapper}>
              <div className={styles.shareBtn}>
                {/* <Button
                    icon={<i className="fa fa-plus" />}
                    onClick={() => setCreateCustomTopicModal(true)}
                  >
                    {t("button.27")}
                  </Button> */}
                <Dropdown overlay={menu}>
                  <Button style={{ width: "50px", marginLeft: "10px" }}>
                    <i className="fa fa-download" />
                  </Button>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
      )}
      <div className={styles.DashboardLandscape} id="Dashboard_wrapper">
        {toggleListCard === "list" && topicsType === "custom" ? (
          <BoardCard
            title="Dashboard Landscape"
            selection={selectedTopic.label ? selectedTopic.label : ""}
            unselect={closeSelectedTopic}
            extra={
              <Dropdown overlay={menu}>
                <div className={styles.downloadBtn}>
                  <i
                    className="fa fa-download"
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </div>
              </Dropdown>
            }
          >
            {!DashboardReducer.loading && (
              <CustomSummary
                DashboardId={localState.currentDashboard}
                setActiveTopic={(topic) => {
                  setActiveTopic(topic);
                }}
                toggleListCard={toggleListCard}
                getSelectedTopicData={getSelectedTopicData}
                selectedTopic={selectedTopic}
                closeSelectedTopic={closeSelectedTopic}
                changeDateRange={changeDateRange}
                topicChanged={topicChanged}
                setTopicChanged={setTopicChanged}
                setShowDownload={setShowDownload}
                summaryItems={summaryItems}
                setSummaryitems={setSummaryitems}
                tempSummaryItems={tempSummaryItems}
                setTempSummaryItems={setTempSummaryItems}
                loading={summaryLoading}
                setLoading={setSummaryLoading}
                fillZeros={fillZeros}
                uniqueAllDates={uniqueAllDates}
                dashboardLoaded={dashboardLoaded}
              />
            )}
          </BoardCard>
        ) : (
          <BoardCard
            title="Dashboard Landscape"
            selection={selectedTopic.label ? selectedTopic.label : ""}
            unselect={closeSelectedTopic}
            type={
              topicsType === "ai" && localState.isBubbleView
                ? "ai-bubble-view"
                : topicsType === "ai" && !localState.isBubbleView
                ? "ai-trend-view"
                : undefined
            }
            theme={theme}
            extra={
              topicsType === "ai" && (
                <div className={styles.bubbleExtraContent}>
                  <div className={styles.aiViewSwitch}>
                    <Button
                      id="viewbutton"
                      type="primary"
                      className={
                        localState.isBubbleView
                          ? styles.aiActiveButton
                          : undefined
                      }
                      onClick={() => {
                        [
                          "sentiment",
                          "intent",
                          "rating",
                          "top_topics",
                          "topics_operator",
                          "platform_id",
                          "match_date",
                          "hotterm",
                          "nps",
                          "keyword",
                          "pivotFilter",
                          "pivots_operator",
                          "level1Selection",
                          "level2Selection",
                          "level1OfSelected",
                          "level2OfSelected",
                          "selected_topic",
                          "active_topic_data",
                        ].map((item) => params.delete(item));
                        history.push({ search: params.toString() });
                        updateQParams("view_type", "bubble");
                        setState({
                          ...localState,
                          isBubbleView: true,
                        });
                      }}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/bubble_chart.svg"
                            : "/assets/bubble_chart_black.svg"
                        }
                        alt=""
                      />
                    </Button>
                    <Button
                      id="viewbutton"
                      type="primary"
                      className={
                        !localState.isBubbleView
                          ? styles.aiActiveButton
                          : undefined
                      }
                      onClick={() => {
                        [
                          "sentiment",
                          "intent",
                          "rating",
                          "top_topics",
                          "topics_operator",
                          "platform_id",
                          "match_date",
                          "hotterm",
                          "nps",
                          "keyword",
                          "pivotFilter",
                          "pivots_operator",
                          "level1Selection",
                          "level2Selection",
                          "level1OfSelected",
                          "level2OfSelected",
                          "selected_topic",
                          "active_topic_data",
                        ].map((item) => params.delete(item));
                        history.push({ search: params.toString() });
                        updateQParams("view_type", "trends");
                        setState({
                          ...localState,
                          isBubbleView: false,
                        });
                      }}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/trending_up.svg"
                            : "/assets/trending_up_black.svg"
                        }
                        alt=""
                      />
                    </Button>
                  </div>
                  <Dropdown
                    overlay={quickNavMenu}
                    style={{ zIndex: 50 }}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <Button
                      id="quicknav"
                      type="primary"
                      className={`${styles.aiButton} ${styles.aiButton_search}`}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/ai_quick_nav.svg"
                            : "/assets/ai_quick_nav_black.svg"
                        }
                        alt=""
                      />
                    </Button>
                  </Dropdown>
                </div>
              )
            }
          >
            <Spin spinning={localState.infoLoading || DashboardReducer.loading}>
              {localState.isBubbleView &&
              topicsType === "ai" &&
              !localState.infoLoading &&
              !topicsLoading ? (
                ((DashboardReducer.topics || {}).datasets || []).length ===
                0 ? (
                  <h4 className={styles.noTrends}>{t("notifications.77")}</h4>
                ) : DashboardReducer.topics &&
                  selectedTopicData &&
                  selectedTopic &&
                  selectedTopic.index !== -1 &&
                  selectedTopic.id ? (
                  <div className={`${styles.bubbleWrapperGroup}`}>
                    {selectedTopicData &&
                      selectedTopic &&
                      selectedTopic.index !== -1 &&
                      selectedTopic.id && (
                        <div className={styles.bubbleDetails}>
                          <div
                            className={styles.bubble}
                            style={
                              selectedTopic && selectedTopic.backgroundColor
                                ? {
                                    backgroundColor:
                                      selectedTopic.backgroundColor,
                                  }
                                : {}
                            }
                          >
                            {selectedTopic.label}
                          </div>
                          <div className={styles.content}>
                            <div className={styles.header}>
                              <div className={styles.title}>Details</div>
                              <div className={styles.subtitle}>
                                <div className={styles.color}></div>
                                {selectedTopic.label}
                              </div>
                            </div>
                            <div className={styles.header}>
                              <div className={styles.title}>KEYWORDS</div>
                              <div className={styles.subtitle}>
                                {selectedTopicData &&
                                selectedTopicData?.TopWords &&
                                selectedTopicData.TopWords.length
                                  ? selectedTopicData.TopWords.join(", ")
                                  : ""}
                              </div>
                            </div>
                            <Button
                              className={styles.selectedTopicCard}
                              onClick={closeSelectedTopic}
                            >
                              {selectedTopic.label} <CloseOutlined />
                            </Button>
                          </div>
                        </div>
                      )}
                    <Bubble
                      ref={bubbleChart}
                      data={DashboardReducer.topics}
                      options={{
                        ...bubbleOptions,
                        onClick: handleBubbleClick,
                        scales: {
                          xAxes: [
                            {
                              display: false,
                              ticks: {
                                display: false,
                                min:
                                  xValues &&
                                  Math.round(Math.min(...filteredValues.x)) >
                                    -100
                                    ? Math.round(
                                        Math.min(...filteredValues.x) - 10
                                      )
                                    : -200,
                                max:
                                  xValues &&
                                  Math.round(Math.max(...filteredValues.x)) <
                                    100
                                    ? Math.round(
                                        Math.max(...filteredValues.x) + 10
                                      )
                                    : 200,
                              },
                            },
                          ],
                          yAxes: [
                            {
                              display: false,
                              ticks: {
                                display: false,
                                max:
                                  yValues &&
                                  Math.round(Math.max(...filteredValues.y)) <
                                    100
                                    ? Math.round(
                                        Math.max(...filteredValues.y) + 10
                                      )
                                    : 200,
                                min:
                                  yValues &&
                                  Math.round(Math.min(...filteredValues.y)) >
                                    -100
                                    ? Math.round(
                                        Math.min(...filteredValues.y) - 10
                                      )
                                    : -200,
                              },
                            },
                          ],
                        },
                      }}
                      plugins={(ChartDataLabels, zoom)}
                    />
                    {!shared && (
                      <div className={styles.bottomRow}>
                        {localState.isBubbleView && (
                          <div className={styles.zoomInfoBox}>
                            <img
                              onClick={resetZoom}
                              src={
                                theme === "dark"
                                  ? "/assets/refresh.svg"
                                  : "/assets/refresh_black.svg"
                              }
                              alt=""
                            />
                            <i
                              className="fa-solid fa-minus"
                              onClick={() => handleZoomState(-25)}
                            ></i>
                            <span className={styles.zoomPercent}>
                              {100 + zoomLevel}
                            </span>
                            <i
                              className="fa-solid fa-plus"
                              onClick={() => handleZoomState(25)}
                            ></i>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={`${styles.bubbleWrapper}`}>
                    {selectedTopicData &&
                      selectedTopic &&
                      selectedTopic.index !== -1 &&
                      selectedTopic.id && (
                        <div className={styles.bubbleDetails}>
                          <div className={styles.bubble}>
                            {selectedTopic.label}
                          </div>
                          <div className={styles.content}>
                            <div className={styles.header}>
                              <div className={styles.title}>Details</div>
                              <div className={styles.subtitle}>
                                <div className={styles.color}></div>
                                {selectedTopic.label}
                              </div>
                            </div>
                            <div className={styles.header}>
                              <div className={styles.title}>KEYWORDS</div>
                              <div className={styles.subtitle}>
                                {selectedTopicData &&
                                selectedTopicData?.TopWords &&
                                selectedTopicData.TopWords.length
                                  ? selectedTopicData.TopWords.join(", ")
                                  : ""}
                              </div>
                            </div>
                            <Button
                              className={styles.selectedTopicCard}
                              onClick={closeSelectedTopic}
                            >
                              {selectedTopic.label} <CloseOutlined />
                            </Button>
                          </div>
                        </div>
                      )}
                    <Bubble
                      ref={bubbleChart}
                      data={DashboardReducer.topics}
                      options={{
                        ...bubbleOptions,
                        onClick: handleBubbleClick,
                        scales: {
                          xAxes: [
                            {
                              display: false,
                              ticks: {
                                display: false,
                                min:
                                  xValues &&
                                  Math.round(Math.min(...filteredValues.x)) >
                                    -100
                                    ? Math.round(
                                        Math.min(...filteredValues.x) - 20
                                      )
                                    : -200,
                                max:
                                  xValues &&
                                  Math.round(Math.max(...filteredValues.x)) <
                                    100
                                    ? Math.round(
                                        Math.max(...filteredValues.x) + 20
                                      )
                                    : 200,
                              },
                            },
                          ],
                          yAxes: [
                            {
                              display: false,
                              ticks: {
                                display: false,
                                max:
                                  yValues &&
                                  Math.round(Math.max(...filteredValues.y)) <
                                    100
                                    ? Math.round(
                                        Math.max(...filteredValues.y) + 20
                                      )
                                    : 200,
                                min:
                                  yValues &&
                                  Math.round(Math.min(...filteredValues.y)) >
                                    -100
                                    ? Math.round(
                                        Math.min(...filteredValues.y) - 20
                                      )
                                    : -200,
                              },
                            },
                          ],
                        },
                      }}
                      plugins={(ChartDataLabels, zoom)}
                    />
                    {!shared && (
                      <div className={styles.bottomRow}>
                        {localState.isBubbleView && (
                          <div className={styles.zoomInfoBox}>
                            <img
                              onClick={resetZoom}
                              src={
                                theme === "dark"
                                  ? "/assets/refresh.svg"
                                  : "/assets/refresh_black.svg"
                              }
                              alt=""
                            />
                            <i
                              className="fa-solid fa-minus"
                              onClick={() => handleZoomState(-25)}
                            ></i>
                            <span className={styles.zoomPercent}>
                              {100 + zoomLevel}
                            </span>
                            <i
                              className="fa-solid fa-plus"
                              onClick={() => handleZoomState(25)}
                            ></i>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
              ) : (
                !DashboardReducer.loading && (
                  <TrendsView
                    DashboardId={localState.currentDashboard}
                    getSelectedTopicData={getSelectedTopicData}
                    selectedTopicData={selectedTopicData}
                    selectedTopic={selectedTopic}
                    closeSelectedTopic={closeSelectedTopic}
                    topicsType={topicsType}
                    isBubbleView={localState.isBubbleView}
                    setActiveTopic={(topic) => {
                      setActiveTopic(topic);
                    }}
                    setDisableDateRange={setDisableDateRange}
                    setSelectedTopicData={setSelectedTopicData}
                    changeDateRange={changeDateRange}
                    topicChanged={topicChanged}
                    setTopicChanged={setTopicChanged}
                    setShowDownload={setShowDownload}
                    trends={trends}
                    setTrends={setTrends}
                    tempTrends={tempTrends}
                    setTempTrends={setTempTrends}
                    loading={trendsLoading}
                    setLoading={setTrendsLoading}
                  />
                )
              )}
            </Spin>
          </BoardCard>
        )}
      </div>
      {topicsType !== "custom" ? (
        <>
          {selectedTopicData && queryFilters?.selected_topic?.id && (
            <div
              id="SelectedTopicInfo"
              className={
                (shareableState.blur || localState.blur) && styles.blur
              }
              onClick={() =>
                shareableState.blur &&
                setShareableState({ ...shareableState, mailChimpModal: true })
              }
            >
              {!localState.infoLoading &&
                !topicsLoading &&
                !localState.loading && (
                  <SelectedTopicInfo
                    shared={shared}
                    selectedTopicData={selectedTopicData}
                    setSelectedTopic={setSelectedTopic}
                    selectedTopic={selectedTopic}
                    closeSelectedTopic={closeSelectedTopic}
                    user={user}
                    topicsType={topicsType}
                    toggleListCard={toggleListCard}
                    activeTopic={activeTopic}
                    DashboardId={localState.currentDashboard}
                    currentDashboardHash={currentDashboardHash}
                    setDisableDateRange={setDisableDateRange}
                    topicChanged={topicChanged}
                    setTopicChanged={setTopicChanged}
                    uniqueAllDates={uniqueAllDates}
                    fillZeros={fillZeros}
                  />
                )}
            </div>
          )}
        </>
      ) : (
        topicsType === "custom" && (
          <>
            {selectedTopicData && (
              <div
                id="SelectedTopicInfo"
                className={
                  (shareableState.blur || localState.blur) && styles.blur
                }
                onClick={() =>
                  shareableState.blur &&
                  setShareableState({ ...shareableState, mailChimpModal: true })
                }
              >
                {!localState.infoLoading &&
                  !summaryLoading &&
                  !trendsLoading && (
                    <SelectedTopicInfo
                      shared={shared}
                      selectedTopicData={selectedTopicData}
                      setSelectedTopicData={setSelectedTopicData}
                      selectedTopic={selectedTopic}
                      setSelectedTopic={setSelectedTopic}
                      closeSelectedTopic={closeSelectedTopic}
                      user={user}
                      activeTopic={activeTopic}
                      topicsType={topicsType}
                      toggleListCard={toggleListCard}
                      DashboardId={localState.currentDashboard}
                      currentDashboardHash={currentDashboardHash}
                      topicChanged={topicChanged}
                      setTopicChanged={setTopicChanged}
                      allItems={
                        params.get("view_type") === "trends"
                          ? trends && trends.length > 0
                            ? trends
                            : []
                          : summaryItems && summaryItems.length > 0
                          ? summaryItems
                          : []
                      }
                      uniqueAllDates={uniqueAllDates}
                      fillZeros={fillZeros}
                    />
                  )}
              </div>
            )}
          </>
        )
      )}
      <Modal
        title={t("selected_dashboard_page.31")}
        visible={shareableState.mailChimpModal}
        onOk={() => handleAddVisitor()}
        onCancel={() =>
          setShareableState({ ...shareableState, mailChimpModal: false })
        }
        // keyboard={false}
        // maskClosable={false}
        // mask={false}
        // closable={false}
        okButtonProps={{ style: { borderRadius: 8 } }}
        cancelButtonProps={{ style: { borderRadius: 8 } }}
        footer={[
          shareableState.showGoToLogin && (
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                history.push("/login?redirectURL=" + document.location.href)
              }
            >
              {t("selected_dashboard_page.32")}
            </Button>
          ),
          <Button key="submit" type="primary" onClick={handleAddVisitor}>
            {t("selected_dashboard_page.33")}
          </Button>,
        ]}
      >
        <p>{t("selected_dashboard_page.34")}</p>
        <p>
          <Input id="visitorEmail" placeholder={t("settings.188")} />
        </p>

        <p>
          <input
            type="radio"
            name="terms"
            id="terms"
            onChange={(e) =>
              setShareableState({
                ...shareableState,
                checkTerms: e.target.checked,
              })
            }
            className={styles.double}
          />
          {t("selected_dashboard_page.35")}{" "}
          <a
            onClick={() =>
              setShareableState({
                ...shareableState,
                showMoreInfo: !shareableState.showMoreInfo,
              })
            }
          >
            {t("selected_dashboard_page.36")}
          </a>
        </p>
        {shareableState.showMoreInfo && (
          <div className="mailMoreInfo">
            <p style={{ color: "#fff", fontSize: "12px" }}>
              {t("selected_dashboard_page.37")}
            </p>
            <p style={{ color: "#fff", fontSize: "12px" }}>
              {t("selected_dashboard_page.38")}
            </p>
            <p style={{ color: "#fff", fontSize: "12px" }}>
              {t("selected_dashboard_page.39")}
            </p>
          </div>
        )}
      </Modal>
      <Modal
        title={t("selected_dashboard_page.40")}
        visible={searchByKeywordModal}
        forceRender
        onOk={() => selectMostBubble("searchByKeyword")}
        onCancel={() => setSearchByKeywordModal(false)}
        okButtonProps={{ style: { borderRadius: 8 } }}
        cancelButtonProps={{ style: { borderRadius: 8 } }}
      >
        {searchByKeywordModal && (
          <Input
            id="searchByKeywordInput"
            placeholder={t("selected_dashboard_page.41")}
          />
        )}
        <p style={{ marginTop: "20px" }}>{t("selected_dashboard_page.42")}</p>
      </Modal>
      <Modal
        title={t("selected_dashboard_page.43")}
        visible={searchTopicModal}
        onOk={() => selectMostBubble("search")}
        onCancel={() => setSearchTopicModal(false)}
        okButtonProps={{ style: { borderRadius: 8 } }}
        cancelButtonProps={{ style: { borderRadius: 8 } }}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder={t("selected_dashboard_page.44")}
          optionFilterProp="children"
          onChange={setSearchTopicWord}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {DashboardReducer.topics?.datasets.map((item, i) => {
            return <Select.Option value={i}>{item.data[0].name}</Select.Option>;
          })}
        </Select>
      </Modal>

      <CustomModal
        showModal={createCustomTopicModal}
        onClose={() => {
          setCreateCustomTopicModal(false);
          setCustomTopicState({ top_words: [], semantic_enrich: false });
          setReset(true);
        }}
        onSave={createCustomTopic}
        showSaveButton={true}
        textonButton={t("button.15")}
        titleAsset=""
        widthofmodal="550px"
        title={t("selected_dashboard_page.45")}
      >
        <div className={styles.contain}>
          <CreationItem
            item={{
              key: "topic_label",
              title: t("selected_dashboard_page.46"),
              subtitle: t("selected_dashboard_page.47"),
              valueType: "input",
              placeholder: t("selected_dashboard_page.48"),
              required: true,
            }}
            localState={customTopicState}
            setState={setCustomTopicState}
            defaultValue={customTopicState.topic_label}
          />
          <div className={styles.white}></div>
          {
            <>
              <CreationItem
                item={{
                  title: t("selected_dashboard_page.49"),
                  subtitle: t("selected_dashboard_page.50"),
                  valueType: "input",
                  key: "all_keywords",
                  placeholder: t("selected_dashboard_page.51"),
                  required: true,
                }}
                localState={customTopicState}
                setState={setCustomTopicState}
                defaultValue={customTopicState.all_keywords}
                reset={reset}
                setReset={setReset}
              />
            </>
          }
        </div>
      </CustomModal>
      {closeDateFilter && (
        <CustomModal
          showModal={closeDateFilter}
          onClose={() => {
            setCloseDateFilter(false);
            setShowDateFilter(true);
          }}
          hideCloseIcon={"true"}
          onSave={() => {
            setCloseDateFilter(false);
            params.delete("since");
            params.delete("until");
            params.delete("daysRange");
            params.append("daysRange", "allTime");
            history.push({ search: params.toString() });
            clearDateFilter("dateRange");
            clearDateFilter("selectedDaysRange");
          }}
          showSaveButton={true}
          showCancelButton={true}
          textonButton={"Yes, Proceed"}
          cancelButtonText={"No, Cancel"}
          titleAsset=""
          title={"Clear Date Filter?"}
          widthofmodal="460px"
        >
          <p>
            Removing the date filter might cause delays in page loading in the
            case of large dashboards.
          </p>
          <p>Do you want to continue?</p>
        </CustomModal>
      )}
    </div>
  );
};

export default DashboardWrapper;
