import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Modal } from "components/General/ModalFormComponents";
import GroupsSelection from "./GroupsSelection";
import DashboardsSelection from "./DashboardsSelection";
import CategoriesSelection from "./CategoriesSelection";
import styles from "./style.module.scss";
const Popup = ({
  open,
  onClose,
  data,
  onSave,
  dataType,
  setContinueButtonClicked,
  setSelectCategoriesButtonClicked,
  selectedGroups,
  setSelectedGroups,
  selectedDashboards,
  setSelectedDashboards,
  selectedCategories,
  setSelectedCategories,
  resetPopup,
}) => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 950);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);
  const handleContinue = () => {
    onClose();
    setContinueButtonClicked(true);
  };

  const handleSelectCategories = () => {
    onClose();
    setSelectCategoriesButtonClicked(true);
  };

  const handleGenerateChart = () => {
    onClose();
    onSave();
  };
  return (
    <Modal
      showModal={open}
      onClose={onClose}
      onSave={onSave}
      showSaveButton={false}
      showCancelButton={false}
      hideCloseIcon={true}
      titleAsset={""}
      widthofmodal={isMobileView ? "25rem" : ""}
      className={styles.PopupSequence}
      title={
        <div className={styles.popupHeading}>
          {dataType === "Groups" && (
            <>
              {isMobileView
                ? "Choose the group names"
                : "Choose the group names to compare to each other"}
            </>
          )}
          {dataType === "Dashboards" && (
            <>
              {isMobileView
                ? "Choose the dashboard names"
                : "Choose the dashboards from selected companies"}
            </>
          )}
          {dataType === "Categories" && (
            <>
              {isMobileView
                ? "Select top-level categories (Upto 5)"
                : "Select the top-level categories to compare (Upto 5)"}
            </>
          )}
        </div>
      }
    >
      <div className={styles.Popup}>
        {dataType === "Dashboards" && (
          <DashboardsSelection
            dashboards={data}
            selectedDashboards={selectedDashboards}
            setSelectedDashboards={setSelectedDashboards}
            resetPopup={resetPopup}
          />
        )}
        {dataType === "Groups" && (
          <GroupsSelection
            groups={data}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            resetPopup={resetPopup}
          />
        )}
        {dataType === "Categories" && (
          <CategoriesSelection
            categories={data}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            resetPopup={resetPopup}
          />
        )}
      </div>
      <div className={styles.footer}>
        <Button className={styles.footerBtn} onClick={onClose}>
          Cancel
        </Button>
        {dataType === "Groups" && (
          <Button
            className={styles.footerBtn}
            type="primary"
            onClick={handleContinue}
            disabled={selectedGroups?.length > 0 ? false : true}
          >
            Next: Select Dashboards
          </Button>
        )}
        {dataType === "Dashboards" && (
          <Button
            className={styles.footerBtn}
            type="primary"
            onClick={handleSelectCategories}
            disabled={selectedDashboards?.length > 0 ? false : true}
          >
            Next: Select Categories
          </Button>
        )}
        {dataType === "Categories" && (
          <Button
            className={styles.footerBtn}
            type="primary"
            onClick={handleGenerateChart}
            disabled={selectedCategories?.length > 0 ? false : true}
          >
            Generate Chart
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default Popup;
