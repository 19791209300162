import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { Select } from "antd";
import { useHistory } from "react-router-dom";
import { Spin, notification, Button, Input } from "antd";
import { apiRequest } from "util/services";
import { UNLOGGED } from "util/constants";
import AudienceItem from "components/Audience/AudienceItem";
import { getDateString, keyPressed } from "util/common";
import { Modal } from "components/General/ModalFormComponents";
import CustomInput from "components/General/CustomInput";
import { Editor } from "@tinymce/tinymce-react";
import { editorConfigurations } from "pages/EmailMarketing/editorConfig";
import { useTranslation } from "react-i18next";

const Engage = ({ authenticated, user, theme }) => {
  const [switchTab, setSwitchTab] = useState(
    window.location.pathname.includes("/emailMarketing")
      ? "emailMarketing"
      : "audience"
  );
  const [active, setActive] = useState(true);
  const [showRowItems, setShowRowItems] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [audience, setAudience] = useState([]);
  const [filteredAudience, setFilteredAudience] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [inputAudience, setInputAudience] = useState("");
  const [loading2, setLoading2] = useState(true);
  const [sender, setSender] = useState("");
  const [subject, setSubject] = useState("");
  const [htmlBody, setHtmlBody] = useState("");
  const [textBody, setTextBody] = useState("");
  const [target, setTarget] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (authenticated === UNLOGGED) {
      history.push("/");
      return;
    }
    if (switchTab === "audience") {
      loadAudience();
    }
  }, []);

  useEffect(() => {
    if (switchTab === "audience") {
      filterAudience(audience);
    }
  }, [searchValue, active]);

  const loadAudience = async () => {
    const result = await apiRequest("audiences/all", "POST", {
      user_id: user.uid,
    });
    if (result) {
      setAudience(result);
      filterAudience(result);
    } else {
      notification.error({
        message: t("notifications.32"),
      });
    }
    setLoading(false);
  };

  const filterAudience = (audienceData) => {
    let data = audienceData.filter((item) => {
      if (!item.Name.toLowerCase().includes(searchValue.toLowerCase())) {
        return false;
      }
      if (!active && item.Status === "active") return false;
      if (active && item.Status !== "active") return false;
      return true;
    });
    setFilteredAudience(data);
  };

  const handleShowSearch = () => {
    if (window.innerWidth < 880) {
      setShowRowItems(false);
    }
    setShowSearchBox(!showSearchBox);
  };

  const audienceItems = filteredAudience?.map((item) => {
    return (
      <AudienceItem
        item={item}
        user={user}
        audience={audience}
        setAudience={setAudience}
        filterAudience={filterAudience}
      />
    );
  });

  const handleAddAudience = async () => {
    if (!inputAudience) {
      notification.error({
        message: t("notifications.33"),
      });
      return;
    }

    const result = await apiRequest(
      "audiences/create",
      "POST",
      {
        user_id: user.uid,
        name: inputAudience,
      },
      true
    );
    if (!result.error) {
      setAudience([
        ...audience,
        {
          Name: inputAudience,
          Size: 0,
          AddDate: getDateString(new Date()),
          Status: "active",
        },
      ]);
      filterAudience([
        ...audience,
        {
          Name: inputAudience,
          Size: 0,
          AddDate: getDateString(new Date()),
          Status: "active",
        },
      ]);
    } else {
      notification.error({
        message: result.error,
      });
    }
    setModalOpen(false);
  };

  const sendEmail = async () => {
    if (!sender || !target || !subject || !htmlBody) {
      notification.error({
        message: t("notifications.34"),
      });
      return;
    }
    const payload = {
      firebase_id: user.uid,
      sender_email: sender,
      audience_id: parseInt(target, 10),
      email_subject: subject,
      email_text: textBody,
      email_html: htmlBody,
    };
    const result = await apiRequest(
      "audiences/email/send",
      "POST",
      payload,
      true
    );
    if (!result.error) {
      notification.success({
        message: t("notifications.35"),
      });
    } else {
      notification.error({
        message: result.error,
      });
    }
  };

  const getIframe = () => {
    if (switchTab === "emailMarketing") {
      const getIframe = document.querySelector(".tox-edit-area__iframe")
        ?.contentWindow?.document.body;
      getIframe.style.color = theme === "dark" ? "white" : "black";
    }
  };

  return (
    <div className={styles.mainWindow}>
      <div className={styles.header}>
        <span className={styles.title}>{t("dashboard_sidebar.4")}</span>
      </div>
      <div className={styles.tabsContainer}>
        <div className={styles.topicsWrapper}>
          <span
            onClick={() => {
              setSwitchTab("audience");
              history.push("/console/engage/audience");
            }}
            className={switchTab === "audience" && styles.activeTopic}
          >
            {t("engage.1")}
          </span>
          <span
            onClick={() => {
              setSwitchTab("emailMarketing");
              history.push("/console/engage/emailMarketing");
            }}
            className={switchTab === "emailMarketing" && styles.activeTopic}
          >
            {t("engage.2")}
          </span>
        </div>

        {switchTab === "audience" && (
          <div className={styles.firstRow}>
            {showRowItems && (
              <>
                <Select
                  placeholder={t("topic_library.1")}
                  style={{ width: 150 }}
                  className={styles.languageDropdown}
                  onChange={(value) => setActive(value)}
                  value={active}
                >
                  <Select.Option value={true} key={true}>
                    {t("engage.3")}
                  </Select.Option>
                  <Select.Option value={false} key={false}>
                    {t("engage.4")}
                  </Select.Option>
                </Select>
                <div className={styles.actionBlock}>
                  <button className={styles.search} onClick={handleShowSearch}>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/search.svg"
                          : "/assets/search_black.svg"
                      }
                      alt=""
                    />
                  </button>
                  <button
                    className={styles.newAudience}
                    onClick={() => setModalOpen(true)}
                  >
                    {" "}
                    <i class="fa-solid fa-plus"></i>{" "}
                    <span>{t("button.37")}</span>
                  </button>
                  {window.innerWidth > 880 && (
                    <div
                      className={`${styles.searchBox} ${showSearchBox &&
                        styles.showSearch} `}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/search.svg"
                            : "/assets/search_black.svg"
                        }
                        alt=""
                      />
                      <input
                        type="text"
                        placeholder={t("placeholder.6")}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {!showRowItems && (
              <div
                className={`${styles.searchBoxSmall} ${showSearchBox &&
                  styles.showSearch} `}
              >
                <i
                  onClick={() => {
                    setShowRowItems(true);
                    setSearchValue("");
                  }}
                  className="fa-solid fa-arrow-left"
                ></i>
                <input
                  type="text"
                  placeholder={t("placeholder.6")}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            )}
          </div>
        )}

        {switchTab === "audience" ? (
          <Spin spinning={loading}>
            <div className={styles.content}>
              {audienceItems.length !== 0 && (
                <AudienceItem
                  user={user}
                  audience={audience}
                  setAudience={setAudience}
                  filterAudience={filterAudience}
                  item={{}}
                  header
                />
              )}
              {audienceItems.length === 0 && !loading ? (
                <div className={styles.noAudience}>
                  <p className={styles.heading}>
                    {t("empty_states.16")}{" "}
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/emptyStates/no_audience.svg"
                          : "/assets/emptyStates/no_audience_black.svg"
                      }
                      alt=""
                    />
                  </p>
                  <div className={styles.secondRow}>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/error_outline.svg"
                          : "/assets/error_outline_black.svg"
                      }
                      alt=""
                    />
                    {t("empty_states.17")}
                  </div>
                  <p className={styles.secondHeading}>
                    {t("empty_states.18")}{" "}
                    <img src={"/assets/double_down_arrow.svg"} alt="" />
                  </p>
                  <div className={styles.imgBlock}>
                    <div>
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/emptyStates/audience1.png"
                            : theme === "light"
                            ? "/assets/emptyStates/audience1_light.png"
                            : "/assets/emptyStates/audience1_brand.png"
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/emptyStates/audience2.png"
                            : theme === "light"
                            ? "/assets/emptyStates/audience2_light.png"
                            : "/assets/emptyStates/audience2_brand.png"
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/emptyStates/audience3.png"
                            : theme === "light"
                            ? "/assets/emptyStates/audience3_light.png"
                            : "/assets/emptyStates/audience3_brand.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ) : (
                audienceItems
              )}
            </div>
          </Spin>
        ) : (
          <Spin spinning={loading2}>
            <div className={styles.content2}>
              <div className={styles.row}>
                <CustomInput
                  label={t("engage.12")}
                  placeholder={t("placeholder.7")}
                  onChange={setSender}
                />
                <div className={`${styles.target}`}>
                  <h3>{t("engage.13")}: </h3>
                  <Select
                    placeholder={t("placeholder.8")}
                    onChange={(value) => setTarget(value)}
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    optionFilterProp="title"
                  >
                    <Select.Option key={"initial"} value="">
                      {t("placeholder.8")}
                    </Select.Option>
                    {audience.map((item) => {
                      return (
                        <option key={item.ID} value={item.ID} title={item.Name}>
                          {item.Name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className={styles.row}>
                <CustomInput
                  label={t("engage.14")}
                  placeholder={t("placeholder.9")}
                  onChange={setSubject}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.emailBody}>
                  <h3>{t("engage.15")}: </h3>
                  <div className={styles.bodyContent}>
                    <Editor
                      apiKey="iri3bbun68fbtokzf9f9fsabi5siq9yuml1b5atq31neulvc"
                      init={editorConfigurations}
                      onInit={() => {
                        setLoading2(false);
                        getIframe();
                      }}
                      onEditorChange={(content, editor) => {
                        setHtmlBody(content);
                        setTextBody(
                          editor.contentDocument.activeElement.innerText
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.btn}>
                <Button onClick={sendEmail}>{t("button.23")}</Button>
              </div>
            </div>
          </Spin>
        )}
      </div>
      <Modal
        showModal={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleAddAudience}
        showSaveButton={true}
        textonButton={t("button.15")}
        titleAsset=""
        title={t("engage.11")}
        widthofmodal="450px"
      >
        <Input
          style={{
            height: "35px",
            marginBottom: "7px",
            width: "300px",
            background: "transparent",
            color: theme === "dark" ? "white" : "black",
            borderColor: "#6f6c99",
            borderRadius: "10px",
          }}
          placeholder={t("placeholder.10")}
          onKeyPress={(e) => keyPressed(e, handleAddAudience)}
          onChange={(e) => setInputAudience(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default Engage;
