import React, { useState, useEffect } from 'react';
import { Table, Checkbox} from 'antd';

const CategoriesSelection = ({
  categories,
  selectedCategories,
  setSelectedCategories,
  resetPopup
}) => {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(selectedCategories?.length === categories?.length);
  }, [selectedCategories, categories]);

  useEffect(()=>{
    if(resetPopup){
        setSelectAll(false);
        setSelectedCategories([]);
    }
    else{
        setSelectAll(selectedCategories?.length===categories?.length)
    }
  },[resetPopup])

  useEffect(() => {
    setSelectAll(selectedCategories?.length === categories?.length || (selectedCategories?.length > 0 && selectedCategories?.length < categories?.length));
  }, [selectedCategories, categories]);

  const handleCategoriesSelection = (selectedCategory) => {
    const updatedSelection = [...selectedCategories];

    const categoryIndex = updatedSelection.findIndex(
      (category) => category?.categoryId === selectedCategory?.categoryId
    );

    if (categoryIndex === -1 && updatedSelection?.length<5) {
      updatedSelection.push(selectedCategory);
    } else if(categoryIndex!==-1) {
      updatedSelection.splice(categoryIndex, 1);
    } else if(updatedSelection?.length===5){
      updatedSelection.shift()
      updatedSelection.push(selectedCategory);
    }
    setSelectedCategories(updatedSelection);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const selectedCategories = newSelectAll ? categories.slice(0,5) : [];
    setSelectedCategories(selectedCategories);
  };

  const columns = [
    {
      title: (
        <Checkbox
          color="primary"
          checked={selectAll}
          indeterminate={(selectedCategories?.length > 0 && selectedCategories?.length < categories?.length)}
          onChange={handleSelectAll}
        />
      ),
      key: 'checkbox',
      render: (record) => (
        <Checkbox
          checked={Array.isArray(selectedCategories) &&selectedCategories.some(
            (category) => category?.categoryId === record?.categoryId
          )}
          onChange={() => handleCategoriesSelection(record)}
        />
      ),
    },
    {
      title: 'Category Name',
      dataIndex: 'name',
      key: 'name',
      align:'center'
    },
  ];

  const data = categories?.map((category, index) => ({
    categoryId:category.categoryId,
    name: category.name,
  }));

  return (
    <div>
      <Table columns={columns} dataSource={data} pagination={false}/>
    </div>
  );
}

export default CategoriesSelection