import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import LineChartWrapper from "../LineChartWrapper";
import { Modal } from "components/General/ModalFormComponents";
import CreationItem from "components/NewDashboard/CreationItem";
import { notification } from "antd";
import { useSelector } from "react-redux";
import { apiRequest } from "util/services";
import { useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
const TrendItem = ({
  trend,
  view,
  getSelectedTopicData,
  selectedTopic,
  closeSelectedTopic,
  topicsType,
  selected,
  max_y,
  trends,
  setActiveTopic,
  theme,
  getTopics,
  setSelectedTopicData,
  period,
  topicChanged,
  setTopicChanged,
}) => {
  useEffect(() => {
    if (selected) {
      selectTopic();
    }
  }, []);

  const [showMoreOption, setShowMoreOption] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [topicInPopups, setTopicInPopups] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [deleteTopicLoading, setDeleteTopicLoading] = useState(false);
  const [customTopicState, setCustomTopicState] = useState({
    topWords: null,
  });
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const moreOptionRef = useRef();
  const dispatch = useDispatch();
  const user = firebase.auth().currentUser;
  const { t } = useTranslation();
  const history = useHistory();
  const [queryFilters, setQueryFilters] = useState({});
  const { search } = useLocation();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected" ||
          key === "selected_topic" ||
          key === "active_topic_data"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);

  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };

  const selectTopic = () => {
    if (params.has("selected_topic")) setTopicChanged(true);
    let index = -1;
    if (!trends || trends.length === 0) {
      return;
    }
    trends.forEach((item, i) => {
      if (item.TopicId === trend.TopicId) {
        index = i;
      }
    });
    for (let l = 1; l >= 1; l++) {
      if (params.has(`level${l}Selection`)) {
        params.delete(`level${l}Selection`);
        history.push({ search: params.toString() });
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            [`selectedL${l}Topic`]: null,
          },
        });
      } else {
        break;
      }
    }
    [
      "top_topics",
      "topics_operator",
      "hotterm",
      "nps",
      "keyword",
      "pivotFilter",
      "pivots_operator",
      "level1OfSelected",
      "level2OfSelected",
    ].forEach((item) => {
      if (params.has(item)) {
        params.delete(item);
      }
    });
    history.push({ search: params.toString() });
    setActiveTopic(trend);
    updateQParams(
      "selected_topic",
      JSON.stringify({
        index,
        label: trend.Label,
        id: trend.TopicId,
      })
    );
  };

  const determinePeriod = (val) => {
    try {
      let identifier = (val.match(/-/g) || []).length;
      return identifier === 0
        ? "Yearly"
        : identifier === 1
        ? "Monthly"
        : "Daily";
    } catch (e) {
      console.log("Error", e);
      return "Monthly";
    }
  };

  const generateDateString = (date, period) => {
    let x;
    if (period === "Daily" || period === "Weekly") {
      x =
        date.getFullYear() +
        "-" +
        (String(date.getMonth() + 1).length === 1 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (String(date.getDate()).length === 1 ? "0" : "") +
        date.getDate();
    } else if (period === "Monthly") {
      x =
        date.getFullYear() +
        "-" +
        (String(date.getMonth() + 1).length === 1 ? "0" : "") +
        (date.getMonth() + 1);
    } else {
      x = String(date.getFullYear());
    }
    return x;
  };

  const DashboardId = DashboardReducer.dashboard?.ID;
  let volumeData = trends?.map((t) => t.VolumeDaily);
  let sentimentData = trends?.map((t) => t.SentimentDaily);
  const moreOptionOutClick = (e) => {
    if (moreOptionRef.current && !moreOptionRef.current.contains(e.target)) {
      setShowMoreOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", moreOptionOutClick, true);
    return () =>
      document.removeEventListener("click", moreOptionOutClick, true);
  }, []);

  const handleDeleteTopic = async () => {
    setDisabled(true);
    setDeleteTopicLoading(true);
    const payload = {
      topic_ids: [trend.TopicId],
      user_id: user.uid,
      all: false,
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    const result = await apiRequest(
      "dashboards/v2/delete/custom_topic",
      "POST",
      payload,
      true
    );
    if (result.error || result.cancel || result.validation_error) {
      notification.error({
        message: result?.error ? result.error : "Failed to delete topics",
      });
    } else {
      notification.success({
        message: t("notifications.85"),
      });
      setSelectedTopicData();
      getTopics();
    }
    setDeleteTopicLoading(false);
    setDeleteModalOpen(false);
    setTopicInPopups(null);
    setDisabled(false);
  };
  return (
    <div
      className={`${styles.itemMainWindow} ${topicsType === "ai" &&
        styles.customTopicBorder} ${trend.TopicId === selectedTopic?.id &&
        styles.activeItem} ${
        topicsType === "custom" ? styles.customTopicBorder : undefined
      }`}
      key={trend.TopicId}
    >
      <div className={styles.left}>
        <div className={styles.status}>
          {topicsType === "custom" &&
            (trend.status === 3 || trend.Posts === 0 || trend.status === 0) && (
              <img
                src={`/assets/status${
                  trend.status === 0
                    ? "0"
                    : trend.Posts === 0
                    ? "3"
                    : trend.status
                }.png`}
              />
            )}
          <h4
            onClick={() => {
              if (
                (topicsType === "custom" &&
                  trend.Posts > 0 &&
                  trend.status === 1) ||
                topicsType !== "custom"
              ) {
                [
                  "top_topics",
                  "topics_operator",
                  "hotterm",
                  "nps",
                  "keyword",
                  "pivotFilter",
                  "pivots_operator",
                  "level1OfSelected",
                  "level2OfSelected",
                ].forEach((item) => {
                  if (params.has(item)) {
                    params.delete(item);
                  }
                });
                history.push({ search: params.toString() });
                selectTopic();
              }
            }}
          >
            {trend.Label}
          </h4>
          <div className={styles.moreOptionIcon} ref={moreOptionRef}>
            {topicsType === "custom" && (
              <img
                src={
                  theme === "dark"
                    ? "/assets/more-vertical.svg"
                    : "/assets/more-vertical-black.svg"
                }
                alt=""
                onClick={() => {
                  setShowMoreOption(true);
                  setTopicInPopups(trend);
                }}
              />
            )}
            {showMoreOption && (
              <div className={styles.moreOptionCustomTopics}>
                <span
                  onClick={() => {
                    setShowMoreOption(false);
                    setDeleteModalOpen(true);
                  }}
                >
                  {t("button.2")}
                </span>
              </div>
            )}
          </div>
        </div>
        {topicsType === "ai" && (
          <div className={styles.topwords}>
            {trend?.TopWords?.map((topWord) => (
              <span>{topWord}</span>
            ))}
          </div>
        )}
      </div>
      {trend.VolumeDaily.length > 0 ? (
        <div className={styles.rightChart}>
          {view === 0 ? (
            <LineChartWrapper
              // TopicId={trend.TopicId}
              DashboardId={DashboardId}
              topicsType={topicsType}
              VolumeDaily={trend.VolumeDaily}
              data={volumeData}
              max_y={max_y}
              hideLegend
              period={period}
            />
          ) : (
            <LineChartWrapper
              // TopicId={trend.TopicId}
              DashboardId={DashboardId}
              topicsType={topicsType}
              SentimentDaily={trend.SentimentDaily}
              data={sentimentData}
              max_y={max_y}
              hideLegend
              period={period}
            />
          )}
        </div>
      ) : trend.Posts === 0 && trend.status === 1 ? (
        <div
          style={{
            color: "red",
          }}
        >
          {t("notifications.81")}
        </div>
      ) : trend.status === 0 ? (
        <div
          style={{
            color: "#F2A70C",
          }}
        >
          {t("selected_dashboard_page.53")}
        </div>
      ) : null}
      <Modal
        showModal={deleteModalOpen}
        onClose={() => {
          setTopicInPopups(null);
          setDeleteModalOpen(false);
        }}
        onSave={handleDeleteTopic}
        showSaveButton={true}
        showCancelButton={true}
        hideCloseIcon={true}
        textonButton={t("button.2")}
        titleAsset=""
        title={"Delete topic"}
        loadingSaveClick={deleteTopicLoading ? true : false}
        widthofmodal="450px"
        disabled={deleteTopicLoading ? true : false}
        isDeleteModal={true}
      >
        <p className={styles.deletetext}>
          {t("selected_dashboard_page.80")} <b>{topicInPopups?.Label}</b>{" "}
          {t("selected_dashboard_page.85")}
        </p>
      </Modal>
    </div>
  );
};

export default TrendItem;
