import { Select, Tooltip } from "antd";
import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import "./customSummary.scss";
import { apiRequest } from "util/services";
import {
  notification,
  Spin,
  Input,
  Dropdown,
  Button,
  Menu,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { Modal } from "components/General/ModalFormComponents";
import { Line } from "react-chartjs-2";
import { useHistory, useLocation } from "react-router-dom";
import { DashboardReducer } from "redux/Dashboard/reducer";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
const { Option } = Select;

const CustomSummary = ({
  DashboardId,
  getSelectedTopicData,
  selectedTopic,
  closeSelectedTopic,
  changeDateRange,
  setActiveTopic,
  toggleListCard,
  topicChanged,
  setTopicChanged,
  setShowDownload,
  summaryItems,
  setSummaryitems,
  tempSummaryItems,
  setTempSummaryItems,
  loading,
  setLoading,
  fillZeros,
  uniqueAllDates,
  dashboardLoaded,
}) => {
  const [showMoreOption, setShowMoreOption] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [changedSearchTerm, setChangedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortValue, setSortValue] = useState("volume-down");
  const [topicId, setTopicId] = useState("");
  const [selectedTopicIds, setSelectedTopicIds] = useState({});
  const [deletingTopic, setDeletingTopic] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState();
  const [selectionType, setSelectionType] = useState();
  const [totalTopics, setTotalTopics] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [newSearch, setNewSearch] = useState(false);
  const [topicName, setTopicName] = useState("");
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [topicInPopups, setTopicInPopups] = useState(null);
  const [queryFilters, setQueryFilters] = useState({});
  const { search } = useLocation();
  const [periodByDateRange, setPeriodByDateRange] = useState("daily");
  const params = new URLSearchParams(window.location.search);
  const [sortedTopicsList, setSortedTopicsList] = useState([]);
  const [tempSortedTopicsList, setTempSortedTopicsList] = useState([]);
  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected" ||
          key === "selected_topic" ||
          key === "active_topic_data"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);

  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    if (params.get("insightTopicSearch") && summaryItems.length > 0) {
      const selected_tpc = summaryItems.find(
        (item) =>
          item.topic_id === parseInt(params.get("insightTopicSearch"), 10)
      );
      if (selected_tpc) {
        selectTopic(selected_tpc);
      } else {
        notification.error({
          message: "Topic not found",
        });
        [
          "sentiment",
          "intent",
          "rating",
          "top_topics",
          "topics_operator",
          "platform_id",
          "match_date",
          "hotterm",
          "nps",
          "keyword",
          "pivotFilter",
          "pivots_operator",
          "level1Selection",
          "level2Selection",
          "level1OfSelected",
          "level2OfSelected",
          "insightTopicSearch",
        ].forEach((item) => params.delete(item));
        history.push({ search: params.toString() });
        return;
      }
    }
  }, [summaryItems, queryFilters.insightTopicSearch]);

  useEffect(() => {
    if (queryFilters?.insightTopicClosed === "true") {
      params.delete("insightTopicClosed");
      history.push({ search: params.toString() });
      getSortedTopicIds();
    }
  }, [queryFilters?.insightTopicClosed]);
  document.onclick = (e) => {
    if (
      !e.target.classList.contains("moreOptionIcon") &&
      !e.target.classList.contains("moreOptionSVG")
    )
      setShowMoreOption(false);
  };
  const [showFollowModal, setShowFollowModal] = useState(false);
  const SelectionTypeOptions = [
    {
      id: "deselect",
      name: "Deselect",
      value: "deselect",
    },
    {
      id: "visible",
      name: "Select Visible",
      value: "visible",
    },
    {
      id: "all",
      name: "Select All",
      value: "all",
    },
  ];
  const AddTopicForDeletion = (id) => {
    if (id == "deselect") {
      let topics = {};
      setSelectedTopicIds({ ...topics });
      UpdateSummary(Object.keys(topics));
    } else if (id == "all" || id == "visible") {
      let topics = {};
      tempSummaryItems.forEach((item) => {
        topics[item.topic_id] = item.topic_id;
      });
      setSelectedTopicIds({ ...topics });
      UpdateSummary(Object.keys(topics));
    } else if (selectedTopicIds[id]) {
      let topics = { ...selectedTopicIds };
      delete topics[id];
      setSelectedTopicIds({ ...topics });
      UpdateSummary(Object.keys(topics));
      setSelectionType("visible");
    } else {
      setSelectedTopicIds({ ...selectedTopicIds, [id]: id });
      UpdateSummary(Object.keys({ ...selectedTopicIds, [id]: id }));
      setSelectionType("visible");
    }
  };

  const UpdateSummary = (topics) => {
    if (topics.length == 1 && topics[0] === "all") return;
    let summary = [...tempSummaryItems];
    if (topics.length) {
      summary = summary.map((item) => {
        let newItem = item;
        if (topics.includes(String(item.topic_id))) {
          newItem["checked"] = true;
        } else {
          newItem["checked"] = false;
        }
        return newItem;
      });
    } else {
      summary = summary.map((item) => {
        let newItem = item;
        newItem["checked"] = false;
        return newItem;
      });
    }
    setTempSummaryItems(summary);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
          afterDataLimits: (axis) => {
            axis.max += axis.max > 20 ? 10 : 2;
          },
          maxTicksLimit: 3,
        },
      ],
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
        color: "transparent",
      },
    },
    tooltips: false,
    layout: {
      padding: {
        top: 5,
      },
    },
  };
  const handleDeleteTopic = async (multiple = false) => {
    setDeletingTopic(true);
    let ids = [];
    if (multiple) {
      ids = Object.keys(selectedTopicIds);
    } else {
      ids = [topicId];
    }
    const payload = {
      dashboard_id: parseInt(DashboardId, 10),
      all: selectionType === "all" ? true : false,
    };
    if (selectionType !== "all") {
      payload["topic_ids"] = ids;
    }
    const result = await apiRequest(
      "dashboards/v2/delete/custom_topic",
      "POST",
      payload,
      true
    );
    if (result.error) {
      notification.error({
        message: result.error,
      });
    } else {
      setTopicInPopups(null);
      setDeleteModalOpen(false);
      notification.success({
        message: "Custom topic deleted successfully",
      });
      //delete topic from summary items list
      const new_arr = summaryItems.filter(
        (item) => !ids.includes(item.topic_id)
      );
      setSummaryitems(new_arr);
      setSelectedTopicIds({});
      getSortedTopicIds();
    }
    setDeletingTopic(false);
  };
  const updatePage = (increment) => {
    setStartIndex(startIndex + increment);
    closeSelectedTopic();
  };
  useEffect(() => {
    if (newSearch) {
      getSortedTopicIds();
      closeSelectedTopic();
    }
  }, [newSearch]);
  useEffect(() => {
    const since = params.get("since");
    const until = params.get("until");
    if (since && until) {
      const sinceDate = dayjs(since);
      const untilDate = dayjs(until);
      const noOfDays =
        since === until ||
        (untilDate.diff(sinceDate, "day") === 1 && since !== until)
          ? untilDate.diff(sinceDate, "day")
          : untilDate.diff(sinceDate, "day") + 1;
      setPeriodByDateRange(
        noOfDays < 30 ? "daily" : noOfDays === 30 ? "weekly" : "monthly"
      );
    }
  }, [history, search]);
  const getTopicsSummary = async () => {
    setLoading(true);
    const payload = {
      dashboard_id: parseInt(DashboardId, 10),
      topic_type: 2,
      view_type: "list",
      topic_ids: tempSortedTopicsList.slice(
        startIndex * pageSize,
        startIndex * pageSize + pageSize
      ),
    };
    const since = params.get("since")
      ? params.get("since")
      : DashboardReducer.dashboard_start_date;
    const until = params.get("until")
      ? params.get("until")
      : DashboardReducer.dashboard_end_date;
    if (since && until) {
      payload.since = since;
      payload.until = until;
      const sinceDate = dayjs(since);
      const untilDate = dayjs(until);
      const noOfDays =
        since === until ||
        (untilDate.diff(sinceDate, "day") === 1 && since !== until)
          ? untilDate.diff(sinceDate, "day")
          : untilDate.diff(sinceDate, "day") + 1;
      payload.period =
        noOfDays < 30 ? "daily" : noOfDays === 30 ? "weekly" : "monthly";
    } else {
      payload.period = "monthly";
    }
    const result = await apiRequest(
      "dashboards/v2/get/trends_from_topics",
      "POST",
      payload,
      true
    );
    if (result.error || result.cancel || result.validation_error) {
      notification.error({
        message: "Getting summary with the selected option failed",
      });
      setTempSummaryItems([]);
      setSummaryitems([]);
      setLoading(false);
    } else {
      setSummaryitems(result?.data || []);
      setTempSummaryItems(result?.data || []);
      setLoading(false);
    }
  };
  const getSortedTopicIds = async () => {
    setLoading(true);
    const payload = {
      dashboard_id: parseInt(DashboardId, 10),
      topic_type: 2,
      view_type: "list",
      sort_by: sortValue,
    };
    const since = params.get("since")
      ? params.get("since")
      : DashboardReducer.dashboard_start_date;
    const until = params.get("until")
      ? params.get("until")
      : DashboardReducer.dashboard_end_date;
    if (since && until) {
      payload.since = since;
      payload.until = until;
      const sinceDate = dayjs(since);
      const untilDate = dayjs(until);
      const noOfDays =
        since === until ||
        (untilDate.diff(sinceDate, "day") === 1 && since !== until)
          ? untilDate.diff(sinceDate, "day")
          : untilDate.diff(sinceDate, "day") + 1;
      payload.period =
        noOfDays < 30 ? "daily" : noOfDays === 30 ? "weekly" : "monthly";
    } else {
      payload.period = "monthly";
    }
    if (searchTerm && newSearch) {
      payload.search_term = searchTerm;
      updatePage(-startIndex);
    } else if (searchTerm && !newSearch) {
      payload.search_term = searchTerm;
    } else if (searchTerm === "" && newSearch) {
      payload.search_term = searchTerm;
      updatePage(-startIndex);
    }
    if (params.get("insightTopicSearch")) {
      payload.topic_id = parseInt(params.get("insightTopicSearch"), 10);
    }
    const result = await apiRequest(
      "dashboards/v2/get/sorted_trend_topics",
      "POST",
      payload,
      true
    );
    if (result.error || result.cancel || result.validation_error) {
      notification.error({
        message: "Getting summary with the selected option failed",
      });
      setSortedTopicsList([]);
      setTempSortedTopicsList([]);
      setLoading(false);
    } else {
      if (!searchTerm) {
        setSortedTopicsList(result?.topic_ids || []);
      }
      setTempSortedTopicsList(result.topic_ids || []);
      setTotalTopics(result.total_topics || 0);
      setNewSearch(false);
    }
  };
  useEffect(() => {
    if (tempSortedTopicsList && tempSortedTopicsList.length > 0) {
      getTopicsSummary();
    } else {
      setTempSummaryItems([]);
      setSummaryitems([]);
      setNewSearch(false);
      setTotalTopics(0);
      setLoading(false);
    }
  }, [tempSortedTopicsList]);

  useEffect(() => {
    if (tempSummaryItems && tempSummaryItems.length === 0) {
      setShowDownload(false);
    } else {
      setShowDownload(true);
    }
  }, [tempSummaryItems]);
  const skipOnce = useRef(true);
  useEffect(() => {
    if (!loading && dashboardLoaded && params.has("daysRange")) {
      setLoading(true);
      getSortedTopicIds();
    }
  }, [sortValue, queryFilters.daysRange, dashboardLoaded]);
  useEffect(() => {
    if (skipOnce.current) {
      skipOnce.current = false;
      return;
    }
    getTopicsSummary();
  }, [startIndex]);
  const handleSortChange = (value) => {
    closeSelectedTopic();
    setSortValue(value);
    setStartIndex(0);
  };
  const searchPressed = (event) => {
    if (event.key === "Enter") {
      setNewSearch(true);
      setSearchTerm(changedSearchTerm);
    }
  };
  const selectTopic = (topic) => {
    if (params.has("selected_topic")) setTopicChanged(true);
    let index = -1;
    if (!summaryItems || summaryItems.length === 0) {
      return;
    }
    summaryItems.forEach((item, i) => {
      if (item.topic_id === topic.topic_id) {
        index = i;
      }
    });
    if (!params.has("insightTopicSearch")) {
      for (let l = 1; l >= 1; l++) {
        if (params.has(`level${l}Selection`)) {
          params.delete(`level${l}Selection`);
          history.push({ search: params.toString() });
          dispatch({
            type: actions.UPDATE_SEARCH_STATE,
            payload: {
              [`selectedL${l}Topic`]: null,
            },
          });
        } else {
          break;
        }
      }
      [
        "top_topics",
        "topics_operator",
        "hotterm",
        "nps",
        "keyword",
        "pivotFilter",
        "pivots_operator",
        "level1OfSelected",
        "level2OfSelected",
      ].forEach((item) => {
        if (params.has(item)) {
          params.delete(item);
        }
      });
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenKeywords: [],
          chosenFetchType: "topic",
          chosenHotTerm: "",
          chosenRestaurantName: "",
          chosenNPS: "",
          chosenTopTopic: [],
        },
      });
    }
    setActiveTopic(topic);
    updateQParams(
      "selected_topic",
      JSON.stringify({
        index,
        label: topic.topic_name,
        id: topic.topic_id,
      })
    );
  };

  const menu = (
    <Menu style={{ width: "120px" }} theme="dark">
      <Menu.Item
        key={1}
        onClick={(e) => {
          setSelectionType("deselect");
          AddTopicForDeletion("deselect");
        }}
      >
        {t("filter.7")}
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => {
          setSelectionType("visible");
          AddTopicForDeletion("visible");
        }}
      >
        {t("filter.6")}
      </Menu.Item>

      <Menu.Item
        key={3}
        onClick={() => {
          setSelectionType("all");
          AddTopicForDeletion("visible");
        }}
      >
        {t("filter.8")}
      </Menu.Item>
    </Menu>
  );
  const FollowTopic = () => {
    return (
      <span style={{ fontSize: "14px" }}>
        Are you sure that you want to unfollow{" "}
        <b>{topicInPopups?.topic_name}</b> Topic?
      </span>
    );
  };

  const followTopic = async (id) => {
    try {
      const result = await apiRequest("topic_tracker/add", "POST", {
        topic_id: id,
      });

      if (!result.error) {
        notification.success({
          message: "Followed Topic Successfully",
        });
        getSortedTopicIds();
      } else {
        notification.error({
          message: "Something went wrong",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const UnFollowTopic = async (id) => {
    try {
      const result = await apiRequest("topic_tracker/delete", "POST", {
        topic_id: id,
      });

      if (!result.error) {
        notification.success({
          message: "Unfollowed Topic Successfully",
        });
        setTopicInPopups(null);
        setShowFollowModal(false);
        getSortedTopicIds();
      } else {
        notification.error({
          message: "Something went wrong",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const SortMenu = (
    <Menu
      style={{ width: "120px" }}
      theme="dark"
      onChange={(e) => handleSortChange(e)}
    >
      <Menu.Item
        key={1}
        onClick={() => {
          handleSortChange("volume-up");
        }}
      >
        {t("filter.1")}
      </Menu.Item>

      <Menu.Item
        key={4}
        onClick={() => {
          handleSortChange("volume-down");
        }}
      >
        {t("filter.2")}
      </Menu.Item>
      <Menu.Item
        key={5}
        onClick={() => {
          handleSortChange("newest");
        }}
      >
        {t("filter.3")}
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={() => {
          handleSortChange("oldest");
        }}
      >
        {t("filter.4")}
      </Menu.Item>
      <Menu.Item
        key={6}
        onClick={() => {
          handleSortChange("a_z");
        }}
      >
        A-Z
      </Menu.Item>
      <Menu.Item
        key={7}
        onClick={() => {
          handleSortChange("z_a");
        }}
      >
        Z-A
      </Menu.Item>
      <Menu.Item
        key={6}
        onClick={() => {
          handleSortChange("top_increasing");
        }}
      >
        Top Increasing Topics
      </Menu.Item>
      <Menu.Item
        key={7}
        onClick={() => {
          handleSortChange("top_decreasing");
        }}
      >
        Top Decreasing Topics
      </Menu.Item>
    </Menu>
  );
  const DeletModalContent = () => {
    return Object.keys(selectedTopicIds).length > 1 ? (
      <p className={styles.deletetext}>
        {t("selected_dashboard_page.80")}
        <b>
          {" "}
          selected{" "}
          {selectionType === "all"
            ? totalTopics
            : Object.keys(selectedTopicIds).length}{" "}
        </b>
        {t("selected_dashboard_page.82")}
      </p>
    ) : (
      <p className={styles.deletetext}>
        {t("selected_dashboard_page.80")} <b>{topicInPopups?.topic_name}</b>{" "}
        {t("selected_dashboard_page.85")}
      </p>
    );
  };
  useEffect(() => {
    if (Object.keys(selectedTopicIds).length === 1) {
      const id = Object.keys(selectedTopicIds)[0];
      const item = tempSummaryItems.find(
        (item) => item.topic_id === parseInt(id, 10)
      );
      setTopicInPopups(item);
    }
  }, [selectedTopicIds]);
  return (
    <Spin spinning={loading}>
      <div className={styles.customSummaryList}>
        {summaryItems && summaryItems.length > 0 && (
          <div className={styles.searchBarContainer}>
            <div className={styles.searchBox}>
              {/* # TO DO => Add clear icon in the search bar while typing something and to remove any search keyword */}
              <input
                type="text"
                placeholder={t("selected_dashboard_page.79")}
                onKeyPress={searchPressed}
                onChange={(e) => setChangedSearchTerm(e.target.value)}
              />
              <span className={styles.searchIcon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                >
                  <path
                    d="M10.0833 18.2135C14.1334 18.2135 17.4167 14.9303 17.4167 10.8802C17.4167 6.83012 14.1334 3.54688 10.0833 3.54688C6.03325 3.54688 2.75 6.83012 2.75 10.8802C2.75 14.9303 6.03325 18.2135 10.0833 18.2135Z"
                    stroke={theme === "dark" ? "#D9D9D9" : "#222222"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.25 20.0469L15.2625 16.0594"
                    stroke={theme === "dark" ? "#D9D9D9" : "#222222"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className={styles.sortBox}>
              <span className={styles.sortHeading}>{t("filter.5")}</span>
              <Select
                className="sort-dropdown"
                value={sortValue}
                onChange={(e) => handleSortChange(e)}
              >
                <Option key="volume-up" value="volume-up">
                  {t("filter.1")}
                </Option>
                <Option key="volume-down" value="volume-down">
                  {t("filter.2")}
                </Option>
                <Option key="top_increase" value="top_increase">
                  {"Top Increasing (Count)"}
                </Option>
                <Option key="top_decrease" value="top_decrease">
                  {"Top Decreasing (Count)"}
                </Option>
                <Option key="top_increase_per" value="top_increase_per">
                  {"Top Increasing (%)"}
                </Option>
                <Option key="top_decrease_per" value="top_decrease_per">
                  {"Top Decreasing (%)"}
                </Option>
                <Option key="newest" value="newest">
                  {t("filter.3")}
                </Option>
                <Option key="oldest" value="oldest">
                  {t("filter.4")}
                </Option>
                <Option key="a_z" value="a_z">
                  A-Z
                </Option>
                <Option key="z_a" value="z_a">
                  Z-A
                </Option>
              </Select>
            </div>
            <Dropdown overlay={SortMenu}>
              <span className={styles.sortIcon}>
                <img src="/assets/sort_icon.svg" alt="" />
              </span>
            </Dropdown>
          </div>
        )}

        {Object.keys(selectedTopicIds).length ? (
          <div className={styles.buttonWrapper}>
            {" "}
            <div
              className={styles.removeButton}
              onClick={() => setDeleteModalOpen(true)}
            >
              <span className={styles.sortIcon}>
                <img src={"/assets/trash.svg"} alt="" width={18} />
              </span>
              {t("button.39")}{" "}
              {selectionType === "all"
                ? `All (${totalTopics})`
                : `(${Object.keys(selectedTopicIds).length})`}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className={styles.summaryListContainer}>
          {summaryItems?.length > 0 ? (
            <>
              {tempSummaryItems?.length > 0 ? (
                <div className={styles.summaryListTable}>
                  <div className={`${styles.heading} ${styles.row}`}>
                    {/* <Checkbox
                              className='checkbox'
                              onChange={() => {
                                 AddTopicForDeletion("all")

                              }}
                           >
                           </Checkbox> */}
                    <Dropdown overlay={menu}>
                      {theme === "dark" ? (
                        <img src="/assets/arrow_down.svg" alt="" width={18} />
                      ) : (
                        <img
                          src="/assets/arrow_down_black.svg"
                          alt=""
                          width={18}
                        />
                      )}
                    </Dropdown>
                    <div className={styles.topicName}>
                      {t("selected_dashboard_page.74")}
                    </div>
                    <div className={styles.trend}>
                      {t("selected_dashboard_page.75")}
                    </div>
                    <div className={styles.monthlyAverage}>
                      <span>
                        {periodByDateRange === "daily"
                          ? t("selected_dashboard_page.90")
                          : periodByDateRange === "weekly"
                          ? t("selected_dashboard_page.89")
                          : t("selected_dashboard_page.76")}
                      </span>
                      <span>
                        {periodByDateRange === "daily"
                          ? t("selected_dashboard_page.93")
                          : periodByDateRange === "weekly"
                          ? t("selected_dashboard_page.92")
                          : t("selected_dashboard_page.91")}
                      </span>
                    </div>
                    <div className={styles.monthlyAverage}>
                      <span>Total Count</span>
                      {periodByDateRange === "daily"
                        ? t("selected_dashboard_page.93")
                        : t("selected_dashboard_page.77")}
                    </div>
                    <div className={styles.currVsPrev}>
                      <div className={styles.monthlyAverage}>
                        <span>
                          {periodByDateRange === "daily"
                            ? t("selected_dashboard_page.93")
                            : t("selected_dashboard_page.77")}
                          {" (vs) "}
                          {periodByDateRange === "daily"
                            ? t("selected_dashboard_page.95")
                            : t("selected_dashboard_page.94")}
                        </span>
                      </div>
                    </div>
                    <div className={styles.actions}></div>
                  </div>
                  {tempSummaryItems &&
                    tempSummaryItems.length > 0 &&
                    tempSummaryItems.map((item) => (
                      <Tooltip
                        title={item.status === 0 && "Topic Running"}
                        align={{ targetOffset: ["45%", "0%"] }}
                      >
                        {item && (
                          <div
                            key={item.topic_id}
                            className={`${styles.row} ${styles.dataRow} ${
                              selectedTopic?.id === item?.topic_id
                                ? styles.activeItem
                                : undefined
                            }`}
                          >
                            <Checkbox
                              className="checkbox"
                              checked={item?.checked ? true : false}
                              onChange={(e) => {
                                e.stopPropagation();
                                AddTopicForDeletion(item.topic_id);
                              }}
                            />
                            <div
                              className={styles.topicName}
                              onClick={() => {
                                if (item.status === 0) {
                                  return;
                                }
                                [
                                  "top_topics",
                                  "topics_operator",
                                  "hotterm",
                                  "nps",
                                  "keyword",
                                  "pivotFilter",
                                  "pivots_operator",
                                  "level1OfSelected",
                                  "level2OfSelected",
                                ].forEach((item) => {
                                  if (params.has(item)) {
                                    params.delete(item);
                                  }
                                });
                                history.push({ search: params.toString() });
                                selectTopic(item);
                              }}
                              title={item.topic_name}
                              style={{
                                cursor:
                                  item.status === 0 ? "not-allowed" : "pointer",
                              }}
                            >
                              {item.topic_name}
                            </div>
                            <div className={styles.trend}>
                              <Line
                                data={{
                                  labels: uniqueAllDates,
                                  datasets: [
                                    {
                                      data: fillZeros(item.trend),
                                      fill: true,
                                      backgroundColor: "rgba(75,192,192,0.1)",
                                      lineTension: 0.4,
                                      borderWidth: 4,
                                      borderColor: "rgba(33, 172, 158,0.8)",
                                      pointRadius: 0,
                                    },
                                  ],
                                }}
                                options={options}
                              />
                            </div>
                            <div className={styles.monthlyAverage}>
                              {item.monthly_avg}
                            </div>
                            <span className={styles.monthlyAverage}>
                              {item.last_month.value}
                            </span>
                            {item?.last_month?.value !== "" ? (
                              <div className={`${styles.currVsPrev}`}>
                                <div className={styles.nestedColValues}>
                                  <span
                                    style={{
                                      color: item?.last_month?.color
                                        ? item?.last_month?.color
                                        : "white",
                                      paddingLeft: "5px",
                                    }}
                                    className={styles.monthlyAverageWithSign}
                                  >
                                    {item?.last_month?.sign == "+" ? (
                                      <svg
                                        width="15"
                                        height="14"
                                        viewBox="0 0 15 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M5.3125 5.2998H10.3125V12.8765C10.3125 13.1103 10.123 13.2998 9.88915 13.2998H5.73585C5.50204 13.2998 5.3125 13.1103 5.3125 12.8765V5.2998Z"
                                          fill={item.last_month.color}
                                        />
                                        <path
                                          d="M7.50446 0.258428C7.66588 0.11185 7.91224 0.111849 8.07366 0.258428L13.0399 4.76798C13.3264 5.02819 13.1423 5.50475 12.7553 5.50475H2.82285C2.43578 5.50475 2.2517 5.02819 2.53826 4.76798L7.50446 0.258428Z"
                                          fill={item.last_month.color}
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill={item.last_month.color}
                                          d="M9 4h6v8h4.84L12 19.84L4.16 12H9V4Z"
                                        />
                                      </svg>
                                    )}
                                    {item.last_month.change}{" "}
                                    {`(${item.last_month.percent_change}%)`}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className={styles.currVsPrev}></div>
                            )}
                            <div className={styles.actions}>
                              <span
                                className="moreOptionIcon"
                                onClick={() => {
                                  setSelectedItem(item.topic_id);
                                  setShowMoreOption(!showMoreOption);
                                  setTopicId(item.topic_id);
                                  setTopicName(item.topic_name);
                                }}
                              >
                                {item.status === 0 ? (
                                  <img
                                    src={`/assets/progress_icon.svg`}
                                    width={20}
                                    height={20}
                                  />
                                ) : theme === "dark" ? (
                                  <img
                                    src="/assets/more-vertical.svg"
                                    className="moreOptionSVG"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/assets/more-vertical-black.svg"
                                    className="moreOptionSVG"
                                    alt=""
                                  />
                                )}
                                {showMoreOption &&
                                  selectedItem === item.topic_id && (
                                    <div className="moreOptionBox">
                                      <span
                                        onClick={() => {
                                          setTopicInPopups(item);
                                          setDeleteModalOpen(true);
                                        }}
                                      >
                                        {" "}
                                        <img
                                          width={24}
                                          src={
                                            theme === "dark"
                                              ? "/assets/trash.svg"
                                              : "/assets/trash_black.svg"
                                          }
                                          className="moreOptionSVG"
                                          alt=""
                                        />
                                        {t("button.2")}
                                      </span>
                                      {item?.followed ? (
                                        <span
                                          onClick={() => {
                                            setTopicId(item.topic_id);
                                            setTopicInPopups(item);
                                            setShowFollowModal(true);
                                          }}
                                        >
                                          {" "}
                                          <img
                                            width={24}
                                            src={
                                              theme === "dark"
                                                ? "/assets/follow_filled.svg"
                                                : "/assets/follow_filled_black.svg"
                                            }
                                            className="moreOptionSVG"
                                            alt=""
                                          />
                                          Unfollow
                                        </span>
                                      ) : (
                                        <span
                                          onClick={() =>
                                            followTopic(item.topic_id)
                                          }
                                        >
                                          {" "}
                                          <img
                                            width={24}
                                            src={
                                              theme === "dark"
                                                ? "/assets/follow_filled.svg"
                                                : "/assets/follow_filled_black.svg"
                                            }
                                            className="moreOptionSVG"
                                            alt=""
                                          />
                                          Follow
                                        </span>
                                      )}
                                    </div>
                                  )}
                              </span>
                            </div>
                          </div>
                        )}
                      </Tooltip>
                    ))}
                  <div className={styles.footer}>
                    <div className={styles.btn}>
                      <Button
                        disabled={startIndex === 0}
                        className={styles.activeBtn}
                        onClick={() => updatePage(-1)}
                      >
                        {t("button.28")}
                      </Button>
                    </div>
                    <span className={styles.paginator}>
                      {t("label.10")} {startIndex + 1}{" "}
                      {lastIndex ? ` / ${lastIndex + 1}` : ""}
                    </span>
                    <div className={styles.btn}>
                      <Button
                        disabled={
                          startIndex * pageSize + tempSummaryItems.length ===
                          totalTopics
                        }
                        className={styles.activeBtn}
                        onClick={() => updatePage(1)}
                      >
                        {t("button.29")}
                      </Button>
                    </div>
                    <div>
                      {tempSummaryItems &&
                        tempSummaryItems.length &&
                        totalTopics > 0 && (
                          <span>{`${startIndex * pageSize +
                            tempSummaryItems.length}/${totalTopics}`}</span>
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.wrapper}>
                  <div className={styles.message}>
                    <div>
                      <img
                        className={styles.message_img}
                        src="/assets/info.svg"
                        width={40}
                        height={40}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <p className={styles.message_text}>
                        {t("notifications.79", { searchTerm: searchTerm })}
                      </p>
                    </div>
                  </div>
                  <div className={styles.message_sub}>
                    <p>{t("selected_dashboard_page.52")}</p>
                  </div>
                </div>
              )}
            </>
          ) : (
            !loading && (
              <div className={styles.noTopics}>
                <p className={styles.heading}>
                  {t("selected_dashboard_page.81")}
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/emptyStates/topic_library_icon.svg"
                        : "/assets/emptyStates/topic_library_black.svg"
                    }
                    alt=""
                  />
                </p>
                <div className={styles.secondRow}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/error_outline.svg"
                        : "/assets/error_outline_black.svg"
                    }
                    alt=""
                  />
                  {t("selected_dashboard_page.78")}
                </div>
                <div className={styles.imgBlock}>
                  <div>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/emptyStates/dashboard_custom_topic.png"
                          : theme === "light"
                          ? "/assets/emptyStates/dashboard_custom_topic_light.png"
                          : "/assets/emptyStates/dashboard_custom_topic_brand.png"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <p
                  className={styles.link}
                  onClick={() => history.push(`/console/topicLibrary`)}
                >
                  {t("empty_states.4")}
                </p>
              </div>
            )
          )}
        </div>
      </div>

      <Modal
        showModal={deleteModalOpen}
        onClose={() => {
          setTopicInPopups(null);
          setDeleteModalOpen(false);
        }}
        onSave={() => {
          handleDeleteTopic(
            Object.keys(selectedTopicIds).length || selectionType == "all"
              ? true
              : false
          );
        }}
        showSaveButton={true}
        showCancelButton={true}
        hideCloseIcon={true}
        textonButton={t("button.2")}
        titleAsset=""
        title={"Delete topic"}
        loadingSaveClick={deletingTopic || loading ? true : false}
        widthofmodal="450px"
        disabled={deletingTopic || loading ? true : false}
        isDeleteModal={true}
      >
        <DeletModalContent />
      </Modal>
      <Modal
        showModal={showFollowModal}
        onClose={() => {
          setTopicInPopups(null);
          setShowFollowModal(false);
        }}
        onSave={() => {
          UnFollowTopic(topicId);
        }}
        showSaveButton={true}
        hideCloseIcon={true}
        showCancelButton={true}
        textonButton={"Unfollow"}
        description={
          <span style={{ fontSize: "14px" }}>
            You will no longer get daily notifications from this topic. This may
            cause to miss the important information on time
          </span>
        }
        loadingSaveClick={loading ? true : false}
        titleAsset=""
        title={"Unfollow Topic"}
        widthofmodal="550px"
        disabled={loading ? true : false}
      >
        <FollowTopic />
      </Modal>
    </Spin>
  );
};

export default CustomSummary;
