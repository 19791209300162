import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Menu } from "antd";
import DrawerMenu from "rc-drawer";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "rc-drawer/assets/index.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./consoleSideMenu.scss";
import {
  DashboardOutlined,
  DatabaseOutlined,
  CloudDownloadOutlined,
  AlertOutlined,
  AppstoreOutlined,
  NodeIndexOutlined,
  ApartmentOutlined,
  UsergroupAddOutlined,
  CoffeeOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
// const { SubMenu } = Menu

const ConsoleSideMenu = ({
  openRightClickPopup,
  setOpenRightClickPopup,
  theme,
}) => {
  const { id } = useParams();
  const [isMobileView, setIsMobileView] = useState(false);
  const [isPrivacyMenuOpen, setIsPrivacyMenuOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState([]);
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const userReducer = useSelector((state) => state.userReducer);
  const currentDashboard = DashboardReducer.dashboard?.ID || id;
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (window.innerWidth < 1100) {
      setIsMobileView(true);
    }
    function handleResize() {
      if (window.innerWidth < 1100) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
    let defaultSelected = document.location.pathname.split("/")[2];
    history.listen(() => {
      defaultSelected = document.location.pathname.split("/")[2];
      setSelectedMenuItem(defaultSelected);
    });
    setSelectedMenuItem(defaultSelected);
    window.addEventListener("resize", handleResize);
  }, []);

  const DashboardKeys = [
    "DashboardData",
    "Dashboard",
    "customTopics",
    "journey",
    "insights",
    "geolocation",
  ];

  const handleClick = (e) => {
    setOpenRightClickPopup(false);
    if (e.key === "DashboardGroup") return;
    else if (e.key === "DashboardData") {
      // reset popup
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          showNewReviewsLabel: false,
        },
      });
    }
    for (let l = 1; l >= 1; l++) {
      if (params.has(`level${l}Selection`)) {
        params.delete(`level${l}Selection`);
        history.push({ search: params.toString() });
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            [`selectedL${l}Topic`]: null,
          },
        });
      } else {
        break;
      }
    }
    [
      "sentiment",
      "intent",
      "rating",
      "top_topics",
      "topics_operator",
      "platform_id",
      "match_date",
      "hotterm",
      "nps",
      "keyword",
      "pivotFilter",
      "pivots_operator",
      "view_type",
      "topics_type",
      "selected_topic",
      "insightTopicSearch",
      "insightTopicClosed",
      "start_index",
      "page_offset",
    ].forEach((item) => params.delete(item));
    setSelectedMenuItem(e.key);
    if (currentDashboard && DashboardKeys.includes(e.key)) {
      history.push({
        pathname: `/console/${e.key}/${currentDashboard}`,
        search: params.toString(),
      });
    } else {
      history.push({
        pathname: `/console/${e.key}`,
      });
    }
  };

  const toggleOpen = () => {
    document
      .querySelector("#root")
      .setAttribute(
        "style",
        !isPrivacyMenuOpen ? "overflow: scroll; width: 100%; height: 100%;" : ""
      );
    setIsPrivacyMenuOpen(!isPrivacyMenuOpen);
  };

  const titleStyle = {
    color: theme === "dark" ? "white" : "black",
    margin: "18px 23px 10px 23px",
    fontFamily: "Poppins",
    fontSize: "14px",
    width: "174px",
    wordBreak: "break-all",
  };

  const DashboardTitleStyle = {
    marginLeft: "7px",
    marginBottom: "0px",
    color: theme === "dark" ? "" : "#4A4D4E",
  };

  const containerStyle = {
    backgroundColor: "#262250",
    paddingLeft: "23px",
    marginBottom: "-15px",
  };

  const freeTextStyle = {
    marginBottom: "0",
    color: "white",
    paddingTop: "18px",
    paddingRight: "18px",
    paddingBottom: "18px",
    fontFamily: "Poppins",
    fontSize: "18px",
  };

  const buttonStyle = {
    background: "#4400AA",
    borderColor: "#4400AA",
    minWidth: "150px",
    color: "white",
    borderRadius: "10px",
  };
  const openLinkNewTab = () => {
    window.open(
      `${window.location.origin + "/console/"}${selectedSubMenu}/${
        currentDashboard ? currentDashboard : ""
      }`,
      "_blank"
    );
  };

  const showRightClickPopup = (
    <div
      className="right-click-popup"
      onContextMenu={(e) => e.preventDefault()}
    >
      <span className="right-click-popup-item" onClick={openLinkNewTab}>
        Open link in new tab
      </span>
    </div>
  );

  const handleRightClickPopup = (e) => {
    e.preventDefault();

    if (e.target.dataset.menuId || e.target.parentElement.dataset.menuId) {
      const temp_key =
        e.target.dataset.menuId || e.target.parentElement.dataset.menuId;
      const item_key = temp_key.split("-")[temp_key.split("-").length - 1];

      if (DashboardKeys.includes(item_key)) {
        setSelectedSubMenu(item_key);
        setOpenRightClickPopup(true);
      } else {
        setOpenRightClickPopup(false);
      }
    } else {
      setOpenRightClickPopup(false);
    }
  };
  const label =
    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat obcaecati eum numquam odit voluptates, molestias temporibus sapiente unde maiores aliquid.";

  const SideMenu = () => {
    return (
      <>
        {userReducer?.logoUrl && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
              backgroundColor:
                theme === "dark"
                  ? "#262250"
                  : theme === "light"
                  ? "#E8E0F4"
                  : "#f4f4f4",
            }}
          >
            <img
              src={userReducer?.logoUrl}
              style={{ maxWidth: 120, maxHeight: 120 }}
            />
          </div>
        )}
        <div>
          <Menu
            onClick={handleClick}
            // style={{ width: isMobileView ? 200 : "auto" }}
            selectedKeys={[selectedMenuItem]}
            defaultOpenKeys={[isMobileView ? "" : "DashboardGroup"]}
            // inlineCollapsed={isMobileView}
            mode="inline"
            theme="dark"
            contextMenu={true}
            className={`side-menu-item-wrapper ${
              theme === "dark"
                ? ""
                : theme === "light"
                ? "light-side-menu"
                : "brand-side-menu"
            }`}
          >
            {currentDashboard &&
              DashboardKeys.includes(
                document.location.pathname.split("/")[2]
              ) &&
              window.innerWidth < 1100 && (
                <>
                  <h4 style={titleStyle}>
                    {DashboardReducer?.dashboard?.Name.length > 39
                      ? DashboardReducer?.dashboard?.Name.substring(0, 40) +
                        "..."
                      : DashboardReducer?.dashboard?.Name}
                  </h4>
                  <Menu.ItemGroup className="sub-menu-item" title={false}>
                    <Menu.Item key="DashboardData">
                      <a
                        className="hyper-link"
                        href={`${window.location.origin +
                          "/console/DashboardData"}/${
                          currentDashboard ? currentDashboard : ""
                        }`}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {t("selected_dashboard_sidebar.1")}
                      </a>
                    </Menu.Item>
                    <Menu.Item key="Dashboard">
                      <a
                        className="hyper-link"
                        href={`${window.location.origin +
                          "/console/Dashboard"}/${
                          currentDashboard ? currentDashboard : ""
                        }`}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {t("selected_dashboard_sidebar.2")}
                      </a>
                    </Menu.Item>
                    {/* <Menu.Item
                      key="industryTopics"
                    >
                      {t("selected_dashboard_sidebar.3")}
                      {selectedSubMenu === 'fixedTopics' && openRightClickPopup && showRightClickPopup}
                    </Menu.Item> */}
                    <Menu.Item key="customTopics">
                      <a
                        className="hyper-link"
                        href={`${window.location.origin +
                          "/console/customTopics"}/${
                          currentDashboard ? currentDashboard : ""
                        }`}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {t("selected_dashboard_sidebar.4")}
                      </a>
                    </Menu.Item>
                    {DashboardReducer.dashboard?.Status !== "Ready" && (
                      <Menu.Item key="journey">
                        <a
                          className="hyper-link"
                          href={`${window.location.origin +
                            "/console/journey"}/${
                            currentDashboard ? currentDashboard : ""
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                          }}
                        >
                          {t("selected_dashboard_sidebar.5")}
                        </a>
                      </Menu.Item>
                    )}
                    <Menu.Item key="insights">
                      <a
                        className="hyper-link"
                        href={`${window.location.origin +
                          "/console/insights"}/${
                          currentDashboard ? currentDashboard : ""
                        }`}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {t("selected_dashboard_sidebar.6")}
                      </a>
                    </Menu.Item>
                    {/* {
                      DashboardReducer.dashboard?.GeoEnabled &&
                      <Menu.Item key="geolocation">
                        {t("selected_dashboard_sidebar.7")}
                        {selectedSubMenu === 'geolocation' && openRightClickPopup && showRightClickPopup}
                      </Menu.Item>
                    } */}
                  </Menu.ItemGroup>
                </>
              )}
            <Menu.Item
              key="myDashboards"
              id="myDashboards"
              icon={<AppstoreOutlined />}
            >
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/myDashboards"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("dashboard_sidebar.1")}
              </a>
            </Menu.Item>
            {/* <Menu.Item icon={<FontAwesomeIcon icon={faPlus} />} key="source" id="source">
              New Dashboard
            </Menu.Item> */}
            <Menu.Item key="global_executive" icon={<DashboardOutlined />}>
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/global_executive"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("dashboard_sidebar.2")}
              </a>
            </Menu.Item>

            <Menu.Item
              key="topicLibrary"
              id="topicLibrary"
              icon={<ApartmentOutlined />}
            >
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/topicLibrary"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("dashboard_sidebar.3")}
              </a>
            </Menu.Item>
            <Menu.Item
              key="industryTopics"
              id="industryTopics"
              icon={<BarChartOutlined />}
            >
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/industryTopics"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("dashboard_sidebar.9")}
              </a>
            </Menu.Item>
            {/* <Menu.Item icon={<img src={theme === 'dark' ? "/assets/tag.svg" : "/assets/tag_black.svg"} alt="" />} key="tags" id="tags">
              <a className="hyper-link" href={`${window.location.origin + "/console/tags"}`} onClick={(event) => {event.preventDefault()}}>{t("dashboard_sidebar.6")} </a>
            </Menu.Item>*/}

            <Menu.Item icon={<UsergroupAddOutlined />} key="engage" id="engage">
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/engage"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("dashboard_sidebar.4")}
              </a>
            </Menu.Item>
            {/* <h4 style={titleStyle}>Engage</h4>
            <Menu.Item icon={<FontAwesomeIcon icon={faUser} />} key="audience" id="audience">
              Audience
            </Menu.Item>
            <Menu.Item icon={<FontAwesomeIcon icon={faEnvelopeOpenText} />} key="emailMarketing" id="emailMarketing">
              Email marketing
            </Menu.Item> */}
            <Menu.Item
              key="report"
              id="report"
              icon={<CloudDownloadOutlined />}
            >
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/report"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("dashboard_sidebar.5")}
              </a>
            </Menu.Item>
            <Menu.Item key="alerts" id="alerts" icon={<AlertOutlined />}>
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/alerts"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("dashboard_sidebar.7")}
              </a>
            </Menu.Item>
            <Menu.Item key="followed" id="followed" icon={<CoffeeOutlined />}>
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/followed"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                Followed Topics
              </a>
            </Menu.Item>
            <Menu.Item key="survey" id="survey" icon={<DatabaseOutlined />}>
              <a
                className="hyper-link"
                href={`${window.location.origin + "/console/survey"}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                {t("dashboard_sidebar.8")}
              </a>
            </Menu.Item>
            {/* <Menu.Item key="journeys" id="journeys" icon={<NodeIndexOutlined />}>
              <a 
                className="hyper-link" 
                href={`${window.location.origin + "/console/journeys"}`} 
                onClick={(event) => {event.preventDefault()}}
              >
                Customer journey 
              </a>
            </Menu.Item> */}
          </Menu>
        </div>
      </>
    );
  };

  const BootstrappedMenu = () => {
    if (isMobileView) {
      return (
        <DrawerMenu
          getContainer={null}
          open={isPrivacyMenuOpen}
          level={null}
          onClose={toggleOpen}
          onHandleClick={toggleOpen}
          className={
            theme === "dark" ? "" : theme === "light" ? "light" : "brand"
          }
        >
          {SideMenu()}
        </DrawerMenu>
      );
    }
    return SideMenu();
  };
  return BootstrappedMenu();
};

export default ConsoleSideMenu;
