import React from "react";
import styles from "./style.module.scss"
const ReviewCard = ({ review, height }) => {
    let reviewPlatformId = String(review?.platform)
    return (
        <div className={styles.reviewCard}>
            <div className={styles.header}>
                <div className={styles.profile}>
                <img className={styles.profile} src={review?.photo!== "None" && review?.photo !== "nan" ? review?.photo : "/assets/facebook_user.jpg"} />
                </div>
                <div className={styles.title}>
                    <div className={styles.name}> {review?.name} </div>
                    {/* <div className={styles.description}> {(review?.username !== "None" && review?.username !== "nan" && typeof (review?.username) !== "undefined") ? "@" + review?.username : ""} </div> */}
                    <div className={styles.description}>{review?.date.substring(0,22)}</div>
                </div>
                {reviewPlatformId === "1" ?
              <img className={styles.twitter}  src="/assets/twitter_new_.png" />
              : reviewPlatformId === "5" ?
                <img style={{ fontSize: 32, marginRight: 15 }} src="/assets/sikayet_icon.svg" alt="sikayet" />
                : reviewPlatformId === "6" ?
                  <i style={{ fontSize: 32, marginRight: 15, color: '#674fbc' }} className="fa-brands fa-instagram" />
                  : reviewPlatformId === "9" ?
                    <i style={{ fontSize: 32, marginRight: 15, color: '#4267B2' }} className="fa-brands fa-facebook-square" />
                    : reviewPlatformId === "10" ?
                      <i style={{ fontSize: 32, marginRight: 15, color: '#4267B2' }} className="fa-brands fa-facebook-square" />
                      : reviewPlatformId === "18" ?
                        <i style={{ fontSize: 32, marginRight: 15, color: '#ff4500' }} className="fa-brands fa-reddit" />
                        : reviewPlatformId === "19" ?
                          <img style={{ objectFit: 'contain', marginTop: 5, marginRight: 15 }} src="/assets/eksi_icon.svg" alt="eksi" />
                          : reviewPlatformId === "27" ?
                            <i style={{ fontSize: 32, marginRight: 15, color: '#674fbc' }} className="fa-brands fa-instagram" />
                            : null}
            </div>
            <div className={styles.reviewContent}>
                {review?.review?.length > 117 ? review?.review?.substring(0,117) + "..":review?.review}
            </div>
        </div>)
}

export default ReviewCard