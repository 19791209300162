import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import ReviewItem from "./ReviewItem";
import { Button, Tag, Select, Input } from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { keyPressed } from "util/common";
import { Option } from "antd/lib/mentions";
import mixpanel from "mixpanel-browser";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const ReviewsList = ({
  allReviews,
  startIndex,
  setStartIndex,
  getAllReviews,
  reviewsLoading,
  numReviews,
  setReviewsLoading,
  facebookReplies,
  getFacebookReplies,
  setNumOfDocs,
  resetReviews,
  setResetReviews,
  searchWord,
  setSearchWord,
  handleSearchWord,
  colorMap,
  searchKeywords,
  setSearchKeywords,
}) => {
  const searchState = useSelector(
    (state) => state.DashboardReducer.searchState
  );
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [selectedPerPage, setSelectedPerPage] = useState(10);
  const [filters, setFilters] = useState([]);
  const [reviewPageNumber, setReviewPageNumber] = useState(1);
  const [showHidePopup, setShowHidePopup] = useState(false);
  const [showCustomTopicLabel, setShowCustomTopicLabel] = useState(true);
  const [showCustomTopicParentLabel, setShowCustomTopicParentLabel] = useState(
    true
  );
  const [showAiTopicLabel, setShowAiTopicLabel] = useState(false);
  const [showPivotsInReviews, setShowPivotsInReviews] = useState(false);
  const { t } = useTranslation();
  const showHidePopupRef = useRef();
  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    setReviewPageNumber(startIndex + 1);
  }, [startIndex]);
  useEffect(() => {
    setStartIndex(0);
  }, [allReviews]);
  const hasAlphabet = (str) => {
    return /[A-Za-z]/.test(str);
  };
  const reviews =
    allReviews && allReviews.length
      ? allReviews?.map((review, i) => {
          let targetIdentifier;
          if (review.Gpid) targetIdentifier = review.Gpid;
          else if (review.DocEmail) targetIdentifier = review.DocEmail;
          else if (review.DocPhone) targetIdentifier = review.DocPhone;
          return (
            <ReviewItem
              text={review?.text}
              title={review?.title}
              intents={review?.num_intent}
              sentiment={review?.num_sentiment}
              date={review?.timestamp}
              relatedTopics={review?.associated_topics}
              rate={review?.num_rating}
              setReviewsLoading={setReviewsLoading}
              reviewId={review?.internalId}
              Gpid={review?.gpid}
              date_gpid={review?.date_gpid}
              facebookReplies={facebookReplies}
              getFacebookReplies={getFacebookReplies}
              reviewPlatformId={review?.num_platform}
              platformId={review?.dash_platform}
              name={review?.name}
              pic={review?.pic}
              userVerified={review?.UserVerified}
              username={review?.username}
              likes={review?.thumbsUpCount}
              restaurant={review?.pivot}
              npsRating={parseInt(review?.nps)}
              showCustomTopicLabel={showCustomTopicLabel}
              engagements={{
                likes: hasAlphabet(review?.likes)
                  ? review?.likes
                  : parseInt(review?.likes),
                comments: hasAlphabet(review?.comment_count)
                  ? review?.comment_count
                  : parseInt(review?.comment_count),
                retweets: hasAlphabet(review?.retweet_count)
                  ? review?.retweet_count
                  : parseInt(review?.retweet_count),
                views: hasAlphabet(review?.views_count)
                  ? review?.views_count
                  : parseInt(review?.views_count),
              }}
              showPivotsInReviews={showPivotsInReviews}
              showAiTopicLabel={showAiTopicLabel}
              showCustomTopicParentLabel={showCustomTopicParentLabel}
              allReviews={allReviews}
              groupId={
                review.group_id
                  ? review.group_id
                  : review?.dash_id
                  ? review.dash_id
                  : null
              }
              groupName={
                review.group_name
                  ? review.group_name
                  : review?.dash_name
                  ? review.dash_name
                  : null
              }
              colorMap={colorMap}
            />
          );
        })
      : [];

  const handlePageChange = (value) => {
    setSelectedPerPage(value);
    setNumOfDocs(value);
    setStartIndex(0);
    setReviewPageNumber(1);
  };
  useEffect(() => {
    getAllReviews(startIndex);
  }, [selectedPerPage]);
  const handleUserPage = (e) => {
    if (e.which === 13) {
      if (!reviewPageNumber) {
        notification.info({
          message: t("notifications.2"),
        });
      } else if (
        reviewPageNumber > 0 &&
        reviewPageNumber <= Math.ceil(numReviews / selectedPerPage) &&
        /^\d+$/.test(reviewPageNumber)
      ) {
        getAllReviews(reviewPageNumber - 1);
        e.target.blur();
      } else {
        notification.info({
          message: t("notifications.2"),
        });
      }
    }
  };
  const handleCloseKeyword = (keyword) => {
    if (keyword && searchState.chosenKeywords.includes(keyword)) {
      const newKeywords = searchState.chosenKeywords.filter(
        (item) => item !== keyword
      );
      setSearchWord("");
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenKeywords: newKeywords,
        },
      });
    } else if (keyword && searchKeywords?.includes(keyword)) {
      const newKeywords = searchKeywords.filter((item) => item !== keyword);
      setSearchWord("");
      setSearchKeywords(newKeywords);
    }
  };
  const handleResetFilter = () => {
    if (searchKeywords && searchKeywords.length > 0) {
      setSearchKeywords([]);
      setSearchWord("");
    }
    setSearchWord("");
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        chosenKeywords: [],
      },
    });
  };
  useEffect(() => {
    if (searchState && searchState.chosenKeywords) {
      const { chosenKeywords } = searchState;
      let data = [];
      data = chosenKeywords?.map((keyword, i) => {
        return (
          <Tag
            key={keyword}
            closable={!reviewsLoading}
            onClose={(e) => handleCloseKeyword(keyword)}
          >
            <span className={styles.roundIcon}>
              <img src={"/assets/search_black.svg"} alt="" />
            </span>
            {keyword}
          </Tag>
        );
      });
      setFilters(data);
    }
  }, [searchState.chosenKeywords, reviewsLoading]);
  useEffect(() => {
    if (searchKeywords && searchKeywords.length > 0) {
      let data = [];
      data = searchKeywords.map((keyword, i) => {
        return (
          <Tag
            key={keyword}
            closable={!reviewsLoading}
            onClose={(e) => handleCloseKeyword(keyword)}
          >
            <span className={styles.roundIcon}>
              <img src={"/assets/search_black.svg"} alt="" />
            </span>
            {keyword}
          </Tag>
        );
      });
      setFilters(data);
    }
  }, [searchKeywords, reviewsLoading]);
  return (
    <div className={styles.ReviewsListContainer}>
      <div className={styles.title}>
        {allReviews && allReviews.length > 0 && (
          <div className={styles.searchBar}>
            {showSearchBar && (
              <>
                <div className={styles.showHideTopicLabel}>
                  <span
                    className={styles.showHidePopupBtn}
                    onClick={() => setShowSearchBar(!showSearchBar)}
                  >
                    <span className={styles.showText}>
                      <ArrowLeftOutlined
                        className={styles.eye}
                        style={{
                          color: theme === "dark" ? "white" : "black",
                        }}
                      />
                    </span>
                  </span>
                </div>
                <Input
                  placeholder={t("placeholder.13")}
                  onKeyPress={(e) =>
                    keyPressed(e, () => handleSearchWord(searchWord))
                  }
                  onChange={(e) => setSearchWord(e.target.value)}
                  value={searchWord}
                  suffix={
                    <span
                      type="primary"
                      className={styles.suffixBtn}
                      onClick={() => handleSearchWord(searchWord)}
                      disabled={reviewsLoading ? true : false}
                    >
                      {t("button.5")}
                    </span>
                  }
                />
              </>
            )}
            {!showSearchBar && (
              <div className={styles.showHideTopicLabel}>
                <span
                  className={styles.showHidePopupBtn}
                  onClick={() => setShowSearchBar(!showSearchBar)}
                >
                  <span className={styles.showText}>
                    <SearchOutlined
                      className={styles.searchIcon}
                      style={{
                        color: theme === "dark" ? "white" : "black",
                      }}
                    />
                  </span>
                </span>
              </div>
            )}
          </div>
        )}
        {allReviews && allReviews?.length > 0 && (
          <div className={styles.showHideTopicLabel}>
            <span
              className={styles.showHidePopupBtn}
              onClick={() => setShowHidePopup(!showHidePopup)}
            >
              <EyeOutlined className={styles.eye} />
              <span className={styles.showText}></span>
            </span>
            {showHidePopup && (
              <div className={styles.showTopicPopup} ref={showHidePopupRef}>
                <span className={styles.action}>
                  <span>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/bubble_chart.svg"
                          : "/assets/bubble_chart_black.svg"
                      }
                      width={22}
                      height={22}
                      alt=""
                    />
                    AI Topics
                  </span>
                  {!showAiTopicLabel ? (
                    <EyeInvisibleOutlined
                      onClick={() => setShowAiTopicLabel(true)}
                      className={styles.eyeClose}
                    />
                  ) : (
                    <EyeOutlined
                      className={styles.eye}
                      onClick={() => setShowAiTopicLabel(false)}
                    />
                  )}
                </span>
                <span className={styles.action}>
                  <span>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/custom_words.svg"
                          : "/assets/custom_words_black.svg"
                      }
                      width={20}
                      height={20}
                      alt=""
                    />
                    Custom Topics
                  </span>
                  {!showCustomTopicLabel ? (
                    <EyeInvisibleOutlined
                      onClick={() => setShowCustomTopicLabel(true)}
                      className={styles.eyeClose}
                    />
                  ) : (
                    <EyeOutlined
                      className={styles.eye}
                      onClick={() => setShowCustomTopicLabel(false)}
                    />
                  )}
                </span>
                <span className={styles.action}>
                  <span>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/relation.svg"
                          : "/assets/relation_black.svg"
                      }
                      width={20}
                      height={20}
                      alt=""
                    />
                    Custom Topics With Parent
                  </span>
                  {!showCustomTopicParentLabel ? (
                    <EyeInvisibleOutlined
                      onClick={() => setShowCustomTopicParentLabel(true)}
                      className={styles.eyeClose}
                    />
                  ) : (
                    <EyeOutlined
                      className={styles.eye}
                      onClick={() => setShowCustomTopicParentLabel(false)}
                    />
                  )}
                </span>
                <span className={styles.action}>
                  <span>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/pivot.svg"
                          : "/assets/pivot_black.svg"
                      }
                      width={20}
                      height={20}
                      alt=""
                    />
                    Pivot columns
                  </span>
                  {!showPivotsInReviews ? (
                    <EyeInvisibleOutlined
                      onClick={() => setShowPivotsInReviews(true)}
                      className={styles.eyeClose}
                    />
                  ) : (
                    <EyeOutlined
                      className={styles.eye}
                      onClick={() => setShowPivotsInReviews(false)}
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {filters.length > 0 && (
        <div className={styles.searchFilters}>
          <div className={styles.filterFirstRow}>{filters}</div>
          <div
            className={styles.reset}
            onClick={() => {
              !reviewsLoading && handleResetFilter();
            }}
          >
            {t("selected_dashboard_page.5")}
          </div>
        </div>
      )}
      <div className={styles.reviews}>
        {allReviews?.length === 0 ? (
          <div className={styles.emptyState}>
            <img
              src={
                theme === "dark"
                  ? "/assets/review_empty_state.svg"
                  : "/assets/review_empty_state_black.svg"
              }
              alt=""
            />
            <div className={styles.emptyStateContent}>
              {t("notifications.86")}
            </div>
          </div>
        ) : (
          reviews
        )}
      </div>
      <div className={styles.paginationWrapper}>
        {allReviews?.length > 0 && (
          <div className={styles.innerWrapper}>
            <div className={styles.paginationBox}>
              <Button
                disabled={startIndex === 0 || !reviewPageNumber}
                onClick={() => {
                  // handleNextPrevPage(-1);
                  parseInt(reviewPageNumber, 10) === startIndex + 1 &&
                    getAllReviews(-1);
                  // parseInt(reviewPageNumber, 10) === startIndex + 1 && setReviewPageNumber(prev => parseInt(prev, 10) - 1)
                  mixpanel.track("TOPIBOARD_REVIEWS_PAGE_PREV");
                }}
              >
                <i className="fa fa-angle-left" width={12} height={12} />
              </Button>
              <span className={styles.pagination}>
                <input
                  className={styles.pageIndex}
                  onKeyPress={handleUserPage}
                  type="text"
                  value={reviewPageNumber}
                  onChange={(e) => setReviewPageNumber(e.target.value)}
                />
                {/* Page {startIndex + 1}{" "} */}
                {numReviews
                  ? ` / ${Math.ceil(numReviews / selectedPerPage)}`
                  : ""}
              </span>
              <Button
                disabled={
                  allReviews.length === 0 ||
                  (numReviews &&
                    startIndex + 1 ===
                      Math.ceil(numReviews / selectedPerPage)) ||
                  !reviewPageNumber
                }
                onClick={() => {
                  // handleNextPrevPage(1);
                  parseInt(reviewPageNumber, 10) === startIndex + 1 &&
                    getAllReviews(1);
                  // parseInt(reviewPageNumber, 10) === startIndex + 1 && setReviewPageNumber(prev => parseInt(prev, 10) + 1)
                  mixpanel.track("TOPIBOARD_REVIEWS_PAGE_NEXT");
                }}
              >
                <i className="fa fa-angle-right" width={12} height={12} />
              </Button>
              {numReviews > 0 && (
                <Select
                  className="review-page-number-selector"
                  value={selectedPerPage + ` / ${t("label.10")}`}
                  onChange={handlePageChange}
                  style={{
                    backgroundColor: "transparent",
                  }}
                >
                  <Option value={10}>10</Option>
                  <Option disabled={numReviews < 25 ? true : false} value={25}>
                    25
                  </Option>
                  <Option disabled={numReviews < 50 ? true : false} value={50}>
                    50
                  </Option>
                  <Option
                    disabled={numReviews < 100 ? true : false}
                    value={100}
                  >
                    100
                  </Option>
                </Select>
              )}
            </div>
            {numReviews > 0 && (
              <div className={styles.reviewsTotal}>
                {selectedPerPage * startIndex +
                  allReviews?.length +
                  " of " +
                  numReviews}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewsList;
