import { Height } from "@material-ui/icons";

function formatLabel(str, maxwidth) {
  var sections = [];
  var words = str.split(" ");
  var temp = "";

  words.forEach(function (item, index) {
    if (temp.length > 0) {
      var concat = temp + ' ' + item;

      if (concat.length > maxwidth) {
        sections.push(temp);
        temp = "";
      }
      else {
        if (index == (words.length - 1)) {
          sections.push(concat);
          return;
        }
        else {
          temp = concat;
          return;
        }
      }
    }

    if (index == (words.length - 1)) {
      sections.push(item);
      return;
    }

    if (item.length < maxwidth) {
      temp = item;
    }
    else {
      sections.push(item);
    }

  });

  return sections;
}

const plugins = {
  datalabels: {
    formatter: function (toolTipItem, data) {
      if (data?.dataset?.data[0].name?.length * 4.2 <= data?.dataset?.data[0].r) return toolTipItem.name;
      else {
        return formatLabel(data?.dataset?.data[0].name, Math.trunc(data?.dataset?.data[0].r / 4.5))
      }
    },
    textAlign: "center",
    color: "White",
    font: {
      weight: 300,
      size: 16,
    },
  },
  zoom: {
    pan: {
      enabled: false,
      mode: "xy",
      speed: 10,
      threshold: 10,
    },
    zoom: {
      enabled: false,
      mode: "xy",
      onZoom: (text) => console.log(text),
      rangeMin: {
        x: -300,
        y: -300
      },
      rangeMax: {
        x: 375,
        y: 375
      }
    },
    // limits: {
    //   y: { min: 0, max: 100 },
    //   y2: { min: -5, max: 5 },
    // }
  },
};

const scales = {
  xAxes: [
    {
      display: false,
      ticks: {
        display: false,
        min: -200,
        max: 200
      },
    },
  ],
  yAxes: [
    {
      display: false,
      ticks: {
        display: false,
        max: 200,
        min: -200
      },
    },
  ],
};

const tooltips = {
  enabled: true,
  callbacks: {
    // title: function (context) {
    //   console.log(context)
    // },
    label: function (tooltipItem, data) {
      let index = tooltipItem.datasetIndex;
      // let representatives = data.datasets[index].tweet.slice(0, 10);
      return data?.datasets[index]?.data[0].name
    },
    footer: function (tooltipItems, data) {
      let representatives = data?.datasets[tooltipItems[0]?.datasetIndex]?.tweet.slice(0, 10);
      return representatives.join(", ");
    }
  },
  backgroundColor: "rgba(0,0,0,0.8)",
  bodyAlign: "left",
  // bodyFontSize: '14',
  custom: function (tooltipModel) {
    // tooltipModel.width = 300
  },
};

export const options = {
  legend: { position: "left", display: false },
  tooltips: tooltips,
  scales: scales,
  plugins: plugins,
};
