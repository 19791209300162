import React,{useEffect, useState} from 'react';
import styles from './style.module.scss';
const OverallAverage = ({ data }) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth<950?true:false)
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 950);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const filteredData = data?.filter(category => category.percentage >= 1);
  return (
    <div className={styles.average}>
      <div className={styles.Heading}>
        <h5 className={styles.title}>Overall Average</h5>
      </div>
      <div className={styles.content}>
        {filteredData?.length > 0 && !isMobileView && (
          <>
            <div className={styles.namebar}>
              {filteredData?.map((category, index) => (
                <span
                  key={category.name}
                  title={category.name}
                  className={styles.name}
                  style={{
                    width: `${category.percentage}%`,
                  }}
                >
                  {category.name}
                </span>
              ))}
            </div>
            <div className={styles.databar}>
              {filteredData?.map((category, index) => (
                <div
                  key={category.name}
                  className={styles.name}
                  title={Math.round(category.percentage)}
                  style={{
                    backgroundColor: category.color,
                    width: `${category.percentage}%`,
                    color:
                      category.color === "#D2B5F2" || category.color === '#56DEC8' || category.color === '#FFBFFF' || category.color === '#CCCCCC' || category.color === '#9BC3DE' || category.color === '#E7EDEF'
                        ? '#4400AA'
                        : 'white',
                  }}
                >
                  {category.percentage<=2?Math.round(category.percentage):`${Math.round(category.percentage)}%`}
                </div>
              ))}
            </div>
          </>
        )}
        {filteredData?.length>0 && isMobileView && 
          <div className={styles.mobileViewAvg}>
            {filteredData?.map((_, index) => (
              <div className={styles.block}>
                <div 
                  key={filteredData[index].name}
                  title={filteredData[index].name}
                  className={styles.nameBlock}
                >
                  {filteredData[index].name}
                </div>
                <div className={styles.percentageData}>
                  <div
                    key={filteredData[index].name}
                    className={styles.dataBlock}
                    title={Math.round(filteredData[index].percentage)}
                    style={{
                      backgroundColor: filteredData[index].color,
                      width: `${filteredData[index].percentage}%`,
                      color:
                      filteredData[index].color === "#E8E0F4" || filteredData[index].color === '#A2A8AF' || filteredData[index].color === '#98c0da'
                          ? '#4400AA'
                          : 'white',
                    }}
                  >                                
                    {(filteredData[index].percentage<=5)?'':`${Math.round(filteredData[index].percentage)}%`}
                  </div>
                  {filteredData[index].percentage<=5 &&
                    <span className={styles.mobileAvg}>
                      {Math.round(filteredData[index].percentage)}%
                    </span>
                  }
                </div>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
};

export default OverallAverage;