import React, { useState } from "react";
import styles from "./style.module.scss";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { APP_MESSAGES, APP_MESSAGES_TUR } from "util/constants";
import { useSelector } from "react-redux";
import { Select } from "antd";
import SortPopup from "helperComponents/SortPopup";
import { useTranslation } from "react-i18next";

const AllDashboardsHeader = ({
  platforms,
  localState,
  setState,
  setStartIndex,
  setLastIndex,
  userList,
  user,
  getAllDashboards,
  startIndex,
  setSearchWord,
  searchWord,
  setSortValue,
  groups,
  sortValue,
  setDashboardPageNumber,
}) => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showRowItems, setShowRowItems] = useState(true);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("All");
  const [selectedGroup, setSelectedGroup] = useState("none");
  const history = useHistory();
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t, i18n } = useTranslation();

  const handleChange = (value, e) => {
    if (value === "All") {
      setState({ ...localState, activePlatform: 0 });
      setSelectedPlatform("All");
      setStartIndex(0);
      setLastIndex();
      setDashboardPageNumber(1);
    } else {
      setState({ ...localState, activePlatform: parseInt(value, 10) });
      for (let i = 0; i < platforms?.length; i++) {
        if (platforms[i].id === parseInt(value, 10))
          setSelectedPlatform(platforms[i].name);
      }
      setStartIndex(0);
      setLastIndex();
      setDashboardPageNumber(1);
    }
  };

  const handleGroup = (value, e) => {
    if (value === "none") {
      setState({ ...localState, group_id: null });
      setSelectedGroup("None");
      setStartIndex(0);
      setLastIndex();
      setDashboardPageNumber(1);
    } else if (e.type === "group") {
      setState({ ...localState, group_id: parseInt(value, 10) });
      for (let i = 0; i < groups?.length; i++) {
        if (groups[i].group_id === parseInt(value, 10))
          setSelectedGroup(groups[i].group_name);
      }
      setStartIndex(0);
      setLastIndex();
      setDashboardPageNumber(1);
    } else {
      setState({
        ...localState,
        activePlatform: parseInt(value, 10),
        group_id: null,
      });
      for (let i = 0; i < platforms?.length; i++) {
        if (platforms[i].id === parseInt(value, 10))
          setSelectedPlatform(platforms[i].name);
      }
      setStartIndex(0);
      setLastIndex();
      setDashboardPageNumber(1);
    }
  };

  const handleShowSearch = () => {
    if (window.innerWidth < 880) {
      setShowRowItems(false);
    }
    setShowSearchBox(!showSearchBox);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchWord.trim()) {
        startIndex ? setStartIndex(0) : getAllDashboards();
        setDashboardPageNumber(1);
      } else {
        startIndex ? setStartIndex(0) : getAllDashboards();
        setDashboardPageNumber(1);
      }
    }
  };

  const handleBackward = async () => {
    setShowRowItems(true);
    setSearchWord("");
    getAllDashboards("reset");
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.title}>
          <div className={styles.text}>
            <h5>
              {i18n.language === "en"
                ? APP_MESSAGES.ALL_DashboardS.HEADER
                : APP_MESSAGES_TUR.ALL_DashboardS.HEADER}
            </h5>
          </div>
        </div>
        <div>
          <div className={styles.createBtn}>
            <Button
              id="createDashboard"
              icon={<i className="fa fa-plus" />}
              onClick={() => history.push("/console/source")}
              className={styles.createBtn}
            >
              {t("all_dashboards_page.1")}
            </Button>
          </div>
        </div>
      </div>
      <div id="alldataplatforms" className={styles.bottom}>
        {showRowItems && (
          <div className={styles.firstRow}>
            <div className={styles.selectors}>
              <Select
                onChange={handleChange}
                style={{ width: 200 }}
                value={`Source: ${selectedPlatform}`}
                showSearch
                optionFilterProp="title"
              >
                <Select.Option key={"All"}>
                  {t("dashboard_sidebar.1")}
                </Select.Option>

                {platforms?.map((platform) => {
                  return (
                    <Select.Option key={platform.id} title={platform.name}>
                      {platform.name}
                    </Select.Option>
                  );
                })}
              </Select>
              <Select
                onChange={handleGroup}
                style={{ width: 200 }}
                value={`Group: ${selectedGroup}`}
                showSearch
                optionFilterProp="title"
              >
                <Select.Option key={"none"}>None</Select.Option>

                {groups?.map((group) => {
                  return (
                    <Select.Option
                      type={"group"}
                      key={group.group_id}
                      title={group.group_name}
                    >
                      {group.group_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>

            <div className={styles.actionBtnBlock}>
              <button className={styles.search} onClick={handleShowSearch}>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/search.svg"
                      : "/assets/search_black.svg"
                  }
                  alt=""
                />
              </button>
              <span
                className={styles.search}
                onClick={() => {
                  getAllDashboards();
                }}
                title="Refresh status"
              >
                <img
                  src={
                    theme === "dark"
                      ? "/assets/refresh.svg"
                      : "/assets/refresh_black.svg"
                  }
                  width={18}
                  height={18}
                />
              </span>
              <img
                src={
                  theme === "dark"
                    ? "/assets/sort_icon.svg"
                    : "/assets/sort_icon_black.svg"
                }
                alt=""
                className={styles.sort}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowSortPopup(!showSortPopup);
                }}
              />
              {showSortPopup && (
                <SortPopup
                  setSortValue={setSortValue}
                  sortValue={sortValue}
                  setShowSortPopup={setShowSortPopup}
                />
              )}
              {window.innerWidth > 880 && (
                <div
                  className={`${styles.searchBox} ${showSearchBox &&
                    styles.showSearch} `}
                >
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/search.svg"
                        : "/assets/search_black.svg"
                    }
                    alt=""
                  />
                  <input
                    type="text"
                    placeholder={t("placeholder.1")}
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                    onKeyPress={handleSearch}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {!showRowItems && (
          <div
            className={`${styles.searchBoxSmall} ${showSearchBox &&
              styles.showSearch} `}
          >
            <i
              onClick={() => {
                handleBackward();
              }}
              className="fa-solid fa-arrow-left"
            ></i>
            <input
              type="text"
              placeholder={t("placeholder.1")}
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onKeyPress={handleSearch}
            />
            <button
              onClick={() =>
                startIndex ? setStartIndex(0) : getAllDashboards()
              }
            >
              {t("button.5")}
            </button>
          </div>
        )}
      </div>
      {userList && userList?.length > 0 && (
        <div id="alldataplatforms" className={styles.bottom}>
          {userList?.map((userObj) => {
            return (
              <div
                key={userObj.FirebaseId}
                className={`${styles.navBtn} ${localState.selectedUserId ===
                  userObj.FirebaseId && styles.navBtnActive}`}
                onClick={() => {
                  setState({
                    ...localState,
                    selectedUserId: userObj.FirebaseId,
                  });
                  setStartIndex(0);
                  setLastIndex();
                }}
              >
                <Button>{userObj.FirebaseEmail}</Button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AllDashboardsHeader;
