import React, { useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import styles from "./style.module.scss";

const DropdownSelect = ({
  options,
  reset,
  disableMenu,
  bgColor,
  selectedOptions,
  setSelectedOptions,
  hideImg,
  hiddenTriggerLabel,
  triggerEvent,
  placement,
  menuWidth,
  menuMarginTop,
  maxMenuHeight,
}) => {
  const handleSelection = (id) => {
    const selected = options.find(
      (option) => option.id?.toString() === id?.toString()
    );
    setSelectedOptions({
      id: selected?.id,
      label: selected?.label,
      img: selected?.img,
      value: selected?.value,
    });
  };

  const menu = (
    <>
      {options.length > 0 ? (
        <Menu
          className={styles.menu}
          style={
            menuMarginTop
              ? menuWidth
                ? {
                    position: "relative",
                    top: menuMarginTop,
                    width: menuWidth,
                    maxHeight: maxMenuHeight ? maxMenuHeight : "150px",
                  }
                : {
                    position: "relative",
                    top: menuMarginTop,
                    maxHeight: maxMenuHeight ? maxMenuHeight : "150px",
                  }
              : menuWidth
              ? {
                  width: menuWidth,
                  maxHeight: maxMenuHeight ? maxMenuHeight : "150px",
                }
              : { maxHeight: maxMenuHeight ? maxMenuHeight : "150px" }
          }
          onClick={(e) => handleSelection(e.key)}
        >
          {options?.length > 0 &&
            !disableMenu &&
            options?.map((option) => (
              <Menu.Item className={styles.menuBox} key={option.id}>
                {option.img && <img src={option.img} width={24} height={24} />}{" "}
                {option.label}
              </Menu.Item>
            ))}
        </Menu>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <div className={styles.DropdownContainer}>
      {reset ? (
        <Dropdown
          className={styles.Dropdown}
          style={{ backgroundColor: bgColor }}
          overlay={menu}
          trigger={triggerEvent ? [triggerEvent] : ["click", "hover"]}
          placement={placement ? placement : "bottomLeft"}
        >
          <img
            src={selectedOptions ? selectedOptions.img : options[0].img}
            width={20}
            height={20}
          />
        </Dropdown>
      ) : (
        <Dropdown
          className={styles.Dropdown}
          style={{ backgroundColor: bgColor }}
          overlay={menu}
          trigger={triggerEvent ? [triggerEvent] : ["click", "hover"]}
          placement={placement ? placement : "bottomLeft"}
        >
          {!hideImg ? (
            <img
              src={selectedOptions ? selectedOptions.img : options[0].img}
              width={20}
              height={20}
            />
          ) : (
            <span className={styles.hiddenTriggerLabel}>
              {hiddenTriggerLabel}
            </span>
          )}
        </Dropdown>
      )}
    </div>
  );
};

export default DropdownSelect;
