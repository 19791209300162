import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import JourneyHeader from "components/General/JourneyHeader";
import { useHistory } from "react-router-dom";
import { notification, Spin } from "antd";
import { apiRequest } from "util/services";
import { UNLOGGED, APP_MESSAGES } from "util/constants";
import JourneyStepItem from "components/Journey/JourneyStepItem";
import { useSelector } from "react-redux";
import DashboardHeader from 'components/Dashboard/Header';
import NestedTabs from 'components/NestedTabs';
import { useTranslation } from 'react-i18next';

const Journey = ({ authenticated, user, match }) => {
  const { t } = useTranslation();
  const JourneySteps = [
    {
      title: t("journey_page.2"),
      info: t("journey_page.3"),
      time: "",
      id: 1
    },
    {
      title: t("journey_page.4") + " ",
      info: t("journey_page.5"),
      time: "",
      id: 2
    },
    {
      title: t("journey_page.6") + " ",
      info: t("journey_page.7"),
      time: "",
      id: 3
    },
    {
      title: t("journey_page.8") + " ",
      info: t("journey_page.9"),
      time: "",
      id: 4
    },
    {
      title: t("journey_page.10") + " ",
      info: t("journey_page.11"),
      time: "",
      id: 5
    },
  ];

  const getJourneySteps = (isUpdate, isTopicAvailable) => {
    let steps = [...journeySteps]
    if (isUpdate) {
      steps[0] = {
        title: "Starting Dashboard update",
        info: "We are receiving recent data to provide updated results",
        time: "",
        id: 1
      }
    }
    if (isTopicAvailable) {
      steps = steps.slice(0, 4);
      steps.push({
        title: "Combining with your Topic Hiearchy",
        info: "We receiving results associated with selected topics",
        time: "",
        id: 5
      })
      steps.push({
        title: t("journey_page.10") + " ",
        info: t("journey_page.11"),
        time: "",
        id: 6
      })
    }

    return steps
  }

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(0);
  const [selectedStep, setSelectedStep] = useState(JourneySteps[0]);
  const [journeySteps, setJourneySteps] = useState(JourneySteps);
  const [complitionTime, setComplitionTime] = useState("")
  const [nextUpdateTime, setNextUpdateTime] = useState("")
  const history = useHistory();
  const DashboardReducer = useSelector((state) => state.DashboardReducer);

  useEffect(() => {
    if (authenticated === UNLOGGED) {
      history.push("/");
      return;
    }
    const currentDashboard = match.params.id || DashboardReducer.dashboard?.ID;
    if (!currentDashboard) {
      notification.error({
        message: t("notifications.42"),
      });
      history.push("/console/myDashboards");
      return;
    }
    initializeBoardProgress();
  }, []);

  const initializeBoardProgress = async () => {
    const currentDashboard = match.params.id || DashboardReducer.dashboard?.ID;
    const result = await apiRequest("dashboards/v2/get", "POST", {
      dashboard_id: parseInt(currentDashboard, 10),
    });
    if (result?.status.ID === -1 || result?.status.ID === 5) {
      notification.info({
        message:
          t("notifications.44"),
      });
      history.push(`/console/myDashboards`);
      return;
    }
    if (result) {
      setComplitionTime(result.status.estimated_completion)
      setNextUpdateTime(result.status.next_update)
      let data = [...getJourneySteps(result.status.update, result.status.custom_topic_available)];
      let timeOfSteps = [];
      if (result.status.update) {
        timeOfSteps.push(result.status.start_update_date);
      } else {
        timeOfSteps.push(result.dashboard.CreationDate);

      }
      timeOfSteps.push(result.status.CrawlingCompleted);
      timeOfSteps.push(result.status.PreprocessingCompleted);
      timeOfSteps.push(result.status.ModelingCompleted);
      if (result.status.custom_topic_available) {
        timeOfSteps.push(result.status.CustomTopicCompleted);
      }
      timeOfSteps.push(result.dashboard.FinishDate);
      for (let i = 0; i < data.length; i++) {
        if (result.status.ID === i && result.status.ID !== 4) {
          setStatus(i);
          setSelectedStep(data[i]);
        }
        data[i].time = formatDate(timeOfSteps[i]);
      }
      if (result.status.ID === 4) {
        setStatus(4);
        setSelectedStep(data[4]);
      }
      setJourneySteps(data);
    } else {
      notification.error({
        message: t("notifications.94"),
      });
    }
    setLoading(false);
  };

  const formatDate = (date) => {
    if (!date) return "";
    var d = new Date(date);

    var datestring = d.toLocaleString();

    return datestring;
  };

  return (
    <div className={styles.mainWindow}>
      <NestedTabs />
      <Spin spinning={loading}>
        <div className={styles.journeyContent}>
          <div className={styles.left} id="journey_steps">
            {journeySteps.map((step, i) => {
              return (
                <JourneyStepItem
                  step={step}
                  setSelectedStep={setSelectedStep}
                  active={selectedStep.id === step.id}
                  id={step.id}
                />
              );
            })}
          </div>
          {
            complitionTime && <div className={styles.right}>
              <h4>Estimated time</h4>
              <h5>Your dashboard will be ready by {complitionTime}</h5>
              {/* <p>{selectedStep.info}</p> */}
            </div>
          }

        </div>
      </Spin>
    </div>
  );
};

export default Journey;
