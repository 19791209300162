import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Menu } from "antd";

const NestedTabs = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState([]);
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const [isMobileView, setIsMobileView] = useState(false);
  const { id } = useParams();
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const userReducer = useSelector((state) => state.userReducer);
  const currentDashboard = DashboardReducer.dashboard?.ID || id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const DashboardKeys = [
    "DashboardData",
    "Dashboard",
    "customTopics",
    "journey",
    "insights",
    "geolocation",
  ];

  useEffect(() => {
    if (window.innerWidth < 1100) {
      setIsMobileView(true);
    }
    function handleResize() {
      if (window.innerWidth < 1100) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
    let defaultSelected = document.location.pathname.split("/")[2];
    history.listen(() => {
      defaultSelected = document.location.pathname.split("/")[2];
      setSelectedMenuItem(defaultSelected);
    });
    setSelectedMenuItem(defaultSelected);
    window.addEventListener("resize", handleResize);
  }, []);

  const handleClick = (e) => {
    if (e.key === "DashboardData") {
      // reset popup
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          showNewReviewsLabel: false,
        },
      });
    }
    for (let l = 1; l >= 1; l++) {
      if (params.has(`level${l}Selection`)) {
        params.delete(`level${l}Selection`);
        history.push({ search: params.toString() });
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            [`selectedL${l}Topic`]: null,
          },
        });
      } else {
        break;
      }
    }
    [
      "sentiment",
      "intent",
      "rating",
      "top_topics",
      "topics_operator",
      "platform_id",
      "match_date",
      "hotterm",
      "nps",
      "keyword",
      "pivotFilter",
      "pivots_operator",
      "view_type",
      "topics_type",
      "selected_topic",
      "insightTopicSearch",
      "insightTopicClosed",
      "start_index",
      "page_offset",
    ].forEach((item) => params.delete(item));
    setSelectedMenuItem(e.key);
    if (currentDashboard && DashboardKeys.includes(e.key)) {
      history.push({
        pathname: `/console/${e.key}/${currentDashboard}`,
        search: params.toString(),
      });
    } else {
      history.push({
        pathname: `/console/${e.key}`,
        search: params.toString(),
      });
    }
  };

  return (
    <div className={styles.nestedTabContainer}>
      {currentDashboard &&
        DashboardKeys.includes(document.location.pathname.split("/")[2]) && (
          <Menu
            onClick={handleClick}
            // style={{ width: isMobileView ? 200 : "auto" }}
            selectedKeys={[selectedMenuItem]}
            defaultOpenKeys={[isMobileView ? "" : "DashboardGroup"]}
            // inlineCollapsed={isMobileView}
            mode="horizontal"
            className={styles.nestedMenuItem}
          >
            <Menu.Item key="DashboardData">
              <Link to={`/console/DashboardData/${currentDashboard}`}>
                {t("selected_dashboard_sidebar.1")}
              </Link>
            </Menu.Item>
            <Menu.Item key="Dashboard">
              <Link to={`/console/Dashboard/${currentDashboard}`}>
                {t("selected_dashboard_sidebar.2")}
              </Link>
            </Menu.Item>
            {/* <Menu.Item
                     key="industryTopics"
                  >
                     {t("selected_dashboard_sidebar.3")}
                  </Menu.Item> */}
            <Menu.Item key="customTopics">
              <Link to={`/console/customTopics/${currentDashboard}`}>
                {t("selected_dashboard_sidebar.4")}
              </Link>
            </Menu.Item>
            {DashboardReducer.dashboard?.Status !== "Ready" && (
              <Menu.Item key="journey">
                <Link to={`/console/journey/${currentDashboard}`}>
                  {t("selected_dashboard_sidebar.5")}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="insights">
              <Link to={`/console/insights/${currentDashboard}`}>
                {t("selected_dashboard_sidebar.6")}
              </Link>
            </Menu.Item>
            {/* {
                     DashboardReducer.dashboard?.GeoEnabled &&
                     <Menu.Item key="geolocation">
                        <Link to={`/console/geolocation/${currentDashboard}`} >
                           {t("selected_dashboard_sidebar.7")}
                        </Link>
                     </Menu.Item>
                  } */}
          </Menu>
        )}
    </div>
  );
};

export default NestedTabs;
