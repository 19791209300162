import React from "react";
import { Button, Menu, Dropdown, Modal, Table } from "antd";
import {
  EllipsisOutlined,
  LeftCircleFilled,
  RightCircleFilled,
  DashboardOutlined,
} from "@ant-design/icons";
import { Modal as CustomModal } from "components/General/ModalFormComponents";
import styles from "./style.module.scss";
import NewOrganizationView from "./newOrganizationView";
import { useSelector } from "react-redux";
import RenameDeletePopup from "helperComponents/RenameDeletePopup";
import i18n from "i18next";

const CustomMenu = ({ onClick }) => (
  <Menu className={styles.menu} onClick={onClick}>
    <Menu.Item key="updateQuota">{i18n.t("settings.164")}</Menu.Item>
    <Menu.Item key="rename">{i18n.t("settings.165")}</Menu.Item>
    <Menu.Item key="invite">{i18n.t("settings.166")}</Menu.Item>
    <Menu.Item key="delete">{i18n.t("settings.167")}</Menu.Item>
    <Menu.Item key="dashboardAccess">{i18n.t("settings.221")}</Menu.Item>
  </Menu>
);

const Representation = ({
  showNewOrganization,
  setShowNewOrganization,
  user,
  organizations,
  permissions,
  mapPermissions,
  onHeaderMenuClick,
  closeNewOrganizationView,
  updateQuotaModal,
  setUpdateQuotaModal,
  updateQuota,
  quota,
  setQuota,
  isMobile,
  unassignedDashboards,
  setShowRenameTeam,
  showRenameTeam,
  renameTeam,
  setTeamName,
  teamName,
  disableBtn,
  showDeleteTeam,
  setShowDeleteTeam,
  deleteOrganization,
  teamDashboards,
  setTeamDashboards,
  teamForInfo,
  setTeamForInfo,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);

  const cols = [
    {
      title: <span className={styles.idColumn}>ID</span>,
      dataIndex: "id",
      key: "id",
      align: "center",
      className: `${styles.idColumn}`,
    },
    {
      title: <span className={styles.nameColumn}>Dashboard Name</span>,
      dataIndex: "name",
      key: "name",
      align: "left",
      className: `${styles.nameColumn}`,
    },
  ];
  const tableData = Object.keys(teamDashboards || {})?.map((key) => ({
    key: key,
    id: key,
    name: teamDashboards[key],
  }));
  const ModalStyle = {
    padding: "30px 45px;",
    borderRadius: "15px",
    background: theme === "dark" ? "#1C193B" : "#ffffff",
    overflow: "hidden",
  };
  return (
    <>
      {showNewOrganization ? (
        <NewOrganizationView
          closeView={closeNewOrganizationView}
          user={user}
          organizations={organizations}
          permissions={permissions}
          mapPermissions={mapPermissions}
        />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h4 className={styles.containerTitle}>
              {i18n.t("settings.168")}
              <p className={styles.subtitle}>{i18n.t("settings.169")}</p>
            </h4>
            <div className={styles.btnWrapper}>
              <DashboardOutlined style={{ fontSize: "24px" }} />{" "}
              {i18n.t("settings.170")} {unassignedDashboards}
              <Button
                className={styles.btn}
                onClick={() => setShowNewOrganization(true)}
              >
                {i18n.t("settings.171")}
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            {organizations.map((obj, index) => (
              <div className={styles.card} key={index}>
                <h3 className={styles.cardTitle}>
                  {obj.OrganizationName}
                  <Dropdown
                    overlay={() => (
                      <CustomMenu
                        onClick={(e) =>
                          onHeaderMenuClick(
                            e.key,
                            obj.OrganizationID,
                            obj.OrganizationName
                          )
                        }
                      />
                    )}
                    trigger={["click"]}
                  >
                    <EllipsisOutlined
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    />
                  </Dropdown>
                </h3>
                {isMobile ? (
                  <>
                    <div className={styles.mobileRow}>
                      <div
                        className={`${styles.permitted} ${styles.textAlignLeft}`}
                      >
                        {i18n.t("settings.172")}
                      </div>
                      <div className={styles.permitted}>
                        {obj.OrganizationPermittedDashboards}
                      </div>
                    </div>
                    <div className={styles.mobileRow}>
                      <div
                        className={`${styles.active} ${styles.textAlignLeft}`}
                      >
                        {i18n.t("settings.173")}
                      </div>
                      <div className={styles.active}>
                        {obj.ActiveDashboards}
                      </div>
                    </div>
                    <div className={styles.mobileRow}>
                      <div
                        className={`${styles.write} ${styles.textAlignLeft}`}
                      >
                        {i18n.t("settings.174")}
                      </div>
                      <div className={styles.write}>{obj.ReadWriteMembers}</div>
                    </div>
                    <div className={styles.mobileRow}>
                      <div className={`${styles.read} ${styles.textAlignLeft}`}>
                        {i18n.t("settings.175")}
                      </div>
                      <div className={styles.read}>{obj.ReadMembers}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.cardHeader}>
                      <div className={styles.permitted}>
                        {i18n.t("settings.172")}
                      </div>
                      <div className={styles.active}>
                        {i18n.t("settings.173")}
                      </div>
                      <div className={styles.write}>
                        {i18n.t("settings.174")}
                      </div>
                      <div className={styles.read}>
                        {i18n.t("settings.175")}
                      </div>
                    </div>
                    <div className={styles.cardContent}>
                      <div className={styles.permitted}>
                        {obj.OrganizationPermittedDashboards}
                      </div>
                      <div className={styles.active}>
                        {obj.ActiveDashboards}
                      </div>
                      <div className={styles.write}>{obj.ReadWriteMembers}</div>
                      <div className={styles.read}>{obj.ReadMembers}</div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <Modal
            visible={updateQuotaModal}
            bodyStyle={ModalStyle}
            footer={null}
            onCancel={() => {
              setQuota(20);
              setUpdateQuotaModal(null);
            }}
            style={{ overflow: "hidden", borderRadius: "15px", padding: "0px" }}
          >
            <h7 className={styles.modalTitle}> {i18n.t("settings.164")}</h7>
            <div className={styles.row}>
              <p className={styles.modalText}>{i18n.t("settings.172")}:</p>
              <LeftCircleFilled
                style={{ fontSize: "16px" }}
                onClick={() => setQuota(quota - 1)}
              />
              {quota}
              <RightCircleFilled
                style={{ fontSize: "16px" }}
                onClick={() => setQuota(quota + 1)}
              />
            </div>
            <div className={styles.row}>
              <Button
                className={`${styles.modalBtn} ${styles.transparent}`}
                onClick={() => {
                  setQuota(5);
                  setUpdateQuotaModal(null);
                }}
              >
                {i18n.t("button.3")}
              </Button>
              <Button
                className={`${styles.modalBtn} ${styles.red}`}
                onClick={() => updateQuota()}
              >
                {i18n.t("button.30")}
              </Button>
            </div>
          </Modal>

          {showRenameTeam && (
            <RenameDeletePopup
              text={teamName}
              setText={setTeamName}
              label={i18n.t("settings.165")}
              handleAction={renameTeam}
              closePopup={setShowRenameTeam}
              disableBtn={disableBtn}
              type="rename"
            />
          )}
          {showDeleteTeam && (
            <RenameDeletePopup
              label={i18n.t("settings.167")}
              handleAction={deleteOrganization}
              closePopup={setShowDeleteTeam}
              disableBtn={disableBtn}
              type="delete"
            />
          )}
          {teamDashboards && Object.keys(teamDashboards).length > 0 && (
            <CustomModal
              title={
                <span>
                  The team <b>{teamForInfo}</b> have access to Dashboards
                </span>
              }
              titleAsset={""}
              description={`Total dashboards: ${
                Object.keys(teamDashboards).length
              }`}
              showModal={Object.keys(teamDashboards).length > 0}
              widthofmodal={window.innerWidth > 500 ? "550px" : "350px"}
              onClose={() => {
                setTeamDashboards(null);
                setTeamForInfo(null);
              }}
            >
              <div className={styles.dashboardsWrapper}>
                <Table
                  columns={cols}
                  dataSource={tableData}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                  }}
                />
              </div>
            </CustomModal>
          )}
        </div>
      )}
    </>
  );
};

export default Representation;
